import { SxProps, TextField, Theme } from '@mui/material'
import { ChangeEvent, HTMLInputTypeAttribute } from 'react'

/**
 *
 * this field is used when you have multiple values in one react state
 * example:
 *
 * const [state, setState] = useState({
 *  name: '',
 *  email: '',
 * })
 *
 * <DefaultTextInput
 * name='name'
 * label='Name'
 * state={[state, setState]}
 * />
 *
 * <DefaultTextInput
 * name='email'
 * label='Email'
 * state={[state, setState]}
 * />
 *
 *
 * @param props.name name of the field and also the key of the state for the value
 * @param props.label label of the field
 * @param props.state react state([state, setState]) that will be used for the value
 * @param props.sx style of the field
 * @param props.error if there is an error
 * @param props.errorMessage error message
 * @param props.type type of the input
 * @param props.className className of the field
 * @param props.disabled if the field is disabled
 */
export default function DefaultTextInput<T>(props: {
  name: keyof T
  label: string
  state: [T, (args: T) => void]
  sx?: SxProps<Theme>
  error?: boolean
  errorMessage?: string
  type?: HTMLInputTypeAttribute
  className?: string
  disabled?: boolean
}) {
  const { name, label, sx, error, errorMessage, type, className, disabled } =
    props
  const [state, setState] = props.state

  return (
    <TextField
      error={error}
      helperText={error ? errorMessage : ''}
      sx={{
        marginTop: '8px',
        marginBottom: '8px',
        ...sx,
      }}
      className={`defaultTextInput${className ? ` ${className}` : ''}`}
      fullWidth
      label={label}
      value={state[name] ?? ''}
      variant='outlined'
      type={type}
      disabled={disabled}
      inputProps={{
        sx: {
          ':-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px #353c46 inset',
          },
        },
      }}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        setState({
          ...state,
          [name]: event.target.value,
        })
      }}
    />
  )
}
