import { Delete } from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, IconButton, Tab, Tooltip } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { PortalUser, TabProps } from 'types'
import { myFetch, UserContext } from 'utils'
import Groups from './Groups'
import Users from './Users'

const tabNames = ['user', 'groups']
type TabType = typeof tabNames[number]

const Admin = () => {
  const tabs: Array<{
    tab: TabType
    content: (props: TabProps) => JSX.Element
    title: string
    props?: TabProps
  }> = [
    {
      tab: 'user',
      content: Users,
      title: 'User',
      props: {
        add: true,
        loadData: async ({ setError, setLoading, setData }) => {
          setError(false)
          setLoading(true)
          await myFetch('/api/admin/get_portal_user')
            .then((body) => body.json())
            .then((data) => setData(data))
            .catch(() => setError(true))
          setLoading(false)
        },
        buttons: (user: PortalUser, loadData?: () => void) => {
          return [
            <Tooltip key={'removeUserFromPortal'} title='Remove from portal'>
              <IconButton
                onClick={async () => {
                  await myFetch(
                    `/api/admin/remove_user_from_portal?user_id=${user.id}`,
                    {
                      method: 'PUT',
                    }
                  )
                  await loadData?.()
                }}>
                <Delete />
              </IconButton>
            </Tooltip>,
          ]
        },
      } as TabProps,
    },
    {
      tab: 'groups',
      content: Groups as (props: TabProps) => JSX.Element,
      title: 'Groups',
    },
  ]

  const { tab } = useParams()

  const navigate = useNavigate()

  const [value, setValue] = useState<TabType>('user')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate('/admin/' + newValue)
  }

  useEffect(() => {
    if (tab && tabNames.includes(tab)) setValue(tab as TabType)
  }, [tab])

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.title} value={tab.tab} />
            ))}
          </TabList>
        </Box>
        <Box
          sx={{
            width: '100%',
            flexGrow: '1',
            maxWidth: '100%',
            maxHeight: '100%',
            overflow: 'auto',
          }}>
          <UserContext.Consumer>
            {(contextData) => {
              const userInfo = contextData?.tokenData
              return userInfo
                ? tabs.map((tab, index) => (
                    <TabPanel key={index} value={tab.tab}>
                      <tab.content userInfo={userInfo} {...tab.props} />
                    </TabPanel>
                  ))
                : null
            }}
          </UserContext.Consumer>
        </Box>
      </TabContext>
    </Box>
  )
}

export default Admin
