import DateFnsAdapter from '@date-io/date-fns'
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import {
  AsyncSearchField,
  FindUser,
  MoneyInput,
  SimpleDialog,
} from 'components'
import { ChangeEvent, useState } from 'react'
import {
  Codes,
  Currency,
  License,
  LicenseUser,
  MoneyPackage,
  codes,
} from 'types'
import { myFetch } from 'utils'

export const CreateLicenseDialog = (props: {
  callback: () => void
  open: boolean
  onClose: () => void
  onError?: (response: Response) => void
}) => {
  const [pickLicenseHolderOpen, setPickLicenseHolderOpen] = useState(false)
  const [date, setDate] = useState(
    new Date(Date.now() + 1000 * 60 * 60 * 24 * 30 * 6)
  )

  const [createLicenseInput, setCreateLicenseInput] = useState<{
    name?: string
    countries?: Codes[]
    license_holder?: string
    valid_until?: string
    company_shares?: MoneyPackage
    default_pricing?: MoneyPackage
    sublicense_of?: string
  }>({
    valid_until: `${date.toISOString().split('T')[0]}T00:00:01+00:00`,
    countries: [],
  })

  const { open, callback, onClose, onError } = props

  return (
    <>
      <SimpleDialog
        title='Change Licenseholder'
        contentText='Select a new Licenseholder'
        open={pickLicenseHolderOpen}
        onClose={() => setPickLicenseHolderOpen(false)}>
        <FindUser
          onSelect={async (user: LicenseUser) => {
            setCreateLicenseInput({
              ...createLicenseInput,
              license_holder: user.id,
            })
            setPickLicenseHolderOpen(false)
          }}
        />
      </SimpleDialog>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Create License</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={DateFnsAdapter}>
            <TextField
              sx={{
                marginTop: '8px',
                marginBottom: '8px',
              }}
              fullWidth
              label='Name'
              variant='outlined'
              autoComplete='new-password'
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setCreateLicenseInput({
                  ...createLicenseInput,
                  name: event.target.value,
                })
              }}
            />
            <AsyncSearchField
              label='ISO-3166 codes'
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant='outlined'
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              getLabel={(item: Codes) => item}
              onType={async (value: string) =>
                codes.filter((code) => code.startsWith(value.toUpperCase()))
              }
              onSelect={(countries: Codes[]) => {
                setCreateLicenseInput({
                  ...createLicenseInput,
                  countries,
                })
              }}
            />
            <Button
              sx={{
                marginTop: '8px',
                marginBottom: '8px',
              }}
              fullWidth
              variant='outlined'
              onClick={() => setPickLicenseHolderOpen(true)}>
              Licenseholder
              {createLicenseInput.license_holder
                ? ` (${createLicenseInput.license_holder})`
                : ''}
            </Button>
            <Box sx={{ marginTop: '8px', marginBottom: '8px' }}>
              <DesktopDatePicker
                label='Valid Until'
                inputFormat='dd/MM/yyyy'
                value={date}
                onChange={(date: Date | null, _: any) => {
                  if (date) {
                    setDate(date)
                    const dateString = `${
                      date.toISOString().split('T')[0]
                    }T00:00:01+00:00`
                    setCreateLicenseInput({
                      ...createLicenseInput,
                      valid_until: dateString,
                    })
                  }
                }}
                renderInput={(params: any) => (
                  <TextField fullWidth {...params} />
                )}
              />
            </Box>
            <MoneyInput
              label='Company shares'
              onChange={(amount?: number, currency?: Currency) => {
                if (amount && currency)
                  setCreateLicenseInput({
                    ...createLicenseInput,
                    company_shares: { amount, currency },
                  })
              }}
            />
            <MoneyInput
              label='Default pricing'
              onChange={(amount?: number, currency?: Currency) => {
                if (amount && currency)
                  setCreateLicenseInput({
                    ...createLicenseInput,
                    default_pricing: { amount, currency },
                  })
              }}
            />
            <AsyncSearchField
              label='Parent license'
              getLabel={(item: License) => item.name}
              onType={async (value: string) =>
                myFetch(`/api/management/list_license?name=${value}`).then(
                  (body) => body.json()
                ) as Promise<License[]>
              }
              onSelect={(license: License) => {
                setCreateLicenseInput({
                  ...createLicenseInput,
                  sublicense_of: license.license_id,
                })
              }}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={async () => {
              await myFetch(`/api/management/create_license`, {
                method: 'post',
                headers: {
                  'content-type': 'application/json',
                },
                body: JSON.stringify(createLicenseInput),
              }).then((body) => {
                if (body.status !== 200 && onError) onError(body)
              })
              onClose()
              callback()
            }}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
