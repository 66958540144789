import { ChevronLeft, ChevronRight, Download } from '@mui/icons-material'
import { Box, Button, Fab, IconButton, TextField } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { AlertDialog } from 'components/Components'
import QrCode from 'qrcode'
import { useEffect, useState } from 'react'
import { csvToXlsx, downloadAsFile, myFetch } from 'utils/utils'

type GenerateCodeResponse = {
  code: string
  url?: string
  hash?: string
}

const GenerateCode = () => {
  const [amount, setAmount] = useState(1)
  const [error, setError] = useState<string | null | undefined>()
  const [codes, setCodes] = useState<GenerateCodeResponse[]>([])
  const [code, setCode] = useState<string | null>(null)
  const [codeIndex, setCodeIndex] = useState(-1)
  const [loading, setLoading] = useState(false)

  const generateCode = async (amount: number, simple: boolean = false) => {
    setCodeIndex(-1)
    setCodes([])
    setLoading(true)
    const response = await myFetch('/api/generate_codes', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      query: {
        amount,
        simple,
      },
    })

    try {
      if (response.ok) setCodes(await response.json())
      else setError((await response.json()).msg)
      setLoading(false)
    } catch (e) {
      setCodes([])
      setLoading(false)
    }
  }

  const saveAsXLSX = () => {
    const csv =
      'code,url\n' +
      codes
        .map((code) => {
          return code.code + ',' + code.url
        })
        .join('\n')
    downloadAsFile(csvToXlsx(csv), 'codes.xlsx')
  }

  useEffect(() => {
    if (codeIndex >= 0) {
      const url = codes[codeIndex]?.url

      if (!url) setCode(null)
      else
        QrCode.toDataURL(url || '', {
          errorCorrectionLevel: 'H',
        })
          .then((url) => setCode(url))
          .catch(() => setCode(null))
    } else setCode(null)
  }, [codeIndex, codes])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
        }}>
        <Button
          sx={{
            mr: 2,
          }}
          variant='outlined'
          disabled={loading}
          onClick={() => generateCode(amount)}>
          Generate codes
        </Button>
        <TextField
          sx={{
            flexGrow: 1,
          }}
          label='Amount'
          type='number'
          error={!!error}
          helperText={error}
          value={amount}
          onChange={(e) => setAmount(parseInt(e.target.value))}
          InputProps={{ inputProps: { min: 1, max: 5000 } }}
        />
      </Box>
      <DataGrid
        sx={{
          mt: 2,
          height: 'calc(100vh - 250px)',
        }}
        onRowClick={(params) => {
          setCodeIndex(params.row.id)
        }}
        rows={codes.map((code, index) => {
          return {
            id: index,
            col1: code.code,
            col2: code.url,
            col3: code.hash,
          }
        })}
        columns={[
          { field: 'col1', headerName: 'Code', width: 150 },
          { field: 'col2', headerName: 'URL', minWidth: 200, flex: 1 },
          { field: 'col3', headerName: 'Hash', minWidth: 200, flex: 1 },
        ]}
      />
      <AlertDialog
        title='Code'
        content={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <h1>{codes[codeIndex]?.code}</h1>
            <img src={code || ''} alt='code' />
            <Box
              sx={{
                flexGrow: 1,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                mt: 2,
              }}>
              <Button onClick={() => setCodeIndex(-1)}>Close</Button>
              <Box>
                <IconButton
                  disabled={codeIndex <= 0}
                  onClick={() => setCodeIndex(codeIndex - 1)}>
                  <ChevronLeft />
                </IconButton>
                <IconButton
                  disabled={codeIndex >= codes.length - 1}
                  onClick={() => setCodeIndex(codeIndex + 1)}>
                  <ChevronRight />
                </IconButton>
              </Box>
            </Box>
          </Box>
        }
        open={!!code}
        onClose={() => setCodeIndex(-1)}
      />
      <Fab
        onClick={saveAsXLSX}
        sx={{ position: 'fixed', bottom: 16, right: 16 }}>
        <Download />
      </Fab>
    </>
  )
}

export default GenerateCode
