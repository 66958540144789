import {
  Fullscreen,
  Pause,
  PictureInPicture,
  PlayArrow,
  Settings,
  VolumeDown,
  VolumeMute,
  VolumeOff,
  VolumeUp,
} from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Fab,
  Grow,
  IconButton,
  LinearProgress,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Slider,
} from '@mui/material'
import { LevelParsed } from 'hls.js'
import { CSSProperties, RefObject, useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { hasAudio } from 'utils'

/**
 * Custom video player component
 * only used in video page
 */
const VideoPlayer = (props: {
  videoPlayerRef: RefObject<HTMLVideoElement>
  videoPlayerProps: { [key: string]: any }
  style?: CSSProperties
  quality?: { current: number; levels: LevelParsed[] }
  onChangeQuality?: (level: number) => void
}) => {
  const mounted = useRef(false)

  const videoPlayerRef = props.videoPlayerRef

  const resolutionAnchorRef = useRef(null)

  const [playing, setPlaying] = useState(props.videoPlayerProps?.autoPlay)
  const [buffered, setBuffered] = useState(0)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [buffering, setBuffering] = useState(true)
  const [seekTo, setSeekTo] = useState(0)
  const [open, setOpen] = useState(false)
  const [muted, setMuted] = useState(videoPlayerRef.current?.muted ?? false)
  const [volume, setVolume] = useState(videoPlayerRef.current?.volume ?? 1)
  const [volumeVisible, setVolumeVisible] = useState(false)
  const [sliding, setSliding] = useState(false)
  const [volumeHover, setVolumeHover] = useState(false)

  const videoHandler = () => {
    if (videoPlayerRef.current?.paused) {
      videoPlayerRef.current?.play()
      setPlaying(true)
    } else {
      videoPlayerRef.current?.pause()
      setPlaying(false)
    }
  }

  useEffect(() => {
    const player = videoPlayerRef.current
    if (player) player.muted = muted
  }, [muted, videoPlayerRef])

  useEffect(() => {
    const player = videoPlayerRef.current
    if (player) {
      player.volume = volume
      if (volume === 0) setMuted(true)
      else setMuted(false)
    }
  }, [volume, videoPlayerRef])

  useEffect(() => {
    if (!isMobile) {
      setVolumeVisible(sliding || volumeHover)
    }
  }, [sliding, volumeHover])

  useEffect(() => {
    const current = videoPlayerRef.current

    current?.addEventListener('play', () => setPlaying(true))
    current?.addEventListener('pause', () => setPlaying(false))

    const timeupdate = (event: Event) => {
      if (event.target) {
        setCurrentTime((event.target as HTMLVideoElement).currentTime)
        const buffer = (event.target as HTMLVideoElement).buffered
        let bufferLength = 0
        for (let i = 0; i < buffer.length; i++) {
          if (bufferLength < buffer.end(i)) bufferLength = buffer.end(i)
        }
        setBuffered(bufferLength)
      }
    }

    const loadeddata = (event: Event) => {
      if (event.target) {
        if (current) {
          current
            .play()
            .then(() => setPlaying(true))
            .catch(() => setPlaying(false))
        } else setPlaying(false)
        setDuration((event.target as HTMLVideoElement).duration)
      }
    }

    const waiting = () => {
      setBuffering(true)
    }
    const playing = () => {
      setBuffering(false)
    }

    const timeout = setTimeout(() => {
      if (current) {
        current.addEventListener('timeupdate', timeupdate)
        current.addEventListener('loadeddata', loadeddata)

        current.addEventListener('waiting', waiting)
        current.addEventListener('playing', playing)
      }
    }, 100)

    return () => {
      clearTimeout(timeout)
      current?.removeEventListener('timeupdate', timeupdate)
      current?.removeEventListener('loaddedata', loadeddata)
      current?.removeEventListener('waiting', waiting)
      current?.removeEventListener('playing', playing)
    }
  }, [videoPlayerRef])

  useEffect(() => {
    const current = videoPlayerRef.current

    if (current) current.currentTime = seekTo
    return () => {}
  }, [seekTo, videoPlayerRef])

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  const normalise = (value: number) => value * (100 / duration)

  return (
    <div style={{ position: 'relative', ...props.style }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          '.controls': {
            opacity: playing ? 0 : 1,
            transition: '200ms',
          },
          ':hover .controls': {
            opacity: 1,
          },
        }}>
        <video
          onClick={() => videoHandler()}
          ref={videoPlayerRef}
          {...props.videoPlayerProps}
        />
        {buffering && (
          <CircularProgress
            sx={{
              position: 'absolute',
              top: 'calc(50% - 40px)',
              left: 'calc(50% - 40px)',
            }}
            size={80}
          />
        )}
        <Fab
          className='controls'
          color='secondary'
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            ':hover': {
              opacity: 1,
            },
          }}
          onClick={() => videoHandler()}>
          {playing ? <Pause /> : <PlayArrow />}
        </Fab>
        <Box
          className='controls'
          sx={{
            position: 'absolute',
            right: 0,
            bottom: 40,
          }}>
          {hasAudio(videoPlayerRef?.current) && (
            <Box
              id='volume'
              onMouseEnter={() => setVolumeHover(true)}
              onMouseLeave={() => setVolumeHover(false)}
              sx={{
                transition: '200ms',
                position: 'relative',
                background: volumeVisible ? '#00000080' : 'transparent',
                display: 'inline-block',
                borderRadius: '40px',
                width: volumeVisible ? '195px' : '40px',
                height: '40px',
                bottom: '-15px',
              }}>
              <Slider
                id='volumeSlider'
                value={volume}
                max={1}
                step={0.01}
                onChange={(event) => {
                  const value = (event.target as any).value as number
                  setVolume(value)
                  if (!sliding) setSliding(true)
                }}
                onChangeCommitted={() => setSliding(false)}
                sx={{
                  transition: '180ms',
                  position: 'absolute',
                  width: volumeVisible ? '120px' : '0px',
                  left: '20px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  '& .MuiSlider-thumb': {
                    opacity: 0,
                  },
                  '& .MuiSlider-track': {
                    border: 'none',
                    borderTop: '1px solid currentColor',
                    borderBottom: '1px solid currentColor',
                  },
                }}
              />
              <IconButton
                id='volumeButton'
                sx={{
                  position: 'absolute',
                  right: 0,
                }}
                onClick={() => setVolume((volume) => (volume > 0 ? 0 : 1))}>
                {volume === 0 ? (
                  <VolumeOff />
                ) : volume < 0.4 ? (
                  <VolumeMute />
                ) : volume < 0.75 ? (
                  <VolumeDown />
                ) : (
                  <VolumeUp />
                )}
              </IconButton>
            </Box>
          )}
          <IconButton
            id='resolutionButton'
            ref={resolutionAnchorRef}
            onClick={() => setOpen((open) => !open)}>
            <Settings
              sx={{
                transform: open ? 'rotate(45deg)' : 'rotate(0deg)',
                transition: '400ms',
              }}
            />
          </IconButton>
          {document.pictureInPictureEnabled &&
            !props.videoPlayerRef?.current?.disablePictureInPicture && (
              <IconButton
                id='pipButton'
                onClick={() => {
                  if (document.pictureInPictureElement)
                    document.exitPictureInPicture()
                  else props.videoPlayerRef?.current?.requestPictureInPicture()
                }}>
                <PictureInPicture />
              </IconButton>
            )}
          <IconButton
            id='maximizeButton'
            onClick={() => props.videoPlayerRef?.current?.requestFullscreen()}>
            <Fullscreen />
          </IconButton>
        </Box>
        <Popper
          open={open}
          anchorEl={resolutionAnchorRef.current}
          role={undefined}
          placement='bottom-start'
          transition
          disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}>
              <Paper>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <MenuList
                    autoFocusItem={open}
                    id='composition-menu'
                    aria-labelledby='composition-button'>
                    <MenuItem
                      key={-1}
                      selected={props.quality?.current === -1}
                      onClick={() => {
                        if (props.onChangeQuality) props.onChangeQuality(-1)
                        setOpen(false)
                      }}>
                      Auto
                    </MenuItem>
                    {props.quality?.levels?.map((level, index) => (
                      <MenuItem
                        key={index}
                        selected={props.quality?.current === index}
                        onClick={() => {
                          if (props.onChangeQuality)
                            props.onChangeQuality(index)
                          setOpen(false)
                        }}>
                        {level.width}x{level.height}
                      </MenuItem>
                    )) ?? null}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <LinearProgress
          variant='buffer'
          value={normalise(currentTime)}
          valueBuffer={normalise(buffered)}
          sx={{
            width: '100%',
            position: 'absolute',
            bottom: 23,
            left: 0,
            ':hover + #thumb': {
              opacity: 1,
            },
          }}
        />
        <Slider
          value={currentTime}
          max={duration}
          onChange={(event) => {
            setSeekTo((event.target as any).value as number)
          }}
          sx={{
            width: '100%',
            position: 'absolute',
            bottom: 10,
            left: 0,
            '& .MuiSlider-track, & .MuiSlider-rail, & .MuiSlider-thumb': {
              opacity: 0,
              transition: '200ms',
            },
            '& .MuiSlider-thumb': {
              '&.Mui-active': {
                opacity: 1,
              },
            },
            '&:hover': {
              '& .MuiSlider-thumb': {
                opacity: 1,
              },
            },
          }}
        />
      </Box>
    </div>
  )
}

export default VideoPlayer
