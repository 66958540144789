import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import React, { useEffect, useState } from 'react'

export function MRadioGroup<T>(props: {
  id: string
  label: string
  items: T[]
  selectedItem?: T
  parseValue: (item: T) => string
  parseLabel: (item: T) => string
  onSelect: (item: string) => void
  style?: React.CSSProperties
  error?: boolean
}) {
  const {
    id,
    label,
    items,
    selectedItem,
    parseValue,
    parseLabel,
    onSelect,
    style,
    error,
  } = props

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSelect((event.target as HTMLInputElement).value)
  }

  return (
    <FormControl style={style} error={error}>
      <FormLabel id={`radio-group-label-${id}`}>{label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby={`radio-group-label-${id}`}
        value={selectedItem}
        name='radio-group'
        onChange={handleChange}>
        {items.map((item) => (
          <FormControlLabel
            key={parseValue(item)}
            value={parseValue(item)}
            control={<Radio />}
            label={parseLabel(item)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export function MCheckBoxGroup<T>(props: {
  id: string
  label: string
  items: T[]
  selecetItems?: T[]
  parseValue: (item: T) => string
  parseLabel: (item: T) => string
  onChange: (items: T[]) => void
  style?: React.CSSProperties
  error?: boolean
}) {
  const {
    id,
    label,
    items,
    selecetItems,
    parseLabel,
    parseValue,
    onChange,
    style,
    error,
  } = props

  const [itemStates, setItemStates] = useState<{ [key: string]: boolean }>({})

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(
      Object.entries({
        ...itemStates,
        [event.target.name]: event.target.checked,
      })
        .filter(([_, value]) => value)
        .map(([key, _]) => key) as any as T[]
    )
  }

  useEffect(() => {
    if (selecetItems) {
      const tmp: { [key: string]: boolean } = {}
      selecetItems.forEach((item) => (tmp[parseValue(item)] = true))
      setItemStates(tmp)
    }
  }, [selecetItems, parseValue])

  return (
    <FormControl style={style} error={error}>
      <FormLabel id={`check-box-group-label-${id}`}>{label}</FormLabel>
      <FormGroup row>
        {items.map((item) => (
          <FormControlLabel
            key={parseValue(item)}
            control={
              <Checkbox
                checked={itemStates[parseValue(item)]}
                onChange={handleChange}
                name={parseValue(item)}
              />
            }
            label={parseLabel(item)}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}
