import { Download } from '@mui/icons-material'
import { Box, Fab } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { AsyncSearchField } from 'components/Components'
import { useEffect, useState } from 'react'
import { Club, DataStatus, SessionOfClub } from 'types'
import { csvToXlsx, downloadAsFile, myFetch } from 'utils'

const dataStatusList = [
  'SCORED',
  'SESSION_CREATED',
  'SESSION_ENDED',
  'WAITING_FOR_DATA',
  'PROCESSING_DATA',
  'DATA_READY',
  'SCORING_STARTED',
  'SMOOTHING_DONE',
]

const SessionsOfClub = () => {
  const [data, setData] = useState<SessionOfClub[]>([])
  const [club, setClub] = useState<Club>()
  const [data_status, setDataStatus] = useState<DataStatus | null>(null)

  const [loading, setLoading] = useState(false)

  const fetchClubs = async (
    filter: string = '',
    rowsPerPage: number = 10,
    needs_approval: boolean = false
  ): Promise<Club[]> => {
    return await myFetch(
      `/api/clubs/list_clubs?limit=${rowsPerPage}&offset=0&needs_approval=${needs_approval}${
        filter.length > 2 ? `&name=${filter}` : ''
      }`
    ).then((body) => body.json())
  }

  const fetchData = async () => {
    if (!club) return
    setLoading(true)
    await myFetch(
      `/api/sessions/sessions_of_club?club_id=${club.id}${
        data_status ? `&data_status=${data_status}` : ''
      }`
    )
      .then((body) => body.json())
      .then((data: SessionOfClub[]) => {
        if (Array.isArray(data) && data.length > 0) setData(data)
        else setData([])
      })
      .catch(console.log)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [club, data_status])

  return (
    <>
      <Box
        sx={{
          marginBottom: '8px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <AsyncSearchField
          sx={{
            flex: 1,
            marginRight: '8px',
          }}
          label='Club'
          getLabel={(item: Club) => item.name}
          onType={async (value: string) =>
            await fetchClubs(value, 10, false).then((data) => data)
          }
          onSelect={(club: Club) => {
            setClub(club)
          }}
        />
        <AsyncSearchField
          sx={{
            flex: 1,
            marginLeft: '8px',
          }}
          label='Data Status'
          getLabel={(item: string) => item}
          onType={async (value: string) =>
            dataStatusList.filter((item) =>
              item.startsWith(value.toUpperCase())
            )
          }
          onSelect={(data_status: DataStatus) => {
            setDataStatus(data_status)
          }}
        />
      </Box>
      <DataGrid
        loading={loading}
        sx={{
          filter: loading ? 'contrast(.7) brightness(0.7)' : '',
          position: 'relative',
          transition: '200ms',
          height: 'calc(100vh - 250px)',
        }}
        columns={[
          { field: 'col1', headerName: 'Session Number', width: 250 },
          { field: 'col2', headerName: 'Created At', width: 350 },
          { field: 'col3', headerName: 'Creator', width: 250 },
          { field: 'col4', headerName: 'Data Status', width: 250 },
          { field: 'col5', headerName: 'Players', width: 250 },
          { field: 'col6', headerName: 'Age Group', width: 250 },
        ]}
        rows={data.map(
          (
            {
              session_number,
              age_group,
              created_at,
              creator_name,
              data_status,
              players,
            },
            index
          ) => ({
            id: index,
            col1: session_number,
            col2: new Date(created_at).toLocaleDateString('en-US', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false,
            }),
            col3: creator_name,
            col4: data_status,
            col5: players,
            col6: age_group,
          })
        )}
      />
      <Fab
        sx={{
          position: 'absolute',
          bottom: '16px',
          right: '16px',
        }}
        onClick={() => {
          let csv =
            'Session Number,Club ID,Club Name,Age Group,Fixed Goalkeeper,Created At,Players,Players Without Goalkeeper,Data Status,Creator ID,Creator Name'
          data.forEach(
            ({
              session_number,
              club_id,
              club_name,
              age_group,
              fixed_goalkeeper,
              created_at,
              players,
              players_without_goalkeeper,
              data_status,
              creator_id,
              creator_name,
            }) =>
              (csv += `\n${session_number},${club_id},${club_name},${age_group},${fixed_goalkeeper},${created_at},${players},${players_without_goalkeeper},${data_status},${creator_id},${creator_name}`)
          )

          downloadAsFile(csvToXlsx(csv), 'SessionsOfClubs.xlsx')
        }}>
        <Download />
      </Fab>
    </>
  )
}

export default SessionsOfClub
