import React from 'react'
import { Currency } from './codes'

export const QueueVisionStatusTypes = [
  'WAITING',
  'STARTING',
  'RUNNING',
  'FAILED_RUN',
  'DONE',
]

export type QueueVisionStatus = (typeof QueueVisionStatusTypes)[number]

export type QueueVision = {
  created_at: string
  current_details: string
  match_number: number
  session_number: number
  status: QueueVisionStatus
  updated_at: string
  worker: string
}

export const QueuePlayerZoomStatusTypes = [
  'QUEUED',
  'STARTING',
  'DOWNLOADING',
  'ENCODING',
  'UPLOADING',
  'FAILED',
  'DONE',
]

export type QueuePlayerZoomStatus = (typeof QueuePlayerZoomStatusTypes)[number]

export const PricingTypes = ['club', 'user']

export type PricingType = (typeof PricingTypes)[number]

export type Pricing = {
  type: PricingType
  identifier: string
  note: string
  price: string
  id: number
}

export type QueuePlayerZoom = {
  created_at: string
  instance_id: string
  match_number: number
  player_list: number[]
  session_number: number
  status: QueuePlayerZoomStatus
  time: number
  updated_at: string
  updates: {
    status: QueuePlayerZoomStatus
    time_is: string
  }[]
  vuids: string[]
}

export const RunnerStates = [
  'pending',
  'running',
  'stopping',
  'stopped',
  'shutting-down',
  'terminated',
]

export type RunnerStatesType = (typeof RunnerStates)[number]

export type RunnerType = {
  InstanceId: string
  InstanceType: string
  LaunchTime: string
  PublicDnsName: string
  State: RunnerStatesType
  Name: string
  type: string
  env: string
}

export type RunnerResponse = {
  InstanceId: string
  InstanceType: string
  LaunchTime: string
  PublicDnsName: string
  State: RunnerStatesType
  Tags: {
    Key: string
    Value: string
  }[]
}[]

export const ScoreTypes = [
  'Control',
  'Dribbling',
  'Passing',
  'Defense',
  'Physical',
  'Impact',
]
export type ScoreTypesType = (typeof ScoreTypes)[number]

export const AgeGroups = [
  'U10_male',
  'U11_male',
  'U12_male',
  'U13_male',
  'U14_male',
  'U15_male',
  'U16_male',
  'U17_male',
  'U18_male',
  'U19_male',
  'U20_male',
  'U21_male',
  'adult_male',
  'senior_male',
  'U10_female',
  'U11_female',
  'U12_female',
  'U13_female',
  'U14_female',
  'U15_female',
  'U16_female',
  'U17_female',
  'U18_female',
  'U19_female',
  'U20_female',
  'U21_female',
  'adult_female',
  'senior_female',
]
export type AgeGroupsType = (typeof AgeGroups)[number]

export const StatusChoices = [
  'WAITING_FOR_UPLOAD',
  'ENCODING_QUEUED',
  'ENCODING_PREPARE_SUBMISSION',
  'ENCODING_SUBMITTED',
  'ENCODING_QUEUE_FAILED',
  'ENCODING_STARTED',
  'ENCODING_UPLOADING',
  'ENCODING_DONE',
  'WAITING_FOR_PUBLISH',
  'PUBLISHED',
]
export type StatusChoicesType = (typeof StatusChoices)[number]

export const VisibilityChoices = ['public', 'private']
export type VisibilityChoicesType = (typeof VisibilityChoices)[number]

export const EncodingChoices = [
  'no-sound',
  'no-thumbnails',
  'no-preview',
  '480p-max',
  '720p-max',
  '1080p-max',
]
export type EncodingChoicesType = (typeof EncodingChoices)[number]

export type SubScore = {
  rawDataList: {
    name: string
    unit: string
    value: string
  }[]
  scoreValue: number
  type: ScoreTypesType
}

export type SessionScores = {
  ageGroup: AgeGroupsType
  created_at: string
  psid: number
  scoreValue: number
  sessionNumber: number
  subScoreList: SubScore[]
  updated_at: string
  version: string
}[]

export interface UserInfo {
  email: string
  email_verified: boolean
  family_name: string
  given_name: string
  name: string
  preferred_username: string
  sub: string
}

export interface PortalUser {
  createdTimestamp: number
  disableableCredentialTypes: string[]
  email: string
  emailVerified: boolean
  enabled: boolean
  firstName: string
  id: string
  lastName: string
  notBefore: number
  requiredActions: string[]
  totp: boolean
  username: string
}

export interface User {
  access: {
    impersonate: boolean
    manage: boolean
    manageGroupMembership: boolean
    mapRoles: boolean
    view: boolean
  }
  attributes: { [key: string]: string[] }[]
  createdTimestamp: number
  disableCredentialsType: string[]
  email: string
  emailVerified: boolean
  enabled: boolean
  firstName: string
  id: string
  lastName: string
  notBefore: number
  requiredActions: string[]
  totp: boolean
  username: string
}

export interface Mail {
  callback: string
  created_at: string
  message_id: string
  notifications: MailNotification[]
  receiver_mail: string
  sender_mail: string
  status: string
  subject: string
}

export interface MailNotification {
  created_at: string
  raw: {
    delivery?: {
      pcocessingTimeMillis: number
      recipients: string[]
      remoteMtaIp: string
      reportingMta: string
      smtpResponse: string
      timestamp: string
    }
    mail: {
      callerIdentity: string
      commonHeaders: {
        from: string[]
        to: string[]
        subject: string
      }
      destination: string[]
      headers: {
        name: string
        value: string
      }[]
      headersTruncated: boolean
      messageId: string
      sendingAccountId: string
      source: string
      sourceArn: string
      sourceIp: string
      timestamp: string
    }
    notificationType: string
  }
  type: string
}

export interface Club {
  city: string
  code: string
  country: string
  house_number: string
  id: number
  name: string
  needs_approval: boolean
  street: string
  zip: string
}

export interface SessionOfClub {
  session_number: number
  club_id: number
  club_name: string
  age_group: string
  fixed_goalkeeper: boolean
  created_at: string
  players: number
  players_without_goalkeeper: number
  data_status: DataStatus
  creator_id: string
  creator_name: string
}

export interface License {
  company_shares: MoneyPackage
  countries: string[]
  created_at: string
  default_pricing: MoneyPackage
  enabled: boolean
  license_holder: LicenseUser
  license_id: string
  license_worker: string[]
  name: string
  sublicense_of?: string
  valid_until: string
}

export interface LicenseUser {
  email: string
  emailVerified?: boolean
  firstName: string
  id: string
  lastName: string
}

export interface MoneyPackage {
  amount: number
  currency: Currency
}

export interface Group {
  id: string
  name: string
  path: PathType
  user_count: number
  subGroups: Group[]
}

export interface AccessTokenContent extends UserInfo {
  token: string
  acr: string
  'allowed-origins': string[]
  aud: string[]
  auth_time: number
  azp: string
  birthdate: string
  exp: number
  groups: string[]
  iat: number
  iss: string
  jti: string
  roles: string[]
  scope: string
  session_state: string
  typ: string
}

export interface UserContextData {
  tokenData: AccessTokenContent
  setTokenData: (data: AccessTokenContent) => void
}

export type VideoStatus =
  | 'WAITING_FOR_UPLOAD'
  | 'UPLOADED'
  | 'ENCODING_QUEUEING'
  | 'ENCODING_QUEUED'
  | 'ENCODING_QUEUE_FAILED'
  | 'ENCODING_STARTED'
  | 'ENCODING_FAILED'
  | 'ENCODING_DONE'
  | 'WAITING_FOR_PUBLISH'
  | 'PUBLISHED'

export interface VideoInfo {
  uuid: string
  creator: string
  owner: string
  status: VideoStatus
  created_at: string
  updated_at: string
  views: number
  texts: {
    title: string
    subtext: string
    keywords: string[]
  }
  video: {
    preview_path: string
    first_thumbnail: string
  }
  visibility: VisibilityChoicesType
}

export interface VideoPatch {
  vuid: string
  status?: StatusChoicesType
  video_dict?: { [key: string]: any }
  title?: string
  subtext?: string
  keywords?: string[]
  visibility?: VisibilityChoicesType
  owner?: string
}

export type VideoApiResponse = VideoInfo[]

export interface VideoUrlDetails {
  keywords?: string[]
  subtext?: string
  title: string
  url?: string
  cookies: string
}

export interface VideoDetails {
  title?: string
  visibility?: string
  encoding_hints?: string[]
  keywords?: string[]
  sub_text?: string[]
}

export interface UploadResponse {
  upload_url: {
    fields: {
      [key: string]: string
    }
    url: string
  }
  uuid: string
}

export interface TabProps {
  userInfo?: AccessTokenContent
  loadData?: (handlers: {
    setError: (bool: boolean) => void
    setLoading: (bool: boolean) => void
    setData: (data: any) => void
  }) => any
}

export interface TablePaginationActionsProps {
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void
}

export type DataStatus =
  | 'SCORED'
  | 'SESSION_CREATED'
  | 'SESSION_ENDED'
  | 'WAITING_FOR_DATA'
  | 'PROCESSING_DATA'
  | 'DATA_READY'
  | 'SCORING_STARTED'
  | 'SMOOTHING_DONE'

export type VideoAnalyticsStatus =
  | 'WAITING_FOR_VIDEOS'
  | 'VIDEOS_UPLOADED'
  | 'UPLOADING_FAILED'
  | 'VIDEOS_ANALYTICS_QUEUED'
  | 'VIDEOS_ANALYTICS_RUNNING'
  | 'VIDEO_ANALYTICS_FAILED'
  | 'VIDEOS_ANALYTICS_DONE'

export type VideoDeliveryStatus =
  | 'WAITING_FOR_VIDEOS'
  | 'PREPARE_TO_MOVE'
  | 'MOVE_ERROR'
  | 'VIDEOS_SENT_TO_PUBLISH'
  | 'VIDEOS_PUBLISHED'

export type IdVisionStatus =
  | 'WAITING_TO_MOVE'
  | 'PREPARE_TO_MOVE'
  | 'MOVING_DATA'
  | 'MOVING_FINISHED'
  | 'MOVING_FAILED'
  | 'NO_DATA_AVAILABLE'
  | 'BACKEND_FAILED'
  | 'NO_DATA_AVAIABLE'

export interface Session {
  session_number: number
  creator_id: string
  frenchise_id: string
  age_group: string
  club: string
  created_at: string
  player: number
  matches: number
  payment_package: {
    currency: string
    amount: string
  }
  data_status: DataStatus
  video_analytics_status: VideoAnalyticsStatus
  video_delivery_status: VideoDeliveryStatus
  id_vision_status: IdVisionStatus
}

export interface SessionApiResponse {
  total_number_of_sessions: number
  sessions: Session[]
}

export const IDLeagueInternAccountAdmin = '/id_league_intern/accounts/admin'
export const IDLeagueInternAccountViewer = '/id_league_intern/accounts/viewer'

export type IDLeagueInternAccountAdminType = typeof IDLeagueInternAccountAdmin
export type IDLeagueInternAccountViewerType = typeof IDLeagueInternAccountViewer

export const IDLeagueInternAccount = [
  IDLeagueInternAccountAdmin,
  IDLeagueInternAccountViewer,
]

export type IDLeagueInternAccountType =
  | IDLeagueInternAccountAdminType
  | IDLeagueInternAccountViewerType

export const IDLeagueInternClubsAdmin = '/id_league_intern/clubs/admin'
export const IDLeagueInternClubsCreator = '/id_league_intern/clubs/creator'
export const IDLeagueInternClubsViewer = '/id_league_intern/clubs/viewer'

export type IDLeagueInternClubsAdminType = typeof IDLeagueInternClubsAdmin
export type IDLeagueInternClubsCreatorType = typeof IDLeagueInternClubsCreator
export type IDLeagueInternClubsViewerType = typeof IDLeagueInternClubsViewer

export const IDLeagueInternClubs = [
  IDLeagueInternClubsAdmin,
  IDLeagueInternClubsCreator,
  IDLeagueInternClubsViewer,
]

export type IDLeagueInternClubsType =
  | IDLeagueInternClubsAdminType
  | IDLeagueInternClubsCreatorType
  | IDLeagueInternClubsViewerType

export const IDLeagueInternLicensesAdmin = '/id_league_intern/licenses/admin'
export const IDLeagueInternLicensesViewer = '/id_league_intern/licenses/viewer'

export type IDLeagueInternLicensesAdminType = typeof IDLeagueInternLicensesAdmin
export type IDLeagueInternLicensesViewerType =
  typeof IDLeagueInternLicensesViewer

export const IDLeagueInternLicenses = [
  IDLeagueInternLicensesAdmin,
  IDLeagueInternLicensesViewer,
]

export type IDLeagueInternLicensesType =
  | IDLeagueInternLicensesAdminType
  | IDLeagueInternLicensesViewerType

export const IDLeagueInternVideosAdmin = '/id_league_intern/videos/admin'
export const IDLeagueInternVideosViewer = '/id_league_intern/videos/viewer'

export type IDLeagueInternVideosAdminType = typeof IDLeagueInternVideosAdmin
export type IDLeagueInternVideosViewerType = typeof IDLeagueInternVideosViewer

export const IDLeagueInternVideos = [
  IDLeagueInternVideosAdmin,
  IDLeagueInternVideosViewer,
]

export type IDLeagueInternVideosType =
  | IDLeagueInternVideosAdminType
  | IDLeagueInternVideosViewerType

export const IDLeagueInternSuperuser = '/id_league_intern/superuser'
export type IDLeagueInternSuperuserType = typeof IDLeagueInternSuperuser

export type IDLeagueInternType =
  | IDLeagueInternAccountType
  | IDLeagueInternClubsType
  | IDLeagueInternLicensesType
  | IDLeagueInternVideosType
  | IDLeagueInternSuperuserType

export const IDLeagueIntern = [
  ...IDLeagueInternAccount,
  ...IDLeagueInternClubs,
  ...IDLeagueInternLicenses,
  ...IDLeagueInternVideos,
  IDLeagueInternSuperuser,
]

export const KickIdExternFinanceHolder = '/kickid_extern/finance/license_holder'

export type KickIdExternFinanceHolderType = typeof KickIdExternFinanceHolder

export const KickIdExternFinance = [KickIdExternFinanceHolder]

export type KickIdExternFinanceType = KickIdExternFinanceHolderType

export const KickIdExternLicensesHolder =
  '/kickid_extern/licenses/license_holder'
export const KickIdExternLicensesUser = '/kickid_extern/licenses/license_user'

export type KickIdExternLicensesHolderType = typeof KickIdExternLicensesHolder
export type KickIdExternLicensesUserType = typeof KickIdExternLicensesUser

export const KickIdExternLicenses = [
  KickIdExternLicensesHolder,
  KickIdExternLicensesUser,
]

export type KickIdExternLicensesType =
  | KickIdExternLicensesHolderType
  | KickIdExternLicensesUserType

export const KickIdExternSessionsHolder =
  '/kickid_extern/sessions/license_holder'
export const KickIdExternSessionsUser = '/kickid_extern/sessions/license_user'

export type KickIdExternSessionsHolderType = typeof KickIdExternSessionsHolder
export type KickIdExternSessionsUserType = typeof KickIdExternSessionsUser

export const KickIdExternSessions = [
  KickIdExternSessionsHolder,
  KickIdExternSessionsUser,
]

export type KickIdExternSessionsType =
  | KickIdExternSessionsHolderType
  | KickIdExternSessionsUserType

export type KickIdExternType =
  | KickIdExternFinanceType
  | KickIdExternLicensesType
  | KickIdExternSessionsType

export const KickIdExtern = [
  ...KickIdExternFinance,
  ...KickIdExternLicenses,
  ...KickIdExternSessions,
]

export const AllPaths = [...IDLeagueIntern, ...KickIdExtern]

export type PathType = IDLeagueInternType | KickIdExternType

export { codes, currency } from './codes'
export type { Codes, Currency } from './codes'

export type VideoDownload = {
  session_number: number
  player_session_id: number
  match_number: number
  url: string
  ttl: number
}

export type CreatorStats = {
  _id: string
  count: number
  creator_name: string
}
