import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import * as React from 'react'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function AlertDialog(props: {
  open: boolean
  title: string
  contentText?: string
  content?: JSX.Element
  leftButton?: {
    text: string
    onClick?: () => void
  }
  rightButton?: {
    text: string
    onClick?: () => void
  }
  onClose: () => void
}) {
  const {
    open,
    title,
    contentText,
    content,
    leftButton,
    rightButton,
    onClose,
  } = props

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      style={{ zIndex: '1800' }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {content ?? <DialogContentText>{contentText}</DialogContentText>}
      </DialogContent>
      <DialogActions>
        {leftButton && (
          <Button
            onClick={(event) => {
              event.stopPropagation()
              onClose()
              leftButton?.onClick?.()
            }}>
            {leftButton?.text}
          </Button>
        )}
        {rightButton && (
          <Button
            onClick={(event) => {
              event.stopPropagation()
              onClose()
              rightButton?.onClick?.()
            }}>
            {rightButton?.text}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
