import {
    KeyboardArrowDown,
    KeyboardArrowUp,
    EditOutlined,
    DoneOutlined,
} from '@mui/icons-material'
import {
    Box,
    Button,
    capitalize,
    Collapse,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { SessionApiResponse, Session } from 'types'
import { myFetch } from 'utils'
import { TablePaginationActions, Loading } from 'components'
import { format, parseISO } from 'date-fns'
import ScoreDialog from './ScoreDialog'

const colorMap: { [key: string]: string } = {
    SESSION_CREATED: '#ADD8E6',
    SESSION_ENDED: '#90EE90',
    SMOOTHING_DONE: '#90EE90',
    WAITING_FOR_DATA: '#90EE90',
    PROCESSING_DATA: '#90EE90',
    DATA_READY: '#00FF00',
    SCORING_STARTED: '#90EE90',
    SCORED: '#00FF00',
    WAITING_FOR_VIDEOS: '#90EE90',
    VIDEOS_UPLOADED: '#90EE90',
    UPLOADING_FAILED: '#F10000',
    VIDEOS_ANALYTICS_QUEUED: '#90EE90',
    VIDEOS_ANALYTICS_RUNNING: '#90EE90',
    VIDEO_ANALYTICS_FAILED: '#F10000',
    VIDEOS_ANALYTICS_DONE: '#00FF00',
    PREPARE_TO_MOVE: '#90EE90',
    MOVE_ERROR: '#F10000',
    VIDEOS_SENT_TO_PUBLISH: '#90EE90',
    WAITING_TO_MOVE: '#90EE90',
    MOVING_DATA: '#90EE90',
    MOVING_FINISHED: '#00FF00',
    MOVING_FAILED: '#F10000',
    NO_DATA_AVAILABLE: '#FBFB91',
    NO_DATA_AVAIABLE: '#FBFB91',
    BACKEND_FAILED: '#F10000',
    VIDEOS_PUBLISHED: '#00FF00',
}

interface Data {
    empty: string
    session_number: string
    created_at: string
    age_group: string
    club: string
    data_status: string
    video_analytics_status: string
    video_delivery_status: string
    id_vision_status: string
}

type Order = 'asc' | 'desc'

function descendingComparator(a: any, b: any, orderBy: keyof Data) {
    const dataA = a[orderBy]
    const dataB = b[orderBy]
    if (!dataA || !dataB) return 0
    return dataA.toString().localeCompare(dataB.toString())
}

function getComparator<Session>(
    order: Order,
    orderBy: keyof Data
): (a: Session, b: Session) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

const EnhancedTableHead = (props: {
    orderBy: keyof Data
    order: Order
    onRequestSort?: (
        event: React.MouseEvent<unknown>,
        property: keyof Data
    ) => void
    tabs: {
        id: keyof Data
        label?: string
        align?: 'left' | 'right'
    }[]
}) => {
    const { tabs, orderBy, order, onRequestSort } = props

    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort?.(event, property)
        }

    return (
        <TableHead>
            <TableRow>
                {tabs.map((tab, index) => (
                    <TableCell
                        key={`${tab.id}${index}`}
                        align={tab.align}
                        sortDirection={orderBy === tab.id ? order : false}>
                        {tab.id !== 'empty' ? (
                            <TableSortLabel
                                active={orderBy === tab.id}
                                direction={orderBy === tab.id ? order : 'asc'}
                                onClick={createSortHandler(tab.id)}>
                                {tab.label ?? ''}
                            </TableSortLabel>
                        ) : (
                            tab.label
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

const SessionDetailsItem = (props: {
    primary: string
    secondary: string
    name: string
    sessionNumber: number | null
    refetch: () => void
}) => {
    const { primary, secondary, name: key, sessionNumber, refetch } = props

    const secondaryNumber = secondary.match(/(\d+\.\d+)/)?.[0]

    const [isEditing, setEditing] = useState(false)
    const [value, setValue] = useState(secondaryNumber)
    const [message, setMessage] = useState('')
    const [isError, setError] = useState(false)

    const isPayment = key === 'payment_package'

    const submitPayment = async (newPrice: string) => {
        return await myFetch(`/api/session/update_price`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            query: {
                session_number: sessionNumber,
                new_price: newPrice,
            },
        }).then(async (res) => ({ status: res.status, ...(await res.json()) }))
    }

    return (
        <ListItem key={key}>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    marginRight: '10px',
                }}>
                {isPayment && isEditing ? (
                    <>
                        <ListItemText primary={primary}/>
                        <TextField
                            name={primary}
                            label={primary}
                            value={value}
                            onChange={(event) => {
                                setValue(event.target.value)
                            }}
                            style={{
                                marginTop: '5px',
                            }}
                        />
                    </>
                ) : (
                    <ListItemText
                        primary={primary}
                        secondary={secondary}
                        secondaryTypographyProps={{
                            style: {
                                color: colorMap[secondary],
                            },
                        }}
                    />
                )}
                {message && (
                    <Typography color={isError ? 'error' : 'success'}>
                        {message}
                    </Typography>
                )}
            </Box>
            {isPayment &&
                (isEditing ? (
                    <IconButton
                        edge='end'
                        aria-label='submit'
                        onClick={() => {
                            console.log(sessionNumber)
                            submitPayment(value || '0').then(({ status, message }) => {
                                setError(status !== 200)
                                setMessage(message)
                                setEditing(false)

                                refetch()
                            })
                        }}>
                        <DoneOutlined/>
                    </IconButton>
                ) : (
                    <IconButton
                        edge='end'
                        aria-label='edit'
                        onClick={() => setEditing(true)}>
                        <EditOutlined/>
                    </IconButton>
                ))}
        </ListItem>
    )
}

const Sessions = () => {
    const colSpan = 10

    const [sessionCount, setSessionCount] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [rows, setRows] = useState<Session[]>([])
    const [page, setPage] = useState(0)
    const [loading, setLoading] = useState(false)
    const [orderBy, setOrderBy] = useState<keyof Data>('session_number')
    const [order, setOrder] = useState<Order>('asc')
    const [selectedSessionNumber, setSelectedSessionNumber] = useState<
        number | null
    >(null)

    const emptyRows = rowsPerPage - rows.length

    const fetchRows = async (page: number = 0, rowsPerPage: number = 5) => {
        setLoading(true)
        await myFetch(
            `/api/sessions?limit=${rowsPerPage}&offset=${page * rowsPerPage}`
        )
            .then((body) => body.json())
            .then((data: SessionApiResponse) => {
                if (data.sessions) setRows(data.sessions)
                if (data.total_number_of_sessions)
                    setSessionCount(data.total_number_of_sessions)
            })
        setLoading(false)
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    useEffect(() => {
        fetchRows(page, rowsPerPage)

        return () => {
        }
    }, [page, rowsPerPage])

    const Row = (props: { session: Session }) => {
        const [open, setOpen] = useState(false)

        const details = Object.entries(props.session)
            .map((entry) => {
                const beautifyName = (str: string) =>
                    str
                        .split('_')
                        .map((s) => capitalize(s))
                        .join(' ')
                const parseMoney = (obj: any) => {
                    if (obj.currency) return `${obj.amount} ${obj.currency}`
                    return JSON.stringify(obj)
                }
                return {
                    primary: beautifyName(entry[0]),
                    secondary:
                        typeof entry[1] === 'object'
                            ? parseMoney(entry[1])
                            : entry[1].toString(),
                    key: entry[0],
                }
            })
            .filter(({ secondary }) => secondary.length > 0)

        return (
            <>
                <TableRow sx={{ '& > .MuiTableCell-body': { borderBottom: 'unset' } }}>
                    <TableCell>
                        <IconButton size='small' onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                        </IconButton>
                    </TableCell>
                    <TableCell component='th' scope='row'>
                        {props.session.session_number}
                    </TableCell>
                    <TableCell component='th' scope='row'>
                        <Button
                            size='small'
                            disabled={props.session.data_status !== 'SCORED'}
                            onClick={() =>
                                setSelectedSessionNumber(props.session.session_number)
                            }>
                            Scores
                        </Button>
                    </TableCell>
                    <TableCell component='th' scope='row'>
                        {format(parseISO(props.session.created_at), 'dd.MM.yyyy')}
                    </TableCell>
                    <TableCell align='left'>{props.session.age_group}</TableCell>
                    <TableCell align='left'>{props.session.club}</TableCell>
                    <TableCell
                        align='right'
                        sx={{ color: colorMap[props.session.data_status] }}>
                        {props.session.data_status}
                    </TableCell>
                    <TableCell
                        align='right'
                        sx={{ color: colorMap[props.session.video_analytics_status] }}>
                        {props.session.video_analytics_status}
                    </TableCell>
                    <TableCell
                        align='right'
                        sx={{ color: colorMap[props.session.video_delivery_status] }}>
                        {props.session.video_delivery_status}
                    </TableCell>
                    <TableCell
                        align='right'
                        sx={{ color: colorMap[props.session.id_vision_status] }}>
                        {props.session.id_vision_status}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={colSpan}>
                        <Collapse in={open} timeout='auto' unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                {/*
                                TODO
                                prepare to be editable
                            */}
                                <Typography variant='h6' gutterBottom component='div'>
                                    Details
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <List>
                                            {details.map((entry, index) =>
                                                index < details.length / 2 ? (
                                                    <SessionDetailsItem
                                                        {...entry}
                                                        name={entry.key}
                                                        sessionNumber={props.session.session_number}
                                                        refetch={() => {
                                                            fetchRows(page, rowsPerPage)
                                                        }}
                                                    />
                                                ) : (
                                                    <></>
                                                )
                                            )}
                                        </List>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <List>
                                            {details.map((entry, index) =>
                                                index >= details.length / 2 ? (
                                                    <SessionDetailsItem
                                                        {...entry}
                                                        name={entry.key}
                                                        sessionNumber={props.session.session_number}
                                                        refetch={() => {
                                                            fetchRows(page, rowsPerPage)
                                                        }}
                                                    />
                                                ) : (
                                                    <></>
                                                )
                                            )}
                                        </List>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        )
    }

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data
    ) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    return (
        <>
            <ScoreDialog
                sessionNumber={selectedSessionNumber}
                onClose={() => setSelectedSessionNumber(null)}
            />
            <TableContainer
                component={Paper}
                sx={{
                    filter: loading ? 'contrast(.7) brightness(0.7)' : '',
                    position: 'relative',
                }}>
                {loading && <Loading/>}
                <Table sx={{ minWidth: 500 }}>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        tabs={[
                            { id: 'empty' },
                            {
                                id: 'session_number',
                                label: 'Session Number',
                                align: 'left',
                            },
                            {
                                id: 'empty',
                                align: 'left',
                            },
                            {
                                id: 'created_at',
                                label: 'Date',
                                align: 'left',
                            },
                            {
                                id: 'age_group',
                                label: 'Age Group',
                                align: 'left',
                            },
                            {
                                id: 'club',
                                label: 'Club',
                                align: 'left',
                            },
                            {
                                id: 'data_status',
                                label: 'Data Status',
                                align: 'right',
                            },
                            {
                                id: 'video_analytics_status',
                                label: 'Video Analytics Status',
                                align: 'right',
                            },
                            {
                                id: 'video_delivery_status',
                                label: 'Video Delivery Status',
                                align: 'right',
                            },
                            {
                                id: 'id_vision_status',
                                label: 'Id Vision Status',
                                align: 'right',
                            },
                        ]}
                    />
                    <TableBody>
                        {rows
                            .slice()
                            .sort(getComparator(order, orderBy))
                            .map((row) => (
                                <Row session={row} key={row.session_number}/>
                            ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={colSpan}/>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                colSpan={colSpan}
                                rowsPerPage={rowsPerPage}
                                count={sessionCount}
                                page={page}
                                SelectProps={{ native: true }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    )
}

export default Sessions
