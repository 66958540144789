import { Chip, TextField, TextFieldProps } from '@mui/material'
import Downshift from 'downshift'
import React, { CSSProperties, useEffect } from 'react'

/**
 * Input field with tags
 * 
 * when you type and there is a tag that matches the input, it will be selected
 * 
 * 
 * @param props.tags tags that will be selected
 * @param props.placeholder placeholder of the input
 * @param props.mainStyle style of the input
 * @param props.selectedTags callback when tags are selected
 * @param props.TextFieldProps other props of the input
 * 
 */
const TagsInput = (
  props: {
    tags: string[]
    placeholder?: string
    mainStyle?: CSSProperties
    selectedTags: (tags: string[]) => void
  } & TextFieldProps
) => {
  const { selectedTags, placeholder, tags, mainStyle, ...other } = props
  const [inputValue, setInputValue] = React.useState('')
  const [selectedItem, setSelectedItem] = React.useState<string[]>([])

  useEffect(() => {
    setSelectedItem(tags)
  }, [tags])

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      const newSelectedItem = [...selectedItem]
      const duplicatedValues = newSelectedItem.indexOf(
        (event.target as any).value.trim()
      )

      if (duplicatedValues !== -1) {
        setInputValue('')
        return
      }
      if (!(event.target as any).value.replace(/\s/g, '').length) return

      newSelectedItem.push((event.target as any).value.trim())
      setSelectedItem(newSelectedItem)
      selectedTags(newSelectedItem)
      setInputValue('')
    }
    if (
      selectedItem.length &&
      !inputValue.length &&
      event.key === 'Backspace'
    ) {
      const newSelectedItem = selectedItem.slice(0, selectedItem.length - 1)
      setSelectedItem(newSelectedItem)
      selectedTags(newSelectedItem)
    }
  }

  function handleChange(item: string | null) {
    if (item === null) return
    let newSelectedItem = [...selectedItem]
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item]
    }
    setInputValue('')
    setSelectedItem(newSelectedItem)
  }

  const handleDelete = (item: string) => () => {
    const newSelectedItem = [...selectedItem]
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1)
    setSelectedItem(newSelectedItem)
    selectedTags(newSelectedItem)
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value)
  }

  return (
    <>
      <Downshift
        id='downshift-multiple'
        inputValue={inputValue}
        onChange={handleChange}>
        {({ getInputProps }) => {
          const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
            onKeyDown: handleKeyDown,
            placeholder,
          })
          return (
            <div style={mainStyle}>
              <TextField
                {...(other as any)}
                {...(inputProps as any)}
                style={{
                  ...other.style,
                  ...inputProps.style,
                  width: '100%',
                }}
                sx={{
                  input: {
                    paddingLeft: '6px',
                  },
                }}
                InputProps={{
                  startAdornment: selectedItem.map((item) => (
                    <Chip
                      key={item}
                      tabIndex={-1}
                      label={item}
                      variant={(other as any).variant}
                      style={{ margin: 3 }}
                      onDelete={handleDelete(item)}
                    />
                  )),
                  onBlur,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    handleInputChange(event)
                    onChange?.(event)
                  },
                  onFocus,
                }}
              />
            </div>
          )
        }}
      </Downshift>
    </>
  )
}

export default TagsInput
