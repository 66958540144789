import { Add, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { Loading, TablePaginationActions } from 'components'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Club } from 'types'
import { myFetch, useCustomAlert } from 'utils'
import CreateClubDialog from './CreateClubDialog'

const Clubs = () => {
  const infoAlert = useRef<HTMLDivElement | null>(null)

  const showAlert = useCustomAlert(infoAlert)

  const [createClubOpen, setCreateClubOpen] = useState(false)
  const [clubCount, setClubCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [rows, setRows] = useState<Club[]>([])
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [approveClub, setApproveClub] = useState<Club>()
  const [filter, setFilter] = useState('')
  const [needsApproval, setNeedsApproval] = useState(false)

  const emptyRows = rowsPerPage - rows.length

  const fetchRows = async (
    page: number = 0,
    rowsPerPage: number = 5,
    filter: string = '',
    needs_approval: boolean = false
  ) => {
    setLoading(true)
    await myFetch(
      `/api/clubs/list_clubs?limit=${rowsPerPage}&offset=${
        page * rowsPerPage
      }&needs_approval=${needs_approval}${
        filter.length > 2 ? `&name=${filter}` : ''
      }`
    )
      .then((body) => body.json())
      .then((data: Club[]) => {
        setRows(data)
        setClubCount((page + 1) * rowsPerPage + 1)
      })
    setLoading(false)
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    fetchRows(page, rowsPerPage, filter, needsApproval)

    return () => {}
  }, [page, rowsPerPage, filter, needsApproval])

  const handleClose = () => {
    setApproveClub(undefined)
  }

  const Row = (props: { club: Club }) => {
    const [open, setOpen] = useState(false)

    const city = useRef<HTMLInputElement>()
    const code = useRef<HTMLInputElement>()
    const country = useRef<HTMLInputElement>()
    const houseNumber = useRef<HTMLInputElement>()
    const name = useRef<HTMLInputElement>()
    const street = useRef<HTMLInputElement>()
    const zip = useRef<HTMLInputElement>()

    const highlight = (text: string, mark: string) => {
      const list = text.split(mark)
      return list.map((t: string, index: number) => (
        <>
          {t}
          {index + 1 < list.length ? <mark>{mark}</mark> : null}
        </>
      ))
    }

    return (
      <>
        <TableRow
          sx={[
            {
              background: 'transparent',
              transition: '200ms',
              '& > .MuiTableCell-body': { borderBottom: 'unset' },
              '& mark': {
                background: '#DFE376',
              },
            },
            props.club.needs_approval ? { background: '#FFEF0030' } : {},
            open ? { background: '#FFFFFF12' } : {},
          ]}>
          <TableCell>
            <IconButton size='small' onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell component='th' scope='row'>
            {props.club.id}
          </TableCell>
          <TableCell align='left'>
            {filter.length > 2
              ? highlight(props.club.name, filter)
              : props.club.name}
          </TableCell>
          <TableCell align='left'>{props.club.city}</TableCell>
          <TableCell align='right'>{props.club.code}</TableCell>
          <TableCell align='right'>
            <Button
              disabled={!props.club.needs_approval}
              variant='contained'
              onClick={() => setApproveClub(props.club)}>
              Approve
            </Button>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={[
              {
                paddingBottom: 0,
                paddingTop: 0,
                background: 'transparent',
                transition: '200ms',
              },
              open ? { background: '#FFFFFF12' } : {},
            ]}
            colSpan={6}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant='h6' gutterBottom component='div'>
                  Edit
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      inputRef={city}
                      fullWidth
                      id='City'
                      label='City'
                      variant='outlined'
                      defaultValue={props.club.city}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      inputRef={code}
                      fullWidth
                      id='Code'
                      label='Code'
                      variant='outlined'
                      defaultValue={props.club.code}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      inputRef={country}
                      fullWidth
                      id='Country'
                      label='Country'
                      variant='outlined'
                      defaultValue={props.club.country}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      inputRef={houseNumber}
                      fullWidth
                      id='Housenumber'
                      label='Housenumber'
                      variant='outlined'
                      defaultValue={props.club.house_number}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      inputRef={name}
                      fullWidth
                      id='Name'
                      label='Name'
                      variant='outlined'
                      defaultValue={props.club.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      inputRef={street}
                      fullWidth
                      id='Street'
                      label='Street'
                      variant='outlined'
                      defaultValue={props.club.street}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      inputRef={zip}
                      fullWidth
                      id='Zip'
                      label='Zip'
                      variant='outlined'
                      defaultValue={props.club.zip}
                    />
                  </Grid>
                </Grid>
                <Button
                  variant='outlined'
                  sx={{
                    float: 'right',
                    margin: '8px',
                  }}
                  onClick={async () => {
                    const data = {
                      id: props.club.id,
                      city: city.current?.value,
                      code: code.current?.value,
                      country: country.current?.value,
                      house_number: houseNumber.current?.value,
                      name: name.current?.value,
                      street: street.current?.value,
                      zip: zip.current?.value,
                    }
                    await myFetch(`/api/clubs/edit_club`, {
                      body: JSON.stringify(data),
                      method: 'POST',
                      headers: {
                        'content-type': 'application/json',
                      },
                    })
                    fetchRows(page, rowsPerPage)
                  }}>
                  Save
                </Button>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  }

  return (
    <>
      <TextField
        fullWidth
        variant='outlined'
        label='Name'
        autoComplete='new-password'
        sx={{
          marginBottom: '8px',
        }}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = event.target.value
          if (value.length > 2) setFilter(value)
          else setFilter('')
        }}
      />
      <FormControlLabel
        control={
          <Switch
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setNeedsApproval(event.target.checked)
            }}
          />
        }
        label='Needs Approval'
        sx={{
          color: 'white',
          marginBottom: '24px',
        }}
      />
      <TableContainer
        component={Paper}
        sx={{
          filter: loading ? 'contrast(.7) brightness(0.7)' : '',
          position: 'relative',
          transition: '200ms',
          marginBottom: '50px',
        }}>
        {loading && <Loading />}
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align='left'>Id</TableCell>
              <TableCell align='left'>Name</TableCell>
              <TableCell align='left'>City</TableCell>
              <TableCell align='right'>Code</TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row club={row} key={row.id} />
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 69 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                colSpan={6}
                rowsPerPage={rowsPerPage}
                count={clubCount}
                page={page}
                SelectProps={{ native: true }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Dialog
        maxWidth='xl'
        open={!!approveClub}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '8px',
            '::-webkit-scrollbar': {
              width: '5px',
              height: '5px',
            },
            '::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '::-webkit-scrollbar-thumb': {
              borderRadius: '8px',
            },
          },
        }}>
        <DialogTitle>Approve Club</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want do approve the club '{approveClub?.name?.trim() ?? ''}'?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={async () => {
              handleClose()
              setLoading(true)
              await myFetch(`/api/clubs/approve_club?id=${approveClub?.id}`, {
                method: 'POST',
              })
              await fetchRows(page, rowsPerPage, filter, needsApproval)
            }}
            autoFocus>
            Approve
          </Button>
        </DialogActions>
      </Dialog>
      <CreateClubDialog
        open={createClubOpen}
        onClose={() => setCreateClubOpen(false)}
        callback={() => fetchRows(page, rowsPerPage, filter, needsApproval)}
        onError={async (body) => {
          const fetchResponse = async (body?: Response) => {
            try {
              return body
                ?.json()
                .then((json) => json.msg ?? JSON.stringify(json))
            } catch (e) {
              return body?.text()
            }
          }
          showAlert(await fetchResponse(body))
        }}
      />
      <Fab
        sx={{
          position: 'absolute',
          bottom: '16px',
          right: '16px',
        }}
        onClick={() => setCreateClubOpen(true)}>
        <Add />
      </Fab>
      <Alert
        ref={infoAlert}
        style={{ display: 'none' }}
        sx={{
          position: 'absolute',
          bottom: 10,
          right: 10,
        }}
        severity='info'
        elevation={6}
        variant='filled'
      />
    </>
  )
}

export default Clubs
