import { ArrowLeftOutlined, ArrowRightOutlined } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { SessionScores, SubScore } from 'types/types'
import { myFetch } from 'utils/utils'
import { CircularProgressAnimation, Dropdown } from './Components'
import Translations from './Translations.json'

function hslToHex(h: number, s: number, l: number) {
  l /= 100
  const a = (s * Math.min(l, 1 - l)) / 100
  const f = (n: number) => {
    const k = (n + h / 30) % 12
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0')
  }
  return `#${f(0)}${f(8)}${f(4)}`
}

function getScoreColor(amount: number) {
  /*const split = 60
    const ratio = amount < split ? amount / split : (amount - split) / 40
    const inversedRatio = 1 - ratio

    const red = [0xa1, 0x03, 0x03]
    const yellow = [0xe8, 0xde, 0x27]
    const green = [0x0, 0xcf, 0x0]

    const colorA = amount < split ? red : yellow
    const colorB = amount < split ? yellow : green

    const hex = colorA
        .map((v, i) => Math.floor(v * inversedRatio + colorB[i] * ratio))
        .map(v => v.toString(16))
        .map(v => v.length === 1 ? `0${v}` : v)
        .join('')

    console.log(`%c${amount} > ${hex}`, `color:#${hex}`)
    return `#${hex}`*/
  return hslToHex(360 - 40 + 320 * (amount / 100), 90, 55)
}

const CustomAccordion = (props: { subScore: SubScore }) => {
  const { subScore } = props

  const [expanded, setExpanded] = useState(false)

  return (
    <Accordion onChange={(_, isExpanded) => setExpanded(isExpanded)}>
      <AccordionSummary
        sx={{
          position: 'relative',
          background: 'black',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            zIndex: 1,
          }}>
          <Typography sx={expanded ? { color: 'black' } : {}}>
            {subScore.type}
          </Typography>
          {!expanded && <Typography>{subScore.scoreValue}</Typography>}
        </Box>
        <Box
          width={`${subScore.scoreValue}%`}
          position='absolute'
          left={0}
          top={0}
          height={1}
          sx={{
            background: expanded
              ? 'linear-gradient(180deg, #46FF44, #0BFF8A)'
              : 'linear-gradient(180deg, #FFFFFF10, #FFFFFF10)',
            borderRight: '1px solid #0BFF8A',
            transition: '1000ms',
          }}
        />
      </AccordionSummary>
      <AccordionDetails>
        <Grid container rowSpacing={2} columnSpacing={2} sx={{ margin: '2px' }}>
          {subScore.rawDataList.map((data) => (
            <Grid item xs={4}>
              <Typography fontSize={20}>
                {data.value}
                {data.unit}
              </Typography>
              <Typography fontSize={14} color='#FFFFFF40'>
                {(Translations as any)[data.name]}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

const PlayerPage = (props: {
  selectedTab: number
  scoreData: SessionScores
}) => {
  const { selectedTab, scoreData } = props

  const [playerScore, setPlayerScore] = useState(
    scoreData.find((score) => score.psid === selectedTab)
  )

  useEffect(() => {
    setPlayerScore(scoreData.find((score) => score.psid === selectedTab))
  }, [selectedTab, scoreData])

  return (
    <Box
      sx={{
        margin: '30px',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box
        sx={{
          width: '300px',
          height: '300px',
          position: 'relative',
          margin: 'auto',
        }}>
        <CircularProgressAnimation
          duration={1000}
          animate
          maxValue={playerScore?.scoreValue ?? 0}
          size='100%'
        />
        <Typography
          fontSize='500%'
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
          {playerScore?.scoreValue}
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: '32px',
        }}>
        {playerScore?.subScoreList?.map((subScore) => (
          <CustomAccordion subScore={subScore} />
        ))}
      </Box>
    </Box>
  )
}

const ScoreDialog = (props: {
  sessionNumber?: number | null
  onClose?: () => void
}) => {
  const { sessionNumber, onClose } = props

  const [scoreData, setScoreData] = useState<SessionScores>([])
  const [items, setItems] = useState([{ title: 'Overview', value: 0 }])
  const [selectedTab, setSelectedTab] = useState(0)

  async function fetchScores() {
    await myFetch(`/api/scores?session_number=${sessionNumber}`)
      .then((body) => body.json())
      .then((data: SessionScores) => {
        setScoreData(data)
      })
  }

  useEffect(() => {
    if (sessionNumber) fetchScores()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionNumber])

  useEffect(() => {
    if (sessionNumber)
      setItems([
        { title: `Overview (${sessionNumber})`, value: 0 },
        ...scoreData.map((score) => ({
          title: `Player_${score.psid}`,
          value: score.psid,
        })),
      ])
  }, [scoreData, sessionNumber])

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='xl'
        open={!!sessionNumber}
        onClose={() => {
          setSelectedTab(0)
          onClose?.()
        }}>
        <Box
          sx={{
            width: '100%',
            minHeight: '30vh',
            maxWidth: '100%',
            maxHeight: '90vh',
            overflowX: 'hidden',

            display: 'flex',
            flexDirection: 'column',
          }}>
          <Box
            sx={{
              margin: '30px 12px 12px 12px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <IconButton
              style={{ width: '56px', height: '56px' }}
              disabled={items.findIndex((i) => i.value === selectedTab) === 0}
              onClick={() => {
                setSelectedTab((value) => {
                  const index = items.findIndex((i) => i.value === value)
                  return items[index > 0 ? index - 1 : index].value
                })
              }}>
              <ArrowLeftOutlined />
            </IconButton>
            <Dropdown
              default={items[0]}
              value={items.find((i) => i.value === selectedTab)}
              style={{
                flexBasis: 'auto',
                flexGrow: 1,
                marginLeft: '15px',
                marginRight: '15px',
              }}
              label=''
              items={items}
              onSelect={(item) => {
                if (item) setSelectedTab(item.value)
              }}
            />
            <IconButton
              style={{ width: '56px', height: '56px' }}
              disabled={
                items.findIndex((i) => i.value === selectedTab) ===
                items.length - 1
              }
              onClick={() => {
                setSelectedTab((value) => {
                  const index = items.findIndex((i) => i.value === value)
                  return items[index < items.length - 1 ? index + 1 : index]
                    .value
                })
              }}>
              <ArrowRightOutlined />
            </IconButton>
          </Box>
          {selectedTab === 0 ? (
            <Box sx={{ margin: '30px' }}>
              <TableContainer component={Paper}>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>pid</TableCell>
                      <TableCell align='right'>ALL</TableCell>
                      <TableCell align='right'>PAS</TableCell>
                      <TableCell align='right'>IMP</TableCell>
                      <TableCell align='right'>PHY</TableCell>
                      <TableCell align='right'>DRI</TableCell>
                      <TableCell align='right'>DEF</TableCell>
                      <TableCell align='right'>CON</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {scoreData.map((score, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:nth-of-type(odd)': {
                            background: '#FFFFFF10',
                          },
                        }}>
                        <TableCell>{score.psid}</TableCell>
                        <TableCell
                          align='right'
                          style={{ color: getScoreColor(score.scoreValue) }}>
                          {score.scoreValue}
                        </TableCell>
                        {score.subScoreList.map((subScore, index) => (
                          <TableCell
                            key={index}
                            align='right'
                            style={{
                              color: getScoreColor(subScore.scoreValue),
                            }}>
                            {subScore.scoreValue}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : (
            <PlayerPage scoreData={scoreData} selectedTab={selectedTab} />
          )}
        </Box>
      </Dialog>
    </>
  )
}

export default ScoreDialog
