import { Close, Publish, Upload } from '@mui/icons-material'
import {
  Box,
  Button,
  Chip,
  Dialog,
  IconButton,
  Typography,
  capitalize,
} from '@mui/material'
import {
  AlertDialog,
  AsyncSearchField,
  CheckBoxGroup,
  CircularProgressWithLabel,
  DefaultTextInput,
  RadioGroup,
  TagsInput,
  VideoPlayer,
} from 'components'
import Hls, {
  LevelParsed,
  LevelSwitchedData,
  ManifestLoadedData,
} from 'hls.js'
import { useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import {
  EncodingChoices,
  EncodingChoicesType,
  UploadResponse,
  VideoDetails,
  VideoInfo,
  VideoPatch,
  VisibilityChoices,
  VisibilityChoicesType,
} from 'types'
import { convertToDuration, myFetch } from 'utils'

export const VideoDialog = (props: {
  open: boolean
  videoDialog?: { source: string; title: string }
  handleClose: () => void
}) => {
  const videoElement = useRef<HTMLVideoElement>(null)

  const [hls, setHls] = useState<Hls>()
  const [videoQuality, setVideoQuality] = useState<{
    current: number
    levels: LevelParsed[]
  }>()

  const { open, videoDialog, handleClose } = props

  useEffect(() => {
    setHls(undefined)
    setVideoQuality(undefined)
  }, [open])

  useEffect(() => {
    setTimeout(() => {
      const current = videoElement.current

      const videoSource = videoDialog?.source

      if (current && videoSource && !hls) {
        if (current.canPlayType('application/vnd.apple.mpegurl')) {
          current.src = videoSource
          current.style.background = 'black'
        } else if (Hls.isSupported()) {
          const hls = new Hls({
            startLevel: -1,
            xhrSetup: function (xhr) {
              xhr.withCredentials = true
            },
          })
          setHls(hls)
          hls.loadSource(videoSource)
          hls.attachMedia(current)
          hls.once(Hls.Events.MANIFEST_LOADED, (_, data: ManifestLoadedData) => {
            setVideoQuality({
              current: -1,
              levels: data.levels,
            })
          })
          hls.on(Hls.Events.LEVEL_SWITCHED, (_, data: LevelSwitchedData) => {
            setVideoQuality((videoQuality) => ({
              current: data.level,
              levels: videoQuality?.levels ?? [],
            }))
          })
          current.style.background = 'black'
        }
      }
    }, 100)

    return () => {
      hls?.destroy()
    }
  }, [videoElement, hls, videoDialog])

  useEffect(() => {
    if (videoQuality && hls) hls.nextLevel = videoQuality.current
    return () => {}
  }, [videoQuality, hls])

  return (
    <Dialog fullWidth maxWidth='xl' open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'relative',
          top: 0,
          left: 0,
          zIndex: 101,
        }}>
        <Typography
          sx={{
            position: 'absolute',
            top: 5,
            left: 10,
          }}
          variant='h6'
          component='div'>
          {videoDialog?.title}
        </Typography>
        <IconButton
          edge='start'
          color='inherit'
          onClick={handleClose}
          arial-label='close'
          sx={{
            position: 'absolute',
            top: 5,
            right: 5,
          }}>
          <Close />
        </IconButton>
      </Box>
      <VideoPlayer
        videoPlayerRef={videoElement}
        videoPlayerProps={{
          autoPlay: true,
          style: {
            width: '100%',
            height: '100%',
          },
        }}
        style={{
          width: '100%',
          height: '90vh',
          maxWidth: '100%',
          maxHeight: '90vh',
          overflow: 'hidden',
        }}
        quality={videoQuality}
        onChangeQuality={(level) => {
          setVideoQuality({
            current: level,
            levels: videoQuality?.levels ?? [],
          })
        }}
      />
    </Dialog>
  )
}

export const UploadVideoDialog = (props: {
  open: boolean
  handleClose: () => void
}) => {
  const { open, handleClose } = props

  const [file, setFile] = useState<File>()
  const [dragOver, setDragOver] = useState(false)
  const [progress, setProgress] = useState(0)
  const [eta, setEta] = useState(0)
  const [videoDetails, setVideoDetails] = useState<VideoDetails>({})
  const [errors, setErrors] = useState<{
    [p in KeysOfVideoDetails]?: boolean
  }>({})

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'video/*': [] },
    maxFiles: 1,
    onDrop: (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) setFile(acceptedFiles[0])
      setDragOver(false)
    },
    onDragEnter: () => setDragOver(true),
    onDragLeave: () => setDragOver(false),
  })

  const uploadVideo = async () => {
    const data = videoDetails
    const keys = Object.keys(inputFields) as KeysOfVideoDetails[]

    keys.forEach((key: KeysOfVideoDetails) => {
      if (!data[key]) data[key] = inputFields[key].default
    })

    const apiResponse: UploadResponse & { error: boolean } = await myFetch(
      '/api/video',
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'content-type': 'application/json',
        },
      }
    ).then((body) => (body.status === 200 ? body.json() : { error: true }))

    if (apiResponse.error || !file) {
      alert('Something went wrong!')
      return
    }
    const formData = new FormData()
    Object.entries(apiResponse.upload_url.fields).forEach(([key, value]) =>
      formData.set(key, value)
    )
    formData.set('file', file)

    await new Promise((res, rej) => {
      const startTime = Date.now()

      const xhr = new XMLHttpRequest()
      xhr.open('POST', apiResponse.upload_url.url)
      xhr.setRequestHeader(
        'Access-Control-Allow-Headers',
        'Content-Type, Accept, X-Requested-With'
      )
      xhr.setRequestHeader(
        'Access-Control-Allow-Origin',
        window.location.origin
      )
      xhr.addEventListener('error', rej)
      xhr.addEventListener('readystatechange', function (event: Event) {
        if (this.readyState === 4) res(null)
      })
      xhr.upload.addEventListener(
        'progress',
        (event: ProgressEvent<XMLHttpRequestEventTarget>) => {
          if (event.lengthComputable) {
            const percent = (event.loaded / event.total) * 100
            const diff = Date.now() - startTime

            setProgress(percent)
            setEta((diff / percent) * 100 - diff)
          }
        }
      )
      xhr.upload.addEventListener('load', () => {
        res(null)
      })
      xhr.send(formData)
    })
      .then(async () => {
        await new Promise((res) => setTimeout(res, 2500))

        setProgress(0)
        handleClose()
      })
      .catch(() => alert('Something went wrong!'))
  }

  useEffect(() => {
    if (file && !videoDetails.title?.length)
      setVideoDetails({
        ...videoDetails,
        title: file?.name,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  useEffect(() => {
    setFile(undefined)
    setVideoDetails({})
    setErrors({})
  }, [open])

  const videoWidth = '60%'
  const elementWidth = '80%'

  const inputStyle = (index: number) => ({
    width: elementWidth,
    margin: index === 0 ? '16px auto 8px auto' : '8px auto',
  })

  type KeysOfVideoDetails = keyof VideoDetails

  type InputFieldType = {
    label: string
    type: 'default' | 'choice' | 'customList' | 'radio' | 'check'
    required: boolean
    default?: any
    multiple?: boolean
    choices: (VisibilityChoicesType | EncodingChoicesType)[]
  }

  const inputFields: { [p in KeysOfVideoDetails]: InputFieldType } = {
    title: {
      label: 'Title',
      type: 'default',
      required: true,
      choices: [],
    },
    keywords: {
      label: 'Keywords',
      type: 'customList',
      required: false,
      choices: [],
    },
    sub_text: {
      label: 'Sub Text',
      type: 'default',
      required: false,
      choices: [],
    },
    visibility: {
      label: 'Visibility',
      type: 'radio',
      required: true,
      choices: VisibilityChoices,
    },
    encoding_hints: {
      label: 'Encoding Hints',
      type: 'check',
      required: false,
      multiple: true,
      default: [],
      choices: EncodingChoices,
    },
  }

  const checkInputs = (videoDetails: VideoDetails) => {
    const requiredKeys: KeysOfVideoDetails[] = (
      Object.keys(inputFields) as KeysOfVideoDetails[]
    ).filter((key) => inputFields[key]?.required)

    let valid = true
    requiredKeys.forEach((key) => {
      if (!videoDetails[key]?.length) valid = false
    })

    return valid
  }

  const validateDetails = () => {
    if (checkInputs(videoDetails)) uploadVideo()
  }

  return (
    <>
      {!!progress && open && (
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '100vw',
            height: '100vh',
            background: '#000000AA',
            backdropFilter: 'blur(5px)',
            zIndex: '1500',
          }}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Typography variant='h1' color='white' sx={{ marginBottom: '1em' }}>
              {progress < 100
                ? `Uploading (${convertToDuration(eta)})`
                : 'Uploaded'}
            </Typography>
            <CircularProgressWithLabel
              size={'25em'}
              textStyle={{ fontSize: '5em' }}
              value={progress}
              style={progress < 100 ? {} : { color: 'green' }}
            />
          </Box>
        </Box>
      )}
      <Dialog fullWidth maxWidth='xl' open={open} onClose={handleClose}>
        <Box
          sx={{
            width: '100%',
            height: '90vh',
            maxWidth: '100%',
            maxHeight: '90vh',
            overflowX: 'hidden',

            display: 'flex',
            flexDirection: 'column',
          }}>
          <Typography
            sx={{
              width: elementWidth,
              margin: '16px auto 0px auto',
            }}
            variant='h5'
            component='div'>
            Upload Video
          </Typography>
          {Object.entries(inputFields)
            .map<InputFieldType & { name: KeysOfVideoDetails }>(
              ([key, value]) => ({ name: key as KeysOfVideoDetails, ...value })
            )
            .map((field, index) => {
              switch (field.type) {
                case 'choice':
                  return (
                    <AsyncSearchField
                      key={field.name}
                      sx={inputStyle(index)}
                      label={field.label}
                      error={errors[field.name]}
                      renderTags={
                        field.multiple
                          ? (value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  variant='outlined'
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                          : undefined
                      }
                      getLabel={(item) => item}
                      onType={async (value: string) =>
                        field.choices.filter((choice) =>
                          choice.toUpperCase().startsWith(value.toUpperCase())
                        )
                      }
                      onSelect={(choices: typeof field.choices[number][]) => {
                        setVideoDetails({
                          ...videoDetails,
                          [field.name]: choices,
                        })
                      }}
                    />
                  )
                case 'customList':
                  return (
                    <TagsInput
                      key={field.name}
                      tags={(videoDetails[field.name] as string[]) ?? []}
                      label={field.label}
                      style={inputStyle(index)}
                      mainStyle={inputStyle(index)}
                      variant='outlined'
                      selectedTags={(tags) =>
                        setVideoDetails({
                          ...videoDetails,
                          [field.name]: tags,
                        })
                      }
                    />
                  )
                case 'radio':
                  return (
                    <RadioGroup
                      key={field.name}
                      id={field.name}
                      style={inputStyle(index)}
                      error={errors[field.name]}
                      label={field.label}
                      items={field.choices}
                      selectedItem={videoDetails[field.name]}
                      parseLabel={(i) => capitalize(i.toString())}
                      parseValue={(i) => i.toString()}
                      onSelect={(item) =>
                        setVideoDetails({ ...videoDetails, [field.name]: item })
                      }
                    />
                  )
                case 'check':
                  return (
                    <CheckBoxGroup
                      key={field.name}
                      id={field.name}
                      style={inputStyle(index)}
                      error={errors[field.name]}
                      label={field.label}
                      items={field.choices}
                      selecetItems={videoDetails[field.name] as string[]}
                      parseLabel={(i) => capitalize(i.toString())}
                      parseValue={(i) => i.toString()}
                      onChange={(items) =>
                        setVideoDetails({
                          ...videoDetails,
                          [field.name]: items,
                        })
                      }
                    />
                  )
                default:
                  return (
                    <DefaultTextInput
                      key={field.name}
                      error={errors[field.name]}
                      sx={inputStyle(index)}
                      state={[videoDetails, setVideoDetails]}
                      {...field}
                    />
                  )
              }
            })}
          <Button
            disabled={!checkInputs(videoDetails) || !file}
            sx={inputStyle(1)}
            startIcon={<Upload />}
            variant='outlined'
            onClick={validateDetails}>
            Upload
          </Button>
          <div
            {...getRootProps({ className: 'dropzone' })}
            style={{
              position: 'relative',
              border: '1px dashed white',
              borderRadius: '13px',
              width: elementWidth,
              height: 150,
              minHeight: 150,
              margin: 'auto',
              marginTop: '24px',
              cursor: 'pointer',
              userSelect: 'none',
            }}>
            <input {...getInputProps()} />
            <span
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}>
              {dragOver
                ? 'Drop the file'
                : 'Drag your file here, or click to select'}
            </span>
          </div>
          <video
            style={{
              width: videoWidth,
              aspectRatio: '16 / 9',
              margin: '16px auto',
              background: 'black',
            }}
            controls>
            {file && <source src={URL.createObjectURL(file)} />}
          </video>
        </Box>
      </Dialog>
    </>
  )
}

export const EditVideoDialog = (props: {
  open: boolean
  uuid: string
  handleClose?: (changed: boolean) => void
}) => {
  const { open, uuid, handleClose } = props

  const fetchVideoInfo = async (uuid: String) =>
    myFetch(`/api/video/info?uuid=${uuid}`).then<VideoInfo>((body) =>
      body.json()
    )

  const [error, setError] = useState(false)
  const [videoInfo, setVideoInfo] = useState<VideoInfo>()
  const [videoPatch, setVideoPatch] = useState<VideoPatch>({ vuid: uuid })

  useEffect(() => {
    if (!open) return
    fetchVideoInfo(uuid).then(setVideoInfo)
  }, [uuid, open])

  useEffect(() => {
    setVideoPatch({
      vuid: uuid,
      status: videoInfo?.status,
      title: videoInfo?.texts?.title,
      subtext: videoInfo?.texts?.subtext,
      keywords: videoInfo?.texts?.keywords,
      owner: videoInfo?.owner,
      visibility: videoInfo?.visibility,
    })
  }, [videoInfo, uuid])

  const changes = () => {
    return (
      videoPatch.title !== videoInfo?.texts?.title ||
      videoPatch.subtext !== videoInfo?.texts?.subtext ||
      videoPatch.keywords !== videoInfo?.texts?.keywords ||
      videoPatch.visibility !== videoInfo?.visibility
    )
  }

  const elementWidth = '80%'

  const inputStyle = (index: number) => ({
    width: elementWidth,
    margin: index === 0 ? '16px auto 8px auto' : '8px auto',
  })

  type KeysOfVideoPatch = keyof VideoPatch
  type Type = 'radio' | 'text' | 'tags'

  const items: {
    name: KeysOfVideoPatch
    type: Type
    [key: string]: any
  }[] = [
    {
      type: 'radio',
      name: 'visibility',
      label: 'Visibility',
      choices: VisibilityChoices,
    },
    {
      type: 'text',
      name: 'title',
      label: 'Title',
    },
    {
      type: 'text',
      name: 'subtext',
      label: 'Subtext',
    },
    {
      type: 'tags',
      name: 'keywords',
      label: 'Keywords',
    },
  ]

  const getChanges = () => {
    const json: VideoPatch = { vuid: videoPatch.vuid }

    if (videoPatch.title !== videoInfo?.texts?.title)
      json.title = videoPatch.title
    if (videoPatch.subtext !== videoInfo?.texts?.subtext)
      json.subtext = videoPatch.subtext
    if (videoPatch.keywords !== videoInfo?.texts?.keywords)
      json.keywords = videoPatch.keywords
    if (videoPatch.visibility !== videoInfo?.visibility)
      json.visibility = videoPatch.visibility

    return json
  }

  const submit = async () => {
    myFetch('/api/video', {
      method: 'PATCH',
      body: JSON.stringify(getChanges()),
      headers: {
        'content-type': 'application/json',
      },
    }).then((body) => {
      if (body.status === 200) handleClose?.(true)
      else setError(true)
    })
  }

  const onClose = () => {
    setVideoInfo(undefined)
    handleClose?.(false)
  }

  return (
    <>
      <Dialog fullWidth maxWidth='xl' open={open} onClose={onClose}>
        <Box
          sx={{
            width: '100%',
            minHeight: '50vh',
            maxWidth: '100%',
            maxHeight: '90vh',
            overflowX: 'hidden',

            display: 'flex',
            flexDirection: 'column',
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              margin: 'auto 0px',
            }}>
            {items.map((field, index) => {
              switch (field.type) {
                case 'radio':
                  return (
                    <RadioGroup
                      key={field.name}
                      id={field.name}
                      style={inputStyle(index)}
                      label={field.label}
                      items={field.choices}
                      selectedItem={videoPatch[field.name]}
                      parseLabel={(i) => capitalize(i.toString())}
                      parseValue={(i) => i.toString()}
                      onSelect={(item) =>
                        setVideoPatch({ ...videoPatch, [field.name]: item })
                      }
                    />
                  )
                case 'text':
                  return (
                    <DefaultTextInput
                      key={field.name}
                      sx={inputStyle(index)}
                      state={[videoPatch, setVideoPatch as any]}
                      name={field.name}
                      label={field.label}
                    />
                  )
                case 'tags':
                  return (
                    <TagsInput
                      key={field.name}
                      tags={(videoPatch[field.name] as string[]) ?? []}
                      label={field.label}
                      style={inputStyle(index)}
                      mainStyle={inputStyle(index)}
                      variant='outlined'
                      selectedTags={(tags) =>
                        setVideoPatch({
                          ...videoPatch,
                          [field.name]: tags,
                        })
                      }
                    />
                  )
                default:
                  return <></>
              }
            })}
            <Button
              disabled={!changes()}
              sx={inputStyle(1)}
              startIcon={<Publish />}
              variant='outlined'
              onClick={submit}>
              Submit
            </Button>
          </Box>
        </Box>
      </Dialog>
      <AlertDialog
        open={error}
        title='Error!'
        contentText='Something went wrong!'
        rightButton={{ text: 'Ok' }}
        onClose={() => setError(false)}
      />
    </>
  )
}
