import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { myFetch } from 'utils/utils'

const VideosInvalidNaming = () => {
  const [data, setData] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    setLoading(true)
    await myFetch('/api/pixellot/videos/invalid_naming')
      .then((body) => body.json())
      .then((data: string[]) => {
        setData(data)
      })
      .catch(console.log)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <Box height='100%' width='100%'>
        <DataGrid
          loading={loading}
          sx={{
            filter: loading ? 'contrast(.7) brightness(0.7)' : '',
            position: 'relative',
            transition: '200ms',
          }}
          columns={[{ field: 'col1', headerName: 'Name', width: 250 }]}
          rows={data.map((name, index) => ({
            id: index,
            col1: name,
          }))}
        />
      </Box>
    </>
  )
}

export default VideosInvalidNaming
