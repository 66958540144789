import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  ContentCopy,
  Add,
} from '@mui/icons-material'
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  TextField,
  Typography,
  Checkbox,
  Switch,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  Alert,
  Fab,
  DialogTitle,
  DialogContentText,
} from '@mui/material'
import React, { useRef } from 'react'
import { useState, useEffect, ChangeEvent, SyntheticEvent } from 'react'
import {
  TablePaginationActions,
  Loading,
  FindUser,
  SimpleDialog,
} from 'components'
import { License, LicenseUser } from 'types'
import { copyToClipboard, myFetch, useCustomAlert } from 'utils'
import { CreateLicenseDialog } from './Components'

const Licenses = () => {
  const infoAlert = useRef<HTMLDivElement | null>(null)

  const showAlert = useCustomAlert(infoAlert)

  const [addLicenseOpen, setAddLicenseOpen] = useState(false)
  const [licenseCount, setLicenseCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [rows, setRows] = useState<License[]>([])
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState('')

  const emptyRows = rowsPerPage - rows.length

  const fetchRows = async (
    page: number = 0,
    rowsPerPage: number = 5,
    filter: string = ''
  ) => {
    setLoading(true)
    await myFetch(
      `/api/management/list_license?limit=${rowsPerPage}&offset=${
        page * rowsPerPage
      }${filter.length > 2 ? `&name=${filter}` : ''}`
    )
      .then((body) => body.json())
      .then((data: License[]) => {
        setRows(data)
        setLicenseCount((page + 1) * rowsPerPage + 1)
      })
    setLoading(false)
  }

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    fetchRows(page, rowsPerPage, filter)

    return () => {}
  }, [page, rowsPerPage, filter])

  const Row = (props: { license: License }) => {
    const [open, setOpen] = useState(false)
    const [deleteLicenseOpen, setDeleteLicenseOpen] = useState(false)
    const [addLicenseUserOpen, setAddLicenseUserOpen] = useState(false)
    const [changeLicenseHolderOpen, setChangeLicenseHolderOpen] =
      useState(false)

    const highlight = (text: string, mark: string) => {
      const list = text.split(mark)
      return list.map((t: string, index: number) => (
        <>
          {t}
          {index + 1 < list.length ? <mark>{mark}</mark> : null}
        </>
      ))
    }

    return (
      <>
        <SimpleDialog
          title='Change Licenseholder'
          contentText='Select a new Licenseholder'
          open={changeLicenseHolderOpen}
          onClose={() => setChangeLicenseHolderOpen(false)}>
          <FindUser
            onSelect={async (user: LicenseUser) => {
              setChangeLicenseHolderOpen(false)
              setLoading(true)
              await myFetch(
                `/api/management/change_license_holder?user_id=${user.id}&license_id=${props.license.license_id}`,
                {
                  method: 'POST',
                }
              )
              setLoading(false)
              fetchRows(page, rowsPerPage, filter)
            }}
          />
        </SimpleDialog>
        <SimpleDialog
          title='Add Licenseuser'
          contentText='Select a new Licenseuser'
          open={addLicenseUserOpen}
          onClose={() => setAddLicenseUserOpen(false)}>
          <FindUser
            onSelect={async (user: LicenseUser) => {
              setAddLicenseUserOpen(false)
              setLoading(true)
              await myFetch(
                `/api/management/add_license_user?user_id=${user.id}&license_id=${props.license.license_id}`,
                {
                  method: 'POST',
                }
              )
              setLoading(false)
              fetchRows(page, rowsPerPage, filter)
            }}
          />
        </SimpleDialog>
        <Dialog
          open={deleteLicenseOpen}
          onClose={() => setDeleteLicenseOpen(false)}>
          <DialogTitle>Delete License</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you want to delete the License
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteLicenseOpen(false)}>Cancel</Button>
            <Button
              onClick={async () => {
                await myFetch(
                  `/api/management/remove_license?license_id=${props.license.license_id}`,
                  {
                    method: 'delete',
                  }
                )
                fetchRows(page, rowsPerPage, filter)
                setDeleteLicenseOpen(false)
              }}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <TableRow
          sx={[
            {
              background: 'transparent',
              transition: '200ms',
              '& > .MuiTableCell-body': { borderBottom: 'unset' },
              '& mark': {
                background: '#DFE376',
              },
            },
            open ? { background: '#FFFFFF12' } : {},
          ]}>
          <TableCell>
            <IconButton size='small' onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell component='th' scope='row'>
            {props.license.license_id}
          </TableCell>
          <TableCell align='left'>
            {filter.length > 2
              ? highlight(props.license.name, filter)
              : props.license.name}
          </TableCell>
          <TableCell align='left'>
            {props.license.license_holder.firstName}{' '}
            {props.license.license_holder.lastName} (
            {props.license.license_holder.email})
          </TableCell>
          <TableCell align='right'>
            {new Date(props.license.created_at).toLocaleDateString('en-US', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </TableCell>
          <TableCell align='right'>
            {new Date(props.license.valid_until).toLocaleDateString('en-US', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </TableCell>
          <TableCell align='right'>
            <Checkbox disabled checked={props.license.enabled} />
          </TableCell>
          <TableCell align='right'>
            <IconButton
              onClick={() => {
                copyToClipboard(JSON.stringify(props.license, null, 2))
                showAlert('Copied to clipboard.')
              }}>
              <ContentCopy />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={[
              {
                paddingBottom: 0,
                paddingTop: 0,
                background: 'transparent',
                transition: '200ms',
              },
              open ? { background: '#FFFFFF12' } : {},
            ]}
            colSpan={8}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant='h6' gutterBottom component='div'>
                  Edit
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={3} md={2}>
                    <FormControlLabel
                      control={
                        <Switch defaultChecked={props.license.enabled} />
                      }
                      label='Enabled'
                      onChange={async (event: SyntheticEvent) => {
                        const input = event.target as HTMLInputElement
                        setLoading(true)
                        await myFetch(
                          `/api/management/enable_license?license_id=${props.license.license_id}&enable=${input.checked}`,
                          {
                            method: 'POST',
                          }
                        )
                        setLoading(false)
                        fetchRows(page, rowsPerPage, filter)
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <Button
                      variant='outlined'
                      onClick={async () => {
                        setChangeLicenseHolderOpen(true)
                      }}>
                      Change Licenseholder
                    </Button>
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <Button
                      variant='outlined'
                      onClick={async () => {
                        setAddLicenseUserOpen(true)
                      }}>
                      Add Licenseuser
                    </Button>
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <Button
                      variant='outlined'
                      onClick={async () => {
                        setDeleteLicenseOpen(true)
                      }}>
                      Delete License
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  }

  return (
    <>
      <TextField
        fullWidth
        variant='outlined'
        label='Name'
        autoComplete='new-password'
        sx={{
          marginBottom: '24px',
        }}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = event.target.value
          if (value.length > 2) setFilter(value)
          else setFilter('')
        }}
      />
      <TableContainer
        component={Paper}
        sx={{
          filter: loading ? 'contrast(.7) brightness(0.7)' : '',
          position: 'relative',
          transition: '200ms',
          marginBottom: '75px',
        }}>
        {loading && <Loading />}
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align='left'>Id</TableCell>
              <TableCell align='left'>Name</TableCell>
              <TableCell align='left'>Holder</TableCell>
              <TableCell align='right'>Created At</TableCell>
              <TableCell align='right'>Valid Until</TableCell>
              <TableCell align='right'>Enabled</TableCell>
              <TableCell align='right' />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row license={row} key={row.license_id} />
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 69 * emptyRows }}>
                <TableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                colSpan={8}
                rowsPerPage={rowsPerPage}
                count={licenseCount}
                page={page}
                SelectProps={{ native: true }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Fab
        sx={{
          position: 'absolute',
          bottom: '16px',
          right: '16px',
        }}
        onClick={() => setAddLicenseOpen(true)}>
        <Add />
      </Fab>
      <CreateLicenseDialog
        open={addLicenseOpen}
        onClose={() => setAddLicenseOpen(false)}
        callback={() => fetchRows(page, rowsPerPage, filter)}
        onError={async (body) => {
          const fetchResponse = async (body: Response) => {
            try {
              return body
                .json()
                .then((json) => json.msg ?? JSON.stringify(json))
            } catch (e) {
              return body.text()
            }
          }
          showAlert(await fetchResponse(body))
        }}
      />
      <Alert
        ref={infoAlert}
        style={{ display: 'none' }}
        sx={{
          position: 'absolute',
          bottom: 10,
          right: 10,
        }}
        severity='info'
        elevation={6}
        variant='filled'
      />
    </>
  )
}

export default Licenses
