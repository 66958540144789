import React from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { onReady } from 'utils'

export const RequireAuth = (props: { children: React.ReactElement }) => {
  const { keycloak } = useKeycloak()

  const { children: Element } = props

  onReady(
    keycloak,
    (authenticated) => {
      if (!authenticated) keycloak?.login()
    },
    2000
  )

  return keycloak.authenticated ? Element : <></>
}
