import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useState } from 'react'
import { DefaultTextInput } from 'components'
import { myFetch } from 'utils'

const CreateClubDialog = (props: {
  open: boolean
  onClose: () => void
  callback: () => void
  onError?: (response?: Response) => void
}) => {
  const [createClubInput, setCreateClubInput] = useState<{
    city?: string
    code?: string
    country?: string
    house_number?: string
    name?: string
    street?: string
    zip?: string
  }>({})

  const { open, onClose, callback, onError } = props

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Create Club</DialogTitle>
        <DialogContent>
          <DefaultTextInput
            state={[createClubInput, setCreateClubInput]}
            label='City'
            name='city'
          />
          <DefaultTextInput
            state={[createClubInput, setCreateClubInput]}
            label='Code'
            name='code'
          />
          <DefaultTextInput
            state={[createClubInput, setCreateClubInput]}
            label='Country'
            name='country'
          />
          <DefaultTextInput
            state={[createClubInput, setCreateClubInput]}
            label='House number'
            name='house_number'
          />
          <DefaultTextInput
            state={[createClubInput, setCreateClubInput]}
            label='Name'
            name='name'
          />
          <DefaultTextInput
            state={[createClubInput, setCreateClubInput]}
            label='Street'
            name='street'
          />
          <DefaultTextInput
            state={[createClubInput, setCreateClubInput]}
            label='Zip'
            name='zip'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={async () => {
              await myFetch(`/api/clubs/create_club`, {
                method: 'post',
                headers: {
                  'content-type': 'application/json',
                },
                body: JSON.stringify(createClubInput),
              })
                .then((body) => {
                  if (body.status !== 200) onError?.(body)
                  else {
                    onClose()
                    callback()
                  }
                })
                .catch(() => onError?.())
            }}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateClubDialog
