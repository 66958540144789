import { Download } from '@mui/icons-material'
import { Box, Fab } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { csvToXlsx, downloadAsFile, myFetch } from 'utils/utils'

const MissingVideos = () => {
  const [data, setData] = useState<{ name: string; created_at: string }[]>([])
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    setLoading(true)
    await myFetch('/api/pixellot/videos/missing')
      .then((body) => body.json())
      .then((data: { name: string; created_at: string }[]) => {
        setData(data)
      })
      .catch(console.log)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <Box height='100%' width='100%'>
        <DataGrid
          loading={loading}
          sx={{
            filter: loading ? 'contrast(.7) brightness(0.7)' : '',
            position: 'relative',
            transition: '200ms',
            height: 'calc(100vh - 180px)',
          }}
          columns={[
            { field: 'col1', headerName: 'Name', width: 250 },
            { field: 'col2', headerName: 'Created At', width: 350 },
          ]}
          rows={data.map(({ name, created_at }, index) => ({
            id: index,
            col1: name,
            col2: new Date(created_at).toLocaleDateString('en-US', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false,
            }),
          }))}
        />
      </Box>
      <Fab
        sx={{
          position: 'fixed',
          bottom: '12px',
          right: '12px',
        }}
        onClick={() => {
          let csv = 'Name,Created At'
          data.forEach(
            ({ name, created_at }) => (csv += `\n${name},${created_at}`)
          )

          downloadAsFile(csvToXlsx(csv), 'missingVideos.xlsx')
        }}>
        <Download />
      </Fab>
    </>
  )
}

export default MissingVideos
