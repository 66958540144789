import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import {
  Box,
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { User } from 'types'
import { myFetch } from 'utils'
import { TablePaginationActions, Loading } from 'components'

const Users = () => {
  const [userCount, setUserCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [rows, setRows] = useState<User[]>([])
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)

  const emptyRows = rowsPerPage - rows.length

  const fetchRows = async (page: number = 0, rowsPerPage: number = 5) => {
    setLoading(true)
    await myFetch(
      `/api/list_user?limit=${rowsPerPage}&offset=${page * rowsPerPage}`
    )
      .then((body) => body.json())
      .then((data: User[]) => {
        setRows(data)
        setUserCount((page + 1) * rowsPerPage + 1)
      })
    setLoading(false)
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    fetchRows(page, rowsPerPage)

    return () => {}
  }, [page, rowsPerPage])

  const Row = (props: { user: User }) => {
    const [open, setOpen] = useState(false)

    const details = Object.entries(props.user)
      .map((entry) => {
        return {
          primary: entry[0],
          secondary:
            typeof entry[1] === 'object'
              ? JSON.stringify(entry[1])
              : entry[1].toString(),
        }
      })
      .filter(({ secondary }) => secondary.length > 0)

    return (
      <>
        <TableRow sx={{ '& > .MuiTableCell-body': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton size='small' onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell component='th' scope='row'>
            {props.user.username}
          </TableCell>
          <TableCell align='left'>{props.user.firstName}</TableCell>
          <TableCell align='left'>{props.user.lastName}</TableCell>
          <TableCell align='right'>{props.user.email}</TableCell>
          <TableCell align='right'>
            <Checkbox checked={props.user.emailVerified} disabled />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant='h6' gutterBottom component='div'>
                  Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <List>
                      {details.map((entry, index) =>
                        index < details.length / 2 ? (
                          <ListItem key={entry.primary}>
                            <ListItemText {...entry} />
                          </ListItem>
                        ) : (
                          <></>
                        )
                      )}
                    </List>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <List>
                      {details.map((entry, index) =>
                        index >= details.length / 2 ? (
                          <ListItem key={entry.primary}>
                            <ListItemText {...entry} />
                          </ListItem>
                        ) : (
                          <></>
                        )
                      )}
                    </List>
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  }

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          filter: loading ? 'contrast(.7) brightness(0.7)' : '',
          position: 'relative',
        }}>
        {loading && <Loading />}
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align='left'>Username</TableCell>
              <TableCell align='left'>First Name</TableCell>
              <TableCell align='left'>Last Name</TableCell>
              <TableCell align='right'>Email</TableCell>
              <TableCell align='right'>Email Verified</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row user={row} key={row.id} />
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                colSpan={6}
                rowsPerPage={rowsPerPage}
                count={userCount}
                page={page}
                SelectProps={{ native: true }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  )
}

export default Users
