import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { myFetch } from "utils/utils";

const getColor = (value: number) => {
  return value > 0 ? "red" : "#00FF00";
};

const ExpectedVideos = () => {
  const [data, setData] = useState<
    {
      session_number: number;
      expected_videos: number;
      uploaded_videos: number;
      created_at: string;
      timestamp: string;
      updated_at: string;
    }[]
  >([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    await myFetch("/api/pixellot/videos/expected_videos")
      .then((body) => body.json())
      .then(
        (
          data: {
            session_number: number;
            expected_videos: number;
            uploaded_videos: number;
            created_at: string;
            timestamp: string;
            updated_at: string;
          }[]
        ) => {
          setData(data);
        }
      )
      .catch(console.log);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Box height="100%" width="100%">
        <DataGrid
          loading={loading}
          sx={{
            filter: loading ? "contrast(.7) brightness(0.7)" : "",
            position: "relative",
            transition: "200ms",
            height: "calc(100vh - 180px)",
          }}
          columns={[
            { field: "col1", headerName: "Session Number", width: 150 },
            {
              field: "col2",
              headerName: "Expected Videos",
              width: 150,
            },
            {
              field: "col3",
              headerName: "Uploaded Videos",
              resizable: true,
              width: 150,
            },
            {
              field: "missing",
              headerName: "Missing Count",
              width: 150,
              renderCell: (params: any) => {
                const color = getColor(params.value);
                return (
                  <Box
                    sx={{
                      backgroundColor: { color },
                    }}
                  >
                    {params.value}
                  </Box>
                );
              },
            },
            {
              field: "col4",
              headerName: "Created At",
              width: 270,
            },
            {
              field: "col5",
              headerName: "Timestamp",
              width: 270,
            },
            {
              field: "col6",
              headerName: "Updated At",
              width: 270,
            },
          ]}
          rows={data.map(
            (
              {
                session_number,
                expected_videos,
                uploaded_videos,
                created_at,
                timestamp,
                updated_at,
              },
              index
            ) => ({
              id: index,
              col1: session_number,
              col2: expected_videos,
              col3: uploaded_videos,
              missing: expected_videos - uploaded_videos,
              col4: new Date(created_at).toLocaleDateString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
              }),
              col5: timestamp
                ? new Date(timestamp).toLocaleDateString("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: false,
                  })
                : "",
              col6: new Date(updated_at).toLocaleDateString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
              }),
            })
          )}
        />
      </Box>
    </>
  );
};

export default ExpectedVideos;
