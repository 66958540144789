import { LoadingButton } from '@mui/lab'
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material'
import { Check, Close } from '@mui/icons-material'
import { Box } from '@mui/system'
import { ChangeEvent, useState } from 'react'
import ReactJson from 'react-json-view'
import { myFetch } from 'utils/utils'
import { WhiteText } from 'components/Components'

const CodeCheck = () => {
  const [history, setHistory] = useState<
    { code: string; status: number | string }[]
  >([])
  const [response, setResponse] = useState({})
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState('')

  const checkCode = async (code: string) => {
    setLoading(true)
    setResponse({})
    const data = await myFetch(`/api/code?code=${code}`)
      .then((body) =>
        body.status === 400
          ? { error: 'Invalid code' }
          : body.status === 200
          ? body.json()
          : { status: body.status }
      )
      .catch((e) => ({ error: e.toString() }))
    setHistory((history) =>
      history.length > 4
        ? [{ code, status: data.status }, ...history.slice(0, -1)]
        : [{ code, status: data.status }, ...history]
    )
    setResponse(data)
    setLoading(false)
  }

  return (
    <Box>
      <TextField
        disabled={loading}
        fullWidth
        sx={{ margin: '5px' }}
        label='Code'
        value={code}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setCode(event.target.value.toUpperCase())
        }
      />
      <LoadingButton
        disabled={!code.length}
        loading={loading}
        sx={{ margin: '5px' }}
        variant='outlined'
        onClick={() => checkCode(code)}>
        Check Code
      </LoadingButton>
      <WhiteText sx={{ marginTop: '25px', marginLeft: '5px' }}>
        History
      </WhiteText>
      <List>
        {history.map((code) => (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                setCode(code.code)
                checkCode(code.code)
              }}
              disabled={loading}>
              <ListItemIcon>
                {code.status === 'open' ? (
                  <Check color='success' />
                ) : (
                  <Close color='error' />
                )}
              </ListItemIcon>
              <ListItemText primary={code.code} style={{ color: 'white' }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <WhiteText sx={{ marginTop: '25px', marginLeft: '5px' }}>
        Response
      </WhiteText>
      <ReactJson
        src={response}
        theme='ocean'
        name={false}
        style={{ margin: '15px 5px 5px 5px', background: 'transparent' }}
      />
    </Box>
  )
}

export default CodeCheck
