import {
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TablePagination,
  IconButton,
  Box,
  Collapse,
  Grid,
} from '@mui/material'
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions'
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'
import { DataGrid } from '@mui/x-data-grid'
import Loading from 'components/Loading'
import { useState, useEffect } from 'react'
import { myFetch } from 'utils/utils'
import {
  QueuePlayerZoom as QueuePlayerZoomType,
  QueuePlayerZoomStatus,
} from 'types/types'
import { WhiteText } from 'components/Components'

const colors: { [key: QueuePlayerZoomStatus]: string } = {
  QUEUED: '#D3B638',
  STARTING: '#ACC732',
  DOWNLOADING: '#ACC732',
  ENCODING: '#ACC732',
  UPLOADING: '#ACC732',
  FAILED: '#CE2828',
  DONE: '#28D428',
}

const QueuePlayerZoom = () => {
  const [rows, setRows] = useState<QueuePlayerZoomType[]>([])
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [queueCount, setQueueCount] = useState(0)
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)

  const emptyRows = rowsPerPage - rows.length

  const fetchDataVision = async (
    rowsPerPage: number = 20,
    page: number = 0
  ) => {
    setLoading(true)
    await myFetch(
      `/api/queues/playerzoom?limit=${rowsPerPage}&skip=${page * rowsPerPage}`
    )
      .then((body) => body.json())
      .then((data: QueuePlayerZoomType[]) => {
        setRows(
          data.map((d) => ({
            ...d,
            vuids: Object.values(d.vuids),
          }))
        )
        setQueueCount((page + 1) * rowsPerPage + 1)
      })
    setLoading(false)
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    fetchDataVision(rowsPerPage, page)

    return () => {}
  }, [rowsPerPage, page])

  const Row = (props: { queuePlayerZoom: QueuePlayerZoomType }) => {
    const [open, setOpen] = useState(false)

    const { queuePlayerZoom } = props

    return (
      <>
        <TableRow>
          <TableCell>
            <IconButton size='small' onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell align='left'>{queuePlayerZoom.match_number}</TableCell>
          <TableCell align='left'>{queuePlayerZoom.session_number}</TableCell>
          <TableCell align='left'>{queuePlayerZoom.instance_id}</TableCell>
          <TableCell
            align='left'
            sx={{ color: colors[queuePlayerZoom.status] }}>
            {queuePlayerZoom.status}
          </TableCell>
          <TableCell align='right'>
            {new Date(queuePlayerZoom.created_at).toLocaleDateString('en-US', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false,
            })}
          </TableCell>
          <TableCell align='right'>
            {new Date(queuePlayerZoom.updated_at).toLocaleDateString('en-US', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false,
            })}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{
              paddingBottom: 0,
              paddingTop: 0,
              background: 'transparent',
              transition: '200ms',
            }}
            colSpan={7}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={3} md={2}>
                    <WhiteText sx={{ margin: '5px' }}>Players</WhiteText>
                    <Box height={350} width='100%'>
                      <DataGrid
                        columns={[
                          { field: 'col1', headerName: 'Player', width: 100 },
                        ]}
                        rows={queuePlayerZoom.player_list.map(
                          (player, index) => ({
                            id: index,
                            col1: player,
                          })
                        )}
                        hideFooter
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3} md={4}>
                    <WhiteText sx={{ margin: '5px' }}>vuids</WhiteText>
                    <Box height={350} width='100%'>
                      <DataGrid
                        columns={[
                          { field: 'col1', headerName: 'vuid', width: 350 },
                        ]}
                        rows={queuePlayerZoom.vuids.map((vuid, index) => ({
                          id: index,
                          col1: vuid,
                        }))}
                        hideFooter
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3} md={5}>
                    <WhiteText sx={{ margin: '5px' }}>Updates</WhiteText>
                    <Box
                      height={350}
                      width='100%'
                      sx={{
                        '& .row-QUEUED': { color: colors['QUEUED'] },
                        '& .row-STARTING': { color: colors['STARTING'] },
                        '& .row-DOWNLOADING': { color: colors['DOWNLOADING'] },
                        '& .row-ENCODING': { color: colors['ENCODING'] },
                        '& .row-UPLOADING': { color: colors['UPLOADING'] },
                        '& .row-FAILED': { color: colors['FAILED'] },
                        '& .row-DONE': { color: colors['DONE'] },
                      }}>
                      <DataGrid
                        columns={[
                          {
                            field: 'col1',
                            headerName: 'Status',
                            width: 150,
                            cellClassName: (params) => `row-${params.value}`,
                          },
                          { field: 'col2', headerName: 'Time', width: 350 },
                        ]}
                        rows={queuePlayerZoom.updates.map(
                          ({ status, time_is }, index) => ({
                            id: index,
                            col1: status,
                            col2: new Date(time_is).toLocaleDateString(
                              'en-US',
                              {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                                hour12: false,
                              }
                            ),
                          })
                        )}
                        hideFooter
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  }

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          filter: loading ? 'contrast(.7) brightness(0.7)' : '',
          position: 'relative',
          transition: '200ms',
        }}>
        {loading && <Loading />}
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align='left'>Match Number</TableCell>
              <TableCell align='left'>Session Number</TableCell>
              <TableCell align='left'>instance ID</TableCell>
              <TableCell align='left'>Status</TableCell>
              <TableCell align='right'>Created At</TableCell>
              <TableCell align='right'>Updated At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <Row queuePlayerZoom={row} key={index} />
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 69 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                colSpan={7}
                rowsPerPage={rowsPerPage}
                count={queueCount}
                page={page}
                SelectProps={{ native: true }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  )
}

export default QueuePlayerZoom
