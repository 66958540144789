import {
  AccountCircle,
  ChevronLeft,
  ExpandLess,
  ExpandMore,
  Logout,
  Mail,
  Settings,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Alert,
  Card,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Snackbar,
  ThemeProvider,
  Toolbar,
  Typography,
  capitalize,
  createTheme,
} from "@mui/material";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { CircularGradient } from "components/Components";
import { RequireAuth } from "keycloak/RequireAuth";
import keycloak from "keycloak/keycloak";
import CodeCheck from "pages/CodeCheck/CodeCheck";
import CreateFromExcel from "pages/CreateFromExcel/CreateFromExcel";
import CreateOperatorSession from "pages/CreateOperatorSession/CreateOperatorSession";
import DownloadVideos from "pages/DownloadVideos/DownloadVideos";
import GenerateCode from "pages/GenerateCodes/GenerateCode";
import MissingVideos from "pages/Pixellot/MissingVideos";
import VideosInvalidNaming from "pages/Pixellot/VideosInvalidNaming";
import ExpectedVideos from "pages/Pixellot/ExpectedVideos";
import Pricing from "pages/Pricing/Pricing";
import Queues from "pages/Queues/Queues";
import Runner from "pages/Runners/Runners";
import SessionsOfClub from "pages/SessionsOfClub/SessionsOfClub";
import {
  MouseEvent as TypedMouseEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import {
  AccessTokenContent,
  IDLeagueInternAccountAdmin,
  IDLeagueInternClubsViewer,
  IDLeagueInternSuperuser,
} from "types";
import { UserContext, getTokenData, permissionCheck } from "utils";
import {
  AppBar,
  DrawerHeader,
  Main,
  MenuButton,
  Title,
  drawerWidth,
} from "./AppComponents";
import Admin from "./pages/Admin/Admin";
import Clubs from "./pages/Clubs/Clubs";
import Dashboard from "./pages/Dashboard/Dashboard";
import Licenses from "./pages/Licenses/Licenses";
import Mails from "./pages/Mails/Mails";
import Sessions from "./pages/Sessions/Sessions";
import Users from "./pages/Users/Users";
import Videos from "./pages/Videos/Videos";

// deffinition for the side bar `/` is the main page, `|` are the sub pages (names have to match the page name)
const pages = [
  "dashboard",
  "videos",
  "admin",
  "sessions/sessions|sessionsOfClub|createOperatorSession",
  "tools/createFromExcel|code-check|generateCode|pricing|downloadVideos",
  "licenses",
  "mails",
  "users",
  "clubs",
  "runner",
  "queues",
  "pixellot/missing-videos|videos-invalid-naming|expected-videos",
];
type Page = (typeof pages)[number];

const SideBarItem = (props: { text: string; index: number }) => {
  const { text, index } = props;

  const navigate = useNavigate();

  const getTitle = (page: Page) => {
    return capitalize(page);
  };

  const [open, setOpen] = useState(false);

  const mainText = text.split("/")[0];
  const secondaryTexts = text.split("/")[1]?.split("|");

  return (
    <>
      <ListItemButton
        key={index}
        onClick={() => {
          if (!secondaryTexts) navigate(text);
          else setOpen((open) => !open);
        }}
      >
        <ListItemIcon>
          <Mail />
        </ListItemIcon>
        <ListItemText primary={getTitle(mainText)} />
        {secondaryTexts ? open ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItemButton>
      {secondaryTexts ? (
        <Collapse in={open} unmountOnExit>
          <List component="div" disablePadding>
            {secondaryTexts.map((t) => (
              <ListItemButton
                sx={{ pl: 4 }}
                key={t}
                onClick={() => {
                  navigate(`${mainText}/${t}`);
                }}
              >
                <ListItemText primary={getTitle(t)} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      ) : null}
    </>
  );
};

const App = () => {
  // permissions for each page (if not defined, everyone can access)
  const pagePermission: {
    [key in Page]?: (userInfo?: AccessTokenContent) => boolean;
  } = {
    admin: (userInfo?: AccessTokenContent) =>
      (userInfo?.groups?.find((group) =>
        permissionCheck(group, IDLeagueInternSuperuser)
      )?.length ?? 0) > 0,
    clubs: (userInfo?: AccessTokenContent) =>
      (userInfo?.groups?.find((group) =>
        permissionCheck(group, IDLeagueInternClubsViewer)
      )?.length ?? 0) > 0,
    mails: (userInfo?: AccessTokenContent) =>
      (userInfo?.groups?.find((group) =>
        permissionCheck(group, IDLeagueInternSuperuser)
      )?.length ?? 0) > 0,
    users: (userInfo?: AccessTokenContent) =>
      (userInfo?.groups?.find((group) =>
        permissionCheck(group, IDLeagueInternAccountAdmin)
      )?.length ?? 0) > 0,
    runner: (userInfo?: AccessTokenContent) =>
      (userInfo?.groups?.find((group) =>
        permissionCheck(group, IDLeagueInternSuperuser)
      )?.length ?? 0) > 0,
  };

  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [tokenData, setTokenData] = useState<AccessTokenContent>(
    getTokenData()
  );

  const userMenu = useRef<HTMLDivElement>(null);

  const getTitle = (page: Page) => {
    return capitalize(page);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenMenu = async (event: TypedMouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpenMenu(true);
  };

  useEffect(() => {
    const current = userMenu.current;

    const handleClickAway = () => {
      setOpenMenu(false);
    };
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (
        current === target ||
        (target?.nodeName !== "BUTTON" && target?.nodeName !== "A")
      )
        event.stopPropagation();
    };

    if (current) {
      current.addEventListener("click", handleClick);
      window.addEventListener("click", handleClickAway);
    }

    return () => {
      current?.removeEventListener("click", handleClick);
      window.removeEventListener("click", handleClickAway);
    };
  });

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ onLoad: undefined }}
      onTokens={(tokens) => setTokenData(getTokenData(tokens.token))}
    >
      <ThemeProvider theme={createTheme({ palette: { mode: "dark" } })}>
        <RequireAuth>
          <UserContext.Provider value={{ tokenData, setTokenData }}>
            <CircularGradient />
            <AppBar position="static" open={open}>
              <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2, ...(open && { display: "none" }) }}
                  onClick={handleDrawerOpen}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  <Routes>
                    {pages.map((page) => {
                      const mainText = page.split("/")[0];
                      const secondaryTexts = page.split("/")[1]?.split("|");

                      return (
                        <>
                          <Route
                            key={page}
                            path={page === "dashboard" ? "/" : page}
                            element={<Title title={getTitle(page)} />}
                          />
                          <Route
                            key={page + "/:option"}
                            path={page + "/:option"}
                            element={<Title title={getTitle(page)} />}
                          />
                          {secondaryTexts
                            ? secondaryTexts.map((t) => (
                                <Route
                                  key={`${mainText}/${t}`}
                                  path={`${mainText}/${t}`}
                                  element={
                                    <Title
                                      title={`${getTitle(
                                        mainText
                                      )} / ${getTitle(t)}`}
                                    />
                                  }
                                />
                              ))
                            : null}
                        </>
                      );
                    })}
                  </Routes>
                </Typography>
                <IconButton
                  size="large"
                  color="inherit"
                  onClick={handleOpenMenu}
                >
                  <AccountCircle />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Drawer
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                  boxSizing: "border-box",
                },
              }}
              variant="persistent"
              anchor="left"
              open={open}
            >
              <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                  <ChevronLeft />
                </IconButton>
              </DrawerHeader>
              <Divider />
              <List>
                <UserContext.Consumer>
                  {(contextData) => {
                    const userInfo = contextData?.tokenData;
                    return (
                      userInfo &&
                      pages
                        .filter(
                          (page) =>
                            !pagePermission[page] ||
                            pagePermission[page]?.(userInfo)
                        )
                        .map((text, index) => (
                          <SideBarItem text={text} index={index} />
                        ))
                    );
                  }}
                </UserContext.Consumer>
              </List>
            </Drawer>
            <Main open={open}>
              <Routes>
                {/**
                 *  Routes
                 *
                 * add new routes based on the following structure
                 */}
                <Route path="/" element={<Dashboard />} />
                <Route
                  path="/dashboard"
                  element={<Navigate to="/" replace />}
                />
                <Route path="/videos">
                  <Route path="" element={<Videos />} />
                  <Route path=":videoId" element={<Videos />} />
                </Route>
                <Route path="/sessions">
                  <Route path="sessions" element={<Sessions />} />
                  <Route path="sessionsOfClub" element={<SessionsOfClub />} />
                  <Route
                    path="createOperatorSession"
                    element={<CreateOperatorSession />}
                  />
                </Route>
                <Route path="/tools">
                  <Route path="createFromExcel" element={<CreateFromExcel />} />
                  <Route path="generateCode" element={<GenerateCode />} />
                  <Route path="pricing" element={<Pricing />} />
                  <Route path="code-check" element={<CodeCheck />} />
                  <Route path="downloadVideos" element={<DownloadVideos />} />
                </Route>
                <Route path="/admin">
                  <Route path="" element={<Admin />} />
                  <Route path=":tab" element={<Admin />} />
                </Route>
                <Route path="/users" element={<Users />} />
                <Route path="/licenses" element={<Licenses />} />
                <Route path="/mails" element={<Mails />} />
                <Route path="/clubs" element={<Clubs />} />
                <Route path="/runner" element={<Runner />} />
                <Route path="/queues">
                  <Route path="" element={<Queues />} />
                  <Route path=":tab" element={<Queues />} />
                </Route>
                <Route path="/pixellot">
                  <Route path="missing-videos" element={<MissingVideos />} />
                  <Route
                    path="videos-invalid-naming"
                    element={<VideosInvalidNaming />}
                  />
                  <Route path="expected-videos" element={<ExpectedVideos />} />
                </Route>
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Main>
            <UserContext.Consumer>
              {() => {
                const userInfo = tokenData;
                return userInfo ? (
                  <Card
                    style={{
                      position: "fixed",
                      top: 20,
                      right: 20,
                      padding: 12,
                      minWidth: 150,
                      userSelect: "none",
                      display: openMenu ? "" : "none",
                    }}
                    raised
                    ref={userMenu}
                  >
                    <Typography variant="body1">{userInfo.name}</Typography>
                    <Link
                      href={`mailto:${userInfo.preferred_username}`}
                      target="_blank"
                      color="inherit"
                      underline="none"
                      sx={{
                        fontSize: 10,
                        marginLeft: 0.5,
                        display: "block",
                        marginBottom: 1,
                      }}
                    >
                      {userInfo.preferred_username}
                    </Link>
                    <Divider />
                    <MenuButton href="/settings" icon={<Settings />}>
                      Settings
                    </MenuButton>
                    <Divider />
                    <MenuButton
                      onClick={() => keycloak.logout()}
                      icon={<Logout />}
                    >
                      Logout
                    </MenuButton>
                  </Card>
                ) : (
                  <Snackbar
                    open={!userInfo}
                    autoHideDuration={6000}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    <Alert severity="error" elevation={6} variant="filled">
                      Could not fetch all data, please reload the page.
                    </Alert>
                  </Snackbar>
                );
              }}
            </UserContext.Consumer>
          </UserContext.Provider>
        </RequireAuth>
      </ThemeProvider>
    </ReactKeycloakProvider>
  );
};

export default App;
