import { Delete, Add, Close } from '@mui/icons-material'
import {
  capitalize,
  Card,
  Tooltip,
  IconButton,
  Fab,
  Dialog,
  Box,
  Grid,
  Typography,
  Alert,
} from '@mui/material'
import { useState, useRef, useEffect } from 'react'
import { Group, PortalUser } from 'types'
import { myFetch, getColor, lerpColor, groupColor } from 'utils'
import { WhiteText, Loading, Error } from 'components'
import FetchSimpleuserList from './FetchSimpleuserList'
import Users from './Users'

const Groups = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [data, setData] = useState<Group[]>([])
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null)

  const alert = useRef<HTMLDivElement | null>(null)
  const infoAlert = useRef<HTMLDivElement | null>(null)

  const loadGroups = async () => {
    setError(false)
    setLoading(true)
    await myFetch('/api/admin/group_view')
      .then((body) => body.json())
      .then((data: Group[]) => {
        const flatData: Group[] = []
        const a = (group: Group) => {
          if (group.subGroups.length === 0) flatData.push(group)
          else group.subGroups.forEach((g) => a(g))
        }
        data.forEach((d) => a(d))
        setData(flatData)
      })
      .catch(() => setError(true))
    setLoading(false)
  }

  useEffect(() => {
    loadGroups()
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formatTitle = (path: string) => {
    return path
      .split('/')
      .filter((_, index) => index > 1)
      .map(capitalize)
      .join(' ')
  }

  const GroupCard = (props: { group: Group }) => {
    return (
      <Card
        sx={{
          padding: '16px',
          borderRadius: 3,
          cursor: 'pointer',
          userSelect: 'none',
          height: 'calc(100% - 32px)',
          background: groupColor(props.group.path, props.group.name),
          transition: '200ms',
          ':hover': {
            transform: 'scale(1.05)',
          },
        }}
        onClick={() => {
          setSelectedGroup(props.group)
        }}>
        <WhiteText variant='h5'>{formatTitle(props.group.path)}</WhiteText>
        <WhiteText variant='body1'>Users: {props.group.user_count}</WhiteText>
      </Card>
    )
  }

  const UserList = (props: { group: Group; maxHeight: number | string }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [data, setData] = useState<PortalUser[]>([])

    const [addUserDialog, setAddUserDialog] = useState(false)

    const loadData = async () => {
      setError(false)
      setLoading(true)
      await myFetch(`/api/admin/users_in_group?group_id=${props.group.id}`)
        .then((body) => body.json())
        .then((data) => setData(data))
        .catch(() => setError(true))
      setLoading(false)
    }

    useEffect(() => {
      loadData()
      return () => {}
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClose = () => {
      setAddUserDialog(false)
    }

    const background = lerpColor(getColor(props.group.path), '#FFFFFF', 0.3)

    return (
      <>
        {loading && <Loading />}
        {error && <Error message='Error loading data!' />}
        {data.length && !error && !loading ? (
          <>
            <Users
              maxHeight={props.maxHeight}
              background={getColor(props.group.path, 0.3)}
              loadData={({ setData }) => setData(data)}
              buttons={(user) => {
                return [
                  <Tooltip
                    key={'removeUserFromGroup'}
                    title='Remove from group'>
                    <IconButton
                      onClick={async () => {
                        await myFetch(
                          `/api/admin/remove_user_from_group?group_id=${props.group.id}&user_id=${user.id}`,
                          {
                            method: 'PUT',
                          }
                        ).then((body) => {
                          const alertRef = alert.current
                          if (body.status !== 200) {
                            if (alertRef) {
                              const message =
                                alertRef.querySelector<HTMLDivElement>(
                                  '.MuiAlert-message'
                                )
                              if (message) message.innerText = body.statusText
                              alertRef.style.display = ''
                            }
                          } else if (alertRef) alertRef.style.display = 'none'
                        })
                        await loadData()
                      }}>
                      <Delete />
                    </IconButton>
                  </Tooltip>,
                ]
              }}
            />
          </>
        ) : null}
        {!error && !loading && (
          <Fab
            sx={{
              position: 'absolute',
              bottom: 16,
              right: 16,
            }}
            onClick={async () => {
              setAddUserDialog(true)
            }}>
            <Add />
          </Fab>
        )}
        <Dialog
          maxWidth='xl'
          open={addUserDialog}
          onClose={handleClose}
          sx={{
            '& .MuiDialog-paper': {
              borderRadius: '8px',
              background: groupColor(
                selectedGroup?.path ?? '',
                selectedGroup?.name ?? ''
              ),
              '::-webkit-scrollbar': {
                width: '5px',
                height: '5px',
              },
              '::-webkit-scrollbar-track': {
                background: 'transparent',
              },
              '::-webkit-scrollbar-thumb': {
                background: background,
                borderRadius: '8px',
              },
              '::-webkit-scrollbar-thumb:hover': {
                background: background,
              },
            },
          }}>
          <FetchSimpleuserList
            usersInGroup={data}
            group={props.group}
            handleClose={handleClose}
            loadData={loadData}
            alert={alert}
          />
        </Dialog>
      </>
    )
  }

  const handleClose = () => {
    setSelectedGroup(null)
    loadGroups()
  }

  const internalGroups: Group[] = []
  const externalGroups: Group[] = []

  data?.forEach((group) => {
    if (group.path.startsWith('/id_league_intern')) internalGroups.push(group)
    else externalGroups.push(group)
  })

  return (
    <>
      {loading && <Loading />}
      {error && <Error message='Error loading data!' />}
      {data && !error && (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Card
              sx={{
                padding: '25px',
                borderRadius: 3,
                margin: 2,
                border: '2px solid',
                borderColor: getColor('/id_league_intern'),
              }}>
              <WhiteText
                variant='h4'
                sx={{ marginTop: 0, marginBottom: '15px' }}>
                Internal
              </WhiteText>
              <Grid
                container
                spacing={{ xs: 4, sx: 4, sm: 4, md: 5 }}
                columns={{ xs: 4, sm: 8, md: 12 }}>
                {internalGroups.map((group) => (
                  <Grid item xs={3} key={group.id}>
                    <GroupCard group={group} />
                  </Grid>
                ))}
              </Grid>
            </Card>
            <Card
              sx={{
                padding: '25px',
                borderRadius: 3,
                margin: 2,
                border: '2px solid',
                borderColor: getColor('/kickid_extern'),
              }}>
              <WhiteText
                variant='h4'
                sx={{ marginTop: 0, marginBottom: '15px' }}>
                External
              </WhiteText>
              <Grid
                container
                spacing={{ xs: 4, sx: 4, sm: 4, md: 5 }}
                columns={{ xs: 4, sm: 8, md: 12 }}>
                {externalGroups.map((group) => (
                  <Grid item xs={3} key={group.id}>
                    <GroupCard group={group} />
                  </Grid>
                ))}
              </Grid>
            </Card>
          </Box>
          <Dialog
            fullWidth
            maxWidth='xl'
            open={!!selectedGroup}
            onClose={handleClose}
            sx={{
              '& .MuiDialog-paper': {
                borderRadius: '8px',
                background: groupColor(
                  selectedGroup?.path ?? '',
                  selectedGroup?.name ?? ''
                ),
              },
            }}>
            <Box
              sx={{
                position: 'relative',
              }}>
              <Typography
                sx={{
                  marginTop: '10px',
                  marginLeft: '15px',
                }}
                variant='h4'
                component='div'>
                {formatTitle(selectedGroup?.path ?? '')}
              </Typography>
              <IconButton
                edge='start'
                color='inherit'
                onClick={handleClose}
                arial-label='close'
                sx={{
                  position: 'absolute',
                  top: 5,
                  right: 5,
                }}>
                <Close />
              </IconButton>
            </Box>
            <Box
              sx={{
                width: 'calc(100% - 40px)',
                height: 'calc(80vh - 40px)',
                padding: '20px',
              }}>
              {selectedGroup && (
                <UserList
                  group={selectedGroup}
                  maxHeight={'calc(80vh - 40px)'}
                />
              )}
            </Box>
          </Dialog>
          <Alert
            ref={alert}
            style={{ display: 'none' }}
            sx={{
              position: 'absolute',
              bottom: 10,
              left: 10,
            }}
            severity='error'
            elevation={6}
            variant='filled'
          />
          <Alert
            ref={infoAlert}
            style={{ display: 'none' }}
            sx={{
              position: 'absolute',
              bottom: 10,
              left: 10,
            }}
            severity='info'
            elevation={6}
            variant='filled'
          />
        </>
      )}
    </>
  )
}

export default Groups
