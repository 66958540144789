import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import { Loading } from 'components/Components'
import { ChangeEvent, useEffect, useState } from 'react'
import { RunnerResponse, RunnerStatesType, RunnerType } from 'types/types'
import { myFetch } from 'utils/utils'

const colors: { [key: RunnerStatesType]: string } = {
  pending: '#B6C938',
  running: '#4CC938',
  stopping: '#C97038',
  stopped: '#C93838',
  'shutting-down': '#957D6A',
  terminated: '#956A6A',
}

const Runner = () => {
  const [rows, setRows] = useState<RunnerType[]>([])
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState('')

  const fetchData = async () => {
    setLoading(true)
    await myFetch(`/api/runner`)
      .then((body) => body.json())
      .then((data: RunnerResponse) => {
        setRows(
          data.map((runner) => ({
            ...runner,
            Name: runner.Tags.find((t) => t.Key === 'Name')?.Value ?? '',
            type: runner.Tags.find((t) => t.Key === 'type')?.Value ?? '',
            env: runner.Tags.find((t) => t.Key === 'env')?.Value ?? '',
          }))
        )
      })
    setLoading(false)
  }

  useEffect(() => {
    fetchData()

    return () => {}
  }, [])

  const Row = (props: { runner: RunnerType }) => {
    const { runner } = props

    return (
      <>
        <TableRow>
          <TableCell align='left'>{runner.Name}</TableCell>
          <TableCell align='left'>{runner.InstanceId}</TableCell>
          <TableCell align='left' sx={{ color: colors[runner.State] }}>
            {runner.State}
          </TableCell>
          <TableCell align='left'>{runner.InstanceType}</TableCell>
          <TableCell align='right'>
            {new Date(runner.LaunchTime).toLocaleDateString('en-US', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false,
            })}
          </TableCell>
        </TableRow>
      </>
    )
  }

  return (
    <>
      <TextField
        fullWidth
        variant='outlined'
        label='Name / Instance ID'
        autoComplete='new-password'
        sx={{
          marginBottom: '24px',
        }}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setFilter(event.target.value)
        }}
      />
      <TableContainer
        component={Paper}
        sx={{
          filter: loading ? 'contrast(.7) brightness(0.7)' : '',
          position: 'relative',
          transition: '200ms',
          marginBottom: '75px',
        }}>
        {loading && <Loading />}
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Name</TableCell>
              <TableCell align='left'>Instance ID</TableCell>
              <TableCell align='left'>Instance state</TableCell>
              <TableCell align='left'>Instance type</TableCell>
              <TableCell align='right'>Launch Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .filter(
                (r) =>
                  r.Name.toLocaleLowerCase().includes(
                    filter.toLocaleLowerCase()
                  ) ||
                  r.InstanceId.toLocaleLowerCase().includes(
                    filter.toLocaleLowerCase()
                  )
              )
              .map((row) => (
                <Row runner={row} key={row.InstanceId} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default Runner
