import {
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import { DataGrid } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { CreatorStats } from 'types/types'
import { myFetch } from 'utils/utils'

const Dashboard = () => {
  const [loading, setLoading] = useState(false)
  const [statsByCreator, setStatsByCreator] = useState<CreatorStats[]>([])
  const [filteredStatsByCreator, setFilteredStatsByCreator] = useState<
    CreatorStats[]
  >([])

  const [showUnknown, setShowUnknown] = useState(false)
  const [creatorFilter, setCreatorNameFilter] = useState<string | undefined>()

  const getStatsByCreator = async () => {
    setLoading(true)
    const response = await myFetch('/api/sessions/stats_by_creator', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (response.ok) {
      const data = await response.json().catch(() => [])
      setStatsByCreator(data)
    }
    setLoading(false)
  }

  useEffect(() => {
    getStatsByCreator()
  }, [])

  useEffect(() => {
    let tmp = statsByCreator
    if (!showUnknown) tmp = tmp.filter((s) => s.creator_name !== '_UNKNOWN')
    if (creatorFilter && creatorFilter.length > 0)
      tmp = tmp.filter((s) => {
        let tmp = true
        if (creatorFilter.includes('id:')) {
          tmp =
            tmp &&
            s._id
              .toLowerCase()
              .includes(
                creatorFilter.split('id:')[1].split(' name:')[0].toLowerCase()
              )
        }
        if (creatorFilter.includes('name:')) {
          tmp =
            tmp &&
            s.creator_name
              .toLowerCase()
              .includes(
                creatorFilter.split('name:')[1].toLowerCase().split(' id:')[0]
              )
        }
        if (
          tmp &&
          !creatorFilter.includes('id:') &&
          !creatorFilter.includes('name:')
        )
          tmp =
            tmp &&
            s.creator_name.toLowerCase().includes(creatorFilter.toLowerCase())
        return tmp
      })

    setFilteredStatsByCreator(tmp)
  }, [statsByCreator, showUnknown, creatorFilter])

  return (
    <Box>
      <Typography
        variant='h4'
        sx={{ marginBottom: '20px', textAlign: 'center' }}
        color={'white'}>
        Sessions by Creator
      </Typography>
      <FormControl
        sx={{
          marginBottom: '20px',
          marginLeft: '20px',
          marginRight: '20px',
          width: 'calc(100% - 20px)',
          flexDirection: 'row',
        }}>
        <FormControlLabel
          control={
            <Switch
              checked={showUnknown}
              onChange={() => setShowUnknown((showUnknown) => !showUnknown)}
            />
          }
          label='Show Unknown Creators'
          sx={{
            color: 'white',
          }}
        />
        <TextField
          label='Filter (name: string or id: number)'
          variant='outlined'
          value={creatorFilter}
          sx={{
            flex: 1,
          }}
          onChange={(e) => {
            setCreatorNameFilter(e.target.value)
          }}
        />
      </FormControl>
      <DataGrid
        loading={loading}
        sx={{
          filter: loading ? 'contrast(.7) brightness(0.7)' : '',
          position: 'relative',
          transition: '200ms',
          height: 'calc(100vh - 250px)',
        }}
        columns={[
          { field: 'col1', headerName: 'ID', width: 250, flex: 0.25 },
          { field: 'col2', headerName: 'Creator Name', minWidth: 150, flex: 1 },
          { field: 'col3', headerName: 'Count', width: 250 },
        ]}
        rows={filteredStatsByCreator.map(
          ({ _id, count, creator_name }, index) => ({
            id: index,
            col1: _id,
            col2: creator_name,
            col3: count,
          })
        )}
      />
    </Box>
  )
}

export default Dashboard
