import {
  DialogTitle,
  TextField,
  List,
  ListItem,
  ListItemText,
} from '@mui/material'
import { MutableRefObject, useState, useEffect } from 'react'
import { PortalUser, Group } from 'types'
import { myFetch, lerpColor, getColor } from 'utils'
import { WhiteText } from 'components'

const FetchSimpleuserList = (props: {
  usersInGroup: PortalUser[]
  group?: Group
  customFetch?: (filter: string) => Promise<PortalUser[]>
  customClickEvent?: (user: PortalUser) => void
  handleClose: () => void
  alert?: MutableRefObject<HTMLDivElement | null>
  loadData?: () => void
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [data, setData] = useState<PortalUser[]>([])
  const [filter, setFilter] = useState<string | undefined>()

  const loadUsers = async () => {
    setError(false)
    setLoading(true)
    if (props.customFetch && (filter?.length ?? 0) > 1) {
      await props.customFetch(filter ?? '').then((data: PortalUser[]) => {
        const existingUserIds = props.usersInGroup.map((user) => user.id)
        setData(data.filter((user) => !existingUserIds.includes(user.id)))
      })
    } else
      await myFetch(`/api/admin/get_portal_user`)
        .then((body) => body.json())
        .then((data: PortalUser[]) => {
          const existingUserIds = props.usersInGroup.map((user) => user.id)
          setData(data.filter((user) => !existingUserIds.includes(user.id)))
        })
        .catch(() => setError(true))
    setLoading(false)
  }

  useEffect(() => {
    loadUsers()
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const borderColor = lerpColor(
    props.group
      ? lerpColor(getColor(props.group.path), '#FFFFFF', 0.3)
      : '#2D2D2D',
    '#FFFFFFF',
    0.6
  )

  return (
    <>
      <DialogTitle>
        <TextField
          label='Search User'
          variant='standard'
          sx={{
            display: 'block',
            '& label.Mui-focused': {
              color: borderColor,
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: borderColor,
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: borderColor,
              },
              '&:hover fieldset': {
                borderColor: borderColor,
              },
              '&.Mui-focused fieldset': {
                borderColor: borderColor,
              },
            },
          }}
          onChange={(event) => {
            setFilter(event.target.value)
            if (props.customFetch) loadUsers()
          }}
        />
      </DialogTitle>
      {error && <WhiteText variant='h4'>Error loading Users</WhiteText>}
      {data && !loading && (
        <List sx={{ pt: 0 }}>
          {data
            .filter((user) => user.email.startsWith(filter ?? ''))
            .map((user) => (
              <ListItem
                button
                key={user.id}
                onClick={async () => {
                  if (props.customClickEvent) props.customClickEvent(user)
                  else if (props.group)
                    await myFetch(
                      `/api/admin/add_user_to_group?group_id=${props.group.id}&user_id=${user.id}`,
                      {
                        method: 'PUT',
                      }
                    ).then((body) => {
                      const alertRef = props.alert?.current
                      if (body.status !== 200) {
                        if (alertRef) {
                          const message =
                            alertRef.querySelector<HTMLDivElement>(
                              '.MuiAlert-message'
                            )
                          if (message) message.innerText = body.statusText
                          alertRef.style.display = ''
                        }
                      } else if (alertRef) alertRef.style.display = 'none'
                    })
                  props.handleClose?.()
                  await props.loadData?.()
                }}>
                <ListItemText
                  primary={`${user.firstName} ${user.lastName}`}
                  secondary={user.email}
                />
              </ListItem>
            ))}
        </List>
      )}
    </>
  )
}

export default FetchSimpleuserList
