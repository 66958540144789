import { Search } from '@mui/icons-material'
import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  Box,
  Button,
  CircularProgress,
  CircularProgressProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  styled,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import {
  ChangeEvent,
  CSSProperties,
  ReactNode,
  useEffect,
  useState,
} from 'react'

import { currency, Currency, LicenseUser } from 'types'
import { myFetch } from 'utils'

import InternalAlertDialog from './AlertDialog'
import InternalDefaultTextInput from './DefaultInputField'
import InternalLoading from './Loading'
import InternalTablePaginationActions from './TablePaginationActions'
import InternalTagsInput from './TagsInput'
import InternalVideoPlayer from './VideoPlayer'

import { MCheckBoxGroup, MRadioGroup } from './InputGroups'

/**
 * This file is mainly a mapping for the components in this folder.
 * It is used to make the components more easily accessible.
 * It also contains some components that are used in multiple places.
 */

export const WhiteText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}))

export const Error = (props: { message: string }) => (
  <WhiteText variant='h4'>{props.message}</WhiteText>
)

export const MoneyInput = (props: {
  label: string
  amount?: number
  currency?: Currency
  onChange: (amount?: number, currency?: Currency) => void
}) => {
  const {
    label,
    amount: defaultAmount,
    currency: defaultCurrency,
    onChange,
  } = props

  const [amount, setAmount] = useState<number | undefined>(defaultAmount)
  const [cur, setCur] = useState<Currency | undefined>(defaultCurrency)

  return (
    <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
      <TextField
        sx={{
          marginTop: '8px',
          marginBottom: '8px',
          width: '58%',
          'input::-webkit-outer-spin-button,input::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
        }}
        type='number'
        label={label}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const amount = parseInt(event.target.value)
          setAmount(amount)
          onChange(amount, cur)
        }}
      />
      <Autocomplete
        options={currency}
        sx={{ width: '40%', display: 'inline-flex' }}
        onChange={(_: any, value: any) => {
          if (currency.includes(value)) {
            setCur(value)
            onChange(amount, value)
          }
        }}
        renderInput={(params) => (
          <TextField
            autoComplete='new-password'
            sx={{ marginTop: '8px', marginBottom: '8px' }}
            {...params}
            label='Currency'
          />
        )}
      />
    </Box>
  )
}

export const FindUser = (props: { onSelect: (user: LicenseUser) => void }) => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState<LicenseUser[]>([])

  const search = async () => {
    setLoading(true)
    await myFetch(`/api/management/get_user?email=${email}`)
      .then((body) => body.json())
      .then((data: LicenseUser[]) => {
        setUsers(data)
      })
    setLoading(false)
  }

  return (
    <>
      <FormControl fullWidth variant='standard'>
        <Input
          placeholder='Email'
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                edge='end'
                onClick={async () => {
                  await search()
                }}>
                <Search />
              </IconButton>
            </InputAdornment>
          }
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value)
          }}
          onKeyPress={async (event: any) => {
            if (event.key === 'Enter') await search()
          }}
        />
      </FormControl>
      <Box
        sx={{
          position: 'relative',
          marginTop: '24px',
          marginBottom: '24px',
          minHeight: '50px',
        }}>
        {loading && (
          <CircularProgress
            style={{
              position: 'absolute',
              top: '50%',
              left: 'calc(50% - 15px)',
            }}
            size={30}
          />
        )}
        <List>
          {users.map((user) => (
            <ListItemButton onClick={() => props.onSelect(user)} key={user.id}>
              <ListItemText
                primary={`${user.firstName} ${user.lastName} (${user.email})`}
                secondary={user.id}
              />
            </ListItemButton>
          ))}
        </List>
      </Box>
    </>
  )
}

export const SimpleDialog = (props: {
  open: boolean
  onClose: () => void
  title: string
  contentText: string
  secondAction?: JSX.Element
  children: ReactNode
}) => {
  return (
    <Dialog
      maxWidth='xl'
      open={props.open}
      onClose={props.onClose}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '8px',
          '::-webkit-scrollbar': {
            width: '5px',
            height: '5px',
          },
          '::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '::-webkit-scrollbar-thumb': {
            borderRadius: '8px',
          },
        },
      }}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: '24px' }}>
          {props.contentText}
        </DialogContentText>
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        {props.secondAction}
      </DialogActions>
    </Dialog>
  )
}

export function AsyncSearchField<T, U>(props: {
  sx?: SxProps<Theme>
  label: string
  error?: boolean
  errorMessage?: string
  className?: string
  defaultValue?: T
  renderTags?: (
    value: T[],
    getTagProps: AutocompleteRenderGetTagProps
  ) => React.ReactNode
  onType: (text: string) => Promise<T[]>
  getLabel: (t: T) => string
  onSelect: (t: U) => void
}) {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<T[]>([])
  const [inputText, setInputText] = useState<string>('')
  const loading = open && options.length === 0

  const {
    sx,
    label,
    renderTags,
    onType,
    getLabel,
    onSelect,
    error,
    errorMessage,
    className,
    defaultValue,
  } = props

  useEffect(() => {
    let active = true

    if (!loading) return
    ;(async () => {
      const options = await onType(inputText)

      if (active) setOptions(options)
    })()

    return () => {
      active = false
    }
  }, [loading, inputText, onType])

  useEffect(() => {
    setOptions([])
  }, [inputText])

  useEffect(() => {
    if (!open) setOptions([])
  }, [open])

  return (
    <Autocomplete
      sx={sx}
      multiple={!!renderTags}
      renderTags={renderTags}
      open={open}
      className={`async-search-field${className ? ` ${className}` : ''}`}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      isOptionEqualToValue={(option, value) =>
        getLabel(option) === getLabel(value)
      }
      defaultValue={defaultValue}
      getOptionLabel={(option) => getLabel(option)}
      options={options}
      loading={loading}
      onChange={(_: any, value: any) => {
        onSelect(value)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={error ? errorMessage : ''}
          autoComplete='new-password'
          sx={{ marginTop: '8px', marginBottom: '8px' }}
          label={label}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setInputText(event.target.value)
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export const CircularProgressWithLabel = (
  props: CircularProgressProps & { value: number; textStyle?: CSSProperties }
) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', margin: 'auto' }}>
      <CircularProgress variant='determinate' {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography
          variant='caption'
          component='div'
          color='text.secondary'
          style={props.textStyle}>{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

export const CircularGradient = () => (
  <svg width='0' height='0' display='block'>
    <defs>
      <linearGradient
        id='circularGradient'
        gradientUnits='objectBoundingBox'
        x1='0'
        y1='0'
        x2='1'
        y2='1'>
        <stop offset='25%' stopColor='#80FF00' />
        <stop offset='64%' stopColor='#00FF98' />
      </linearGradient>
    </defs>
  </svg>
)

export const Loading = InternalLoading
export const TagsInput = InternalTagsInput
export const VideoPlayer = InternalVideoPlayer
export const AlertDialog = InternalAlertDialog
export const DefaultTextInput = InternalDefaultTextInput
export const TablePaginationActions = InternalTablePaginationActions

export const RadioGroup = MRadioGroup
export const CheckBoxGroup = MCheckBoxGroup
