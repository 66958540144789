import {
    Add as AddIcon,
    Close as CloseIcon,
    DeleteOutline as DeleteIcon,
    Edit as EditIcon,
} from '@mui/icons-material'
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    Fab,
    IconButton,
    Modal,
    Typography,
} from '@mui/material'
import { DataGrid, GridSortModel } from '@mui/x-data-grid'
import { AsyncSearchField, DefaultTextInput } from 'components/Components'
import { useEffect, useState } from 'react'
import {
    Pricing as PricingResponse,
    PricingType,
    PricingTypes,
} from 'types/types'
import { myFetch } from 'utils/utils'

const DeleteComponent = (props: {
  id: number
  onDelete: () => Promise<void>
}) => {
  const { id, onDelete } = props

  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
            color: 'text.secondary',
          }}
          onClick={(event) => {
            event.stopPropagation()
          }}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Delete pricing ({id})
          </Typography>
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            Are you sure you want to delete this pricing?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              onClick={() => setOpen(false)}
              color='primary'
              variant='contained'
              sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button
              onClick={async () => {
                setLoading(true)
                await onDelete()
                setLoading(false)
                setOpen(false)
              }}
              disabled={loading}
              color='error'
              variant='contained'>
              Delete{' '}
              {loading && (
                <CircularProgress
                  size={'18px'}
                  sx={{
                    marginLeft: '15px',
                  }}
                />
              )}
            </Button>
          </Box>
        </Box>
      </Modal>
      <IconButton
        onClick={async (event) => {
          event.stopPropagation()
          setOpen(true)
        }}
        disabled={loading}
        color='error'
        size='small'>
        {loading ? (
          <CircularProgress size={24} color='error' />
        ) : (
          <DeleteIcon />
        )}
      </IconButton>
    </>
  )
}

const EditComponent = (props: { onEdit: () => Promise<void> }) => {
  const { onEdit } = props

  const [loading, setLoading] = useState(false)

  return (
    <IconButton
      onClick={async (event) => {
        event.stopPropagation()
        setLoading(true)
        await onEdit()
        setLoading(false)
      }}
      disabled={loading}
      color='primary'
      size='small'>
      {loading ? <CircularProgress size={24} color='primary' /> : <EditIcon />}
    </IconButton>
  )
}

type Props = {
  id?: number
  identifier?: string
  type?: PricingType
  note?: string
  price?: string
  update: boolean
  open: boolean
  setOpen?: (open: boolean) => void
  onClosed?: (success: boolean) => void
}

const CreateUpdatePricing = (props: Props) => {
  const {
    open,
    setOpen,
    onClosed,
    update,
    id: pricingId,
    identifier: pricingIdentifier,
    type: pricingType,
    note: pricingNote,
    price: pricingPrice,
  } = props

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [pricingData, setPricingData] = useState<{
    type?: PricingType
    identifier?: string
    note?: string
    price?: string
  }>({
    type: pricingType,
    identifier: pricingIdentifier,
    note: pricingNote,
    price: pricingPrice,
  })

  const createUpdatePricing: () => Promise<boolean | string> = async () => {
    const params = new URLSearchParams()

    const {
      type: pricingType,
      identifier: pricingIdentifier,
      note: pricingNote,
      price: pricingPrice,
    } = pricingData

    if (pricingId) params.append('id', pricingId.toString())
    if (pricingNote && !pricingId) params.append('note', pricingNote)

    if (!pricingType || !pricingIdentifier || !pricingPrice) return false

    params.append('object_type', pricingType)
    params.append('identifier', pricingIdentifier)
    params.append('price', pricingPrice)

    setLoading(true)

    const res = await myFetch('/api/pricing', {
      method: update ? 'PATCH' : 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      query: params,
    })

    setLoading(false)

    if (!res.ok) return await res.text()

    return true
  }

  useEffect(() => {
    if (open)
      setPricingData({
        type: pricingType,
        identifier: pricingIdentifier,
        note: pricingNote,
        price: pricingPrice,
      })
  }, [open, pricingType, pricingIdentifier, pricingNote, pricingPrice])

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen?.(false)
        onClosed?.(false)
      }}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '16px',
        },
      }}>
      <Box
        sx={{
          margin: '16px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'end',
          position: 'relative',
        }}>
        <IconButton
          sx={{
            position: 'absolute',
            top: '0',
            right: '0',
          }}
          onClick={() => setOpen?.(false)}>
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            marginTop: '36px',
            display: 'flex',
            flexDirection: 'row',
          }}>
          <AsyncSearchField
            sx={{
              flex: 0.5,
              minWidth: '150px',
              marginRight: '8px',
            }}
            label='Type*'
            getLabel={(item: PricingType) => item}
            onType={async (value: string) =>
              PricingTypes.filter((item) =>
                item.toUpperCase().startsWith(value.toUpperCase())
              )
            }
            error={!pricingData.type || pricingData.type === ''}
            defaultValue={pricingData.type}
            onSelect={(type: PricingType) => {
              setPricingData({ ...pricingData, type })
            }}
          />
          <DefaultTextInput
            sx={{
              flex: 0.75,
              minWidth: '150px',
              marginRight: '8px',
            }}
            error={!pricingData.identifier || pricingData.identifier === ''}
            state={[pricingData, setPricingData]}
            label='Identifier*'
            name='identifier'
          />
          <DefaultTextInput
            sx={{
              flex: 0.5,
              minWidth: '150px',
            }}
            error={!pricingData.price || pricingData.price === ''}
            state={[pricingData, setPricingData]}
            label='Price*'
            name='price'
            type='number'
          />
        </Box>
        <DefaultTextInput
          sx={{
            flex: 1,
            minWidth: '150px',
            marginBottom: '16px',
          }}
          state={[pricingData, setPricingData]}
          label='Note'
          name='note'
          disabled={update}
        />
        <Button
          variant='outlined'
          disabled={
            !pricingData.type ||
            pricingData.type === '' ||
            !pricingData.identifier ||
            pricingData.identifier === '' ||
            !pricingData.price ||
            pricingData.price === ''
          }
          onClick={() => {
            if (loading) return
            createUpdatePricing().then((res) => {
              if (res === true) {
                setOpen?.(false)
                onClosed?.(true)
              } else if (res === false) {
                alert('Something went wrong')
              } else {
                setError(res)
              }
            })
          }}>
          {update ? 'Update' : 'Create'} Pricing
          {update ? ` (${pricingId})` : ''}{' '}
          {loading && (
            <CircularProgress size={'18px'} sx={{ marginLeft: '15px' }} />
          )}
        </Button>
        <Typography variant='caption' sx={{ marginTop: '8px', opacity: 0.7 }}>
          * Required
        </Typography>
        {error && (
          <Typography variant='caption' sx={{ marginTop: '8px', color: 'red' }}>
            {error}
          </Typography>
        )}
      </Box>
    </Dialog>
  )
}

const Pricing = () => {
  const [data, setData] = useState<PricingResponse[]>([])
  const [pricingFilter, setPricingFilter] = useState<{
    type?: PricingType
    identifier?: string
    id?: number
  }>({})
  const [dataToEdit, setDataToEdit] = useState<{
    id?: number
    identifier?: string
    type?: PricingType
    note?: string
    price?: string
    update: boolean
  }>({
    update: false,
  })

  const [loading, setLoading] = useState(false)
  const [editCreate, setEditCreate] = useState(false)

  const [sortModel, setSortModel] = useState<GridSortModel>([])

  const getData = async (params?: URLSearchParams) => {
    setLoading(true)

    const res = await myFetch('/api/pricing', {
      method: 'GET',
      query: params,
    })

    await res
      .json()
      .then((data) => {
        if (Array.isArray(data)) setData(data)
        else if (data) setData([data])
      })
      .catch(() => {})

    setLoading(false)
  }

  const deletePricing: (id: number) => Promise<boolean | string> = async (
    id: number
  ) => {
    setLoading(true)

    const res = await myFetch(`/api/pricing`, {
      method: 'DELETE',
      query: { id },
    })

    setLoading(false)

    if (!res.ok) return await res.text()

    return true
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    const params = new URLSearchParams()

    if (pricingFilter.type) params.append('object_type', pricingFilter.type)
    if (pricingFilter.identifier)
      params.append('identifier', pricingFilter.identifier)
    if (pricingFilter.id) params.append('id', pricingFilter.id.toString())

    getData(params)
  }, [pricingFilter])

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '8px',
          flexWrap: 'wrap',
          ':nth-child(1) > div:not(:last-of-type)': {
            marginRight: '8px',
          },
        }}>
        <AsyncSearchField
          sx={{
            flex: 0.33,
            minWidth: '150px',
          }}
          label='Type'
          getLabel={(item: PricingType) => item}
          onType={async (value: string) =>
            PricingTypes.filter((item) =>
              item.toUpperCase().startsWith(value.toUpperCase())
            )
          }
          onSelect={(type: PricingType) => {
            setPricingFilter({ ...pricingFilter, type })
          }}
        />
        <DefaultTextInput
          sx={{
            flex: 0.75,
            minWidth: '150px',
          }}
          state={[pricingFilter, setPricingFilter]}
          label='Identifier'
          name='identifier'
        />
        <DefaultTextInput
          sx={{
            flex: 0.25,
            minWidth: '150px',
          }}
          state={[pricingFilter, setPricingFilter]}
          label='Id'
          name='id'
          type='number'
        />
      </Box>
      <DataGrid
        loading={loading}
        sx={{
          height: 'calc(100vh - 260px)',
        }}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        rows={data}
        getRowId={(row) => row.id + row.type + row.identifier}
        columns={[
          { field: 'id', headerName: 'ID', width: 70 },
          { field: 'type', headerName: 'Type', width: 130 },
          { field: 'price', headerName: 'Price', width: 130 },
          { field: 'note', headerName: 'Note', minWidth: 70, flex: 1 },
          {
            field: 'identifier',
            headerName: 'Identifier',
            minWidth: 70,
            flex: 1,
          },
          {
            field: 'update',
            headerName: '',
            width: 60,
            sortable: false,
            filterable: false,
            hideable: false,
            renderCell: (params) => (
              <EditComponent
                onEdit={async () => {
                  setDataToEdit({ ...params.row, update: true })
                  setEditCreate(true)
                }}
              />
            ),
          },
          {
            field: 'delete',
            headerName: '',
            width: 60,
            sortable: false,
            filterable: false,
            hideable: false,
            renderCell: (params) => (
              <DeleteComponent
                id={params.row.id}
                onDelete={async () => {
                  await deletePricing(params.row.id).then(async (res) => {
                    if (res === true) {
                      await getData()
                      setSortModel([
                        {
                          field: 'id',
                          sort: 'desc',
                        },
                      ])
                    } else if (res === false) {
                      alert('Something went wrong')
                    } else {
                      alert(res)
                    }
                  })
                }}
              />
            ),
          },
        ]}
      />
      <Fab
        sx={{
          position: 'fixed',
          bottom: '16px',
          right: '16px',
        }}
        onClick={() => {
          setDataToEdit({ update: false })
          setEditCreate(true)
        }}>
        <AddIcon />
      </Fab>
      <CreateUpdatePricing
        open={editCreate}
        setOpen={setEditCreate}
        onClosed={async () => {
          setDataToEdit({ update: false })
          await getData()
          setSortModel([
            {
              field: 'id',
              sort: 'desc',
            },
          ])
        }}
        {...dataToEdit}
      />
    </Box>
  )
}

export default Pricing
