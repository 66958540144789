import { addHours, getHours, getMinutes, getSeconds } from 'date-fns'
import Keycloak from 'keycloak-js'
import React, { MutableRefObject } from 'react'
import {
  IDLeagueInternAccountAdmin,
  IDLeagueInternAccountViewer,
  IDLeagueInternClubsAdmin,
  IDLeagueInternClubsCreator,
  IDLeagueInternClubsViewer,
  IDLeagueInternLicensesAdmin,
  IDLeagueInternLicensesViewer,
  IDLeagueInternSuperuser,
  IDLeagueInternVideosAdmin,
  IDLeagueInternVideosViewer,
  PathType,
  UserContextData,
} from 'types'
import { utils, write } from 'xlsx'

export const onReady = (
  keycloak: Keycloak,
  callback: (authenticated?: boolean) => void,
  timeout: number = 5000
) => {
  let timedOut = false

  const t = setTimeout(() => {
    timedOut = true
    callback(keycloak.authenticated)
  }, timeout)

  const orig = keycloak.onReady
  keycloak.onReady = (a) => {
    if (!timedOut) {
      clearTimeout(t)
      orig?.(a)
      callback(a)
    }
  }
}
/* eslint-disable no-mixed-operators */
export const UserContext = React.createContext<UserContextData | null>(null)

export const setCookie = (cname: string, cvalue: any, exdays: number) => {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  let expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export const deleteCookie = (cname: string) => {
  setCookie(cname, '', -1)
}

export const getCookie = (cname: string): any => {
  let name = cname + '='
  let ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1)
    if (c.indexOf(name) === 0) return c.substring(name.length, c.length)
  }
  return null
}

export const clearCookies = () => {
  console.log('CLEAR')
  document.cookie.split(';').forEach((cookie) => {
    console.log(cookie)
    deleteCookie(cookie.split('=')[0])
  })
}

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export const getTokenData = (token: string = getCookie('access_token')) => {
  return token ? { token, ...parseJwt(token) } : null
}

export const parseGroups = (groups: string[]) => {
  return groups.map((group) => {
    const splits = group.split('/')
    let obj = {}
    if (splits.length > 1) obj = { ...obj, group: splits[1] }
    if (splits.length > 2) obj = { ...obj, type: splits[2] }
    return obj
  })
}

export const copyToClipboard = (text: string) => {
  const textField = document.createElement('textarea')
  textField.innerHTML = text
  document.body.appendChild(textField)
  textField.select()
  document.execCommand('copy')
  textField.remove()
}

// simple function to download text as file
export const downloadAsFile = (
  text: string,
  name: string,
  type: string = 'text/plain'
) => {
  const blob = new Blob([text], { type })
  const a = document.createElement('a')
  a.download = name
  a.href = URL.createObjectURL(blob)
  a.click()
}

// since its not possible to change the name of a downloaded file via a-tag:download
// we have to use this workaround using XMLHttpRequest, this downloads the file in the background
// for this reason we have the onStart and onProgress callbacks, the onStart also gives you the xhr object so you can abort the download if you want
export const downloadUrlAsFile = async (
  url: string,
  name: string,
  onStart: (xhr: XMLHttpRequest) => void = () => {},
  onProgress?: (progress: number, total: number, current: number) => void
) => {
  await new Promise<void>((res, rej) => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.onprogress = (event) => {
      if (event.lengthComputable && onProgress) {
        const progress = (event.loaded / event.total) * 100
        onProgress(progress, event.total, event.loaded)
      }
    }
    xhr.onerror = rej
    xhr.onabort = rej
    xhr.onload = () => {
      const blob = xhr.response
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = name
      a.click()
      window.URL.revokeObjectURL(url)
      res()
    }
    xhr.send()
    onStart(xhr)
  })
}

export const hashCode = (str: string): number => {
  let hash = 0
  for (var i = 0; i < str.length; i++)
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  return hash
}

export const intToRGB = (i: number): string => {
  const c = (i & 0x00ffffff).toString(16).toUpperCase()

  return '#00000'.substring(0, 7 - c.length) + c
}

export const lerpColor = (a: string, b: string, amount: number) => {
  const ah = parseInt(a.replace(/#/g, ''), 16),
    ar = ah >> 16,
    ag = (ah >> 8) & 0xff,
    ab = ah & 0xff,
    bh = parseInt(b.replace(/#/g, ''), 16),
    br = bh >> 16,
    bg = (bh >> 8) & 0xff,
    bb = bh & 0xff,
    rr = ar + amount * (br - ar),
    rg = ag + amount * (bg - ag),
    rb = ab + amount * (bb - ab)

  return (
    '#' + (((1 << 24) + (rr << 16) + (rg << 8) + rb) | 0).toString(16).slice(1)
  )
}

export const getColor = (path: string, lerp: number = 0.5) => {
  return lerpColor(intToRGB(hashCode(path)), '#2D2D2D', lerp)
}

export const groupColor = (path: string, name: string) => {
  return lerpColor(getColor(path.split('/')[2] ?? ''), getColor(name), 0.3)
}

// custom fetch implementation to add authorization header and easily add query params
export const myFetch = async (
  input: string,
  init?: RequestInit & { query?: { [key: string]: any } }
): Promise<Response> => {
  const { headers = {}, query, ...restOptions } = init || {};

  // Construct the URL, including query parameters if provided
  const baseUrl = input.startsWith('http')
    ? input
    : `${process.env.REACT_APP_API_URL}${input}`;
  const url = query
    ? `${baseUrl}?${new URLSearchParams(query).toString()}`
    : baseUrl;

  // Configure request headers
  const defaultHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'ADMIN-PORTAL-TOKEN': process.env.REACT_APP_ADMIN_PORTAL_TOKEN || '', // Add the token from the environment
  };
  const mergedHeaders = { ...defaultHeaders, ...headers };

  // Configure fetch options
  const fetchOptions: RequestInit = {
    ...restOptions,
    headers: mergedHeaders,
    mode: 'cors', // Ensure CORS mode is set
  };

  // Execute the fetch request
  return fetch(url, fetchOptions);
};

export const permissionCheck = (group: string, permission: PathType) => {
  return (
    group === permission ||
    group === IDLeagueInternSuperuser ||
    (permission === IDLeagueInternAccountViewer &&
      group === IDLeagueInternAccountAdmin) ||
    (permission === IDLeagueInternClubsViewer &&
      group === IDLeagueInternClubsAdmin) ||
    (permission === IDLeagueInternClubsViewer &&
      group === IDLeagueInternClubsCreator) ||
    (permission === IDLeagueInternClubsCreator &&
      group === IDLeagueInternClubsAdmin) ||
    (permission === IDLeagueInternLicensesViewer &&
      group === IDLeagueInternLicensesAdmin) ||
    (permission === IDLeagueInternVideosViewer &&
      group === IDLeagueInternVideosAdmin)
  )
}

export const useCustomAlert =
  (alert: MutableRefObject<HTMLDivElement | null>) => (msg: string) => {
    const alertRef = alert.current
    if (alertRef) {
      const message =
        alertRef.querySelector<HTMLDivElement>('.MuiAlert-message')
      if (message) message.innerText = msg
      alertRef.style.display = ''
      setTimeout(() => {
        alertRef.style.display = 'none'
      }, 3000 + (msg.length > 10 ? msg.length * 10 : 0))
    }
  }

export const hasAudio = (video?: HTMLVideoElement | null) => {
  if (!video) return false
  const tmp = video as any
  return (
    tmp.mozHasAudio ||
    Boolean(tmp.webkitAudioDecodedByteCount) ||
    Boolean(tmp.audioTracks && tmp.audioTracks.length)
  )
}

export const bytesToHuman = (bytes: number, fixed: number = 2) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return (bytes / Math.pow(1024, i)).toFixed(fixed) + ' ' + sizes[i]
}

export const convertToDuration = (milliseconds: number) => {
  const normalizeTime = (time: string): string =>
    time.length === 1 ? `0${time}` : time

  const MINUTES_IN_HOUR = 60

  const date = new Date(milliseconds)
  const timezoneDiff = date.getTimezoneOffset() / MINUTES_IN_HOUR
  const dateWithoutTimezoneDiff = addHours(date, timezoneDiff)

  const hours = normalizeTime(String(getHours(dateWithoutTimezoneDiff)))
  const minutes = normalizeTime(String(getMinutes(dateWithoutTimezoneDiff)))
  const seconds = normalizeTime(String(getSeconds(dateWithoutTimezoneDiff)))

  const hoursOutput = hours !== '00' ? `${hours}:` : ''

  return `${hoursOutput}${minutes}:${seconds}`
}

// function to search for a key in an object
export const deepSearch = (
  object: { [key: string]: any },
  key: string,
  predicate: (k: string, v: any) => Boolean
): { [key: string]: any } | any | null => {
  if (object.hasOwnProperty(key) && predicate(key, object[key]) === true)
    return object

  for (let i = 0; i < Object.keys(object).length; i++) {
    let value = object[Object.keys(object)[i]]
    if (typeof value === 'object' && value != null) {
      let o = deepSearch(object[Object.keys(object)[i]], key, predicate)
      if (o != null) return o
    }
  }
  return null
}

export const csvToXlsx = (csv: string) => {
  const wb = utils.book_new()
  const ws = utils.aoa_to_sheet(csv.split('\n').map((r) => r.split(',')))
  utils.book_append_sheet(wb, ws, 'Sheet1')

  return write(wb, { type: 'array', bookType: 'xlsx' })
}
