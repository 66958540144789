import { ChangeEvent, useEffect, useRef, useState } from 'react'
import {
  Button,
  Box,
  Card,
  CircularProgress,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { Add, Delete, Edit } from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router-dom'
import { myFetch } from 'utils'
import {
  VideoApiResponse,
  VideoStatus,
  VideoUrlDetails,
  VisibilityChoicesType,
} from 'types'
import { WhiteText, AlertDialog } from 'components'
import { EditVideoDialog, UploadVideoDialog, VideoDialog } from './Components'
import './Videos.css'

const Videos = () => {
  const theme = useTheme()

  const navigate = useNavigate()

  const { videoId } = useParams()

  const [data, setData] = useState<VideoApiResponse>([])
  const [rows, setRows] = useState<JSX.Element[]>([])
  const [allLoaded, setAllLoaded] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState('')

  const [optionalVideoList, setOptionalVideoList] = useState(false)
  const [uploadVideoOpen, setUploadVideoOpen] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [editVideoOpen, setEditVideoOpen] = useState(false)
  const [uuid, setUuid] = useState('')

  const deleteVideo = (uuid: string) => {}

  const [videoDialog, setVideoDialog] = useState<
    { source: string; title: string } | undefined
  >()

  const contents = useRef<HTMLDivElement>(null)

  interface ItemType {
    uuid: string
    title: string
    subTitle?: string
    thumbnail: string
    preview?: string
    dummy?: boolean
    status?: VideoStatus
    visiblity?: VisibilityChoicesType
  }
  interface RowType extends Array<ItemType> {}

  const fetchVideo = async (uuid: String) =>
    myFetch(`/api/video?uuid=${uuid}`).then<VideoUrlDetails>((body) =>
      body.json()
    )

  const openVideo = async (uuid: String) => {
    const json = await fetchVideo(uuid)
    if (json.url) {
      setVideoDialog({ source: json.url, title: json.title })
      const cookies: { [key: string]: string } = {}
      json.cookies.split(new RegExp('(,|;) ')).forEach((cookie) => {
        const key = cookie.split('=')[0]
        if (key !== ';' && key !== ',') cookies[key] = cookie.split('=')[1]
      })
      Object.entries(cookies).forEach(([key, value]) => {
        if (
          key === 'CloudFront-Key-Pair-Id' ||
          key === 'CloudFront-Policy' ||
          key === 'CloudFront-Signature'
        ) {
          document.cookie = `${key}=${value};domain=${cookies['Domain']};path=${cookies['Path']}`
        }
      })
    }
  }

  useEffect(() => {
    if (videoId) openVideo(videoId)
    else setVideoDialog(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId])

  const IdTubeThumbnail = (props: { src: string; animated?: string }) => {
    const thumbnailContainer = useRef<HTMLElement>(null)
    const thumbnailImage = useRef<HTMLImageElement>(null)
    const animatedThumbnailImage = useRef<HTMLImageElement>(null)
    const [hovering, setHovering] = useState(false)
    const [fetching, setFetching] = useState(false)

    useEffect(() => {
      const current = thumbnailContainer.current
      if (current) {
        current.addEventListener('mouseenter', () => {
          setHovering(true)
        })
        current.addEventListener('mouseleave', () => {
          setHovering(false)
        })
      }
      return () => {}
    }, [thumbnailContainer])

    useEffect(() => {
      const current = thumbnailImage.current
      if (current) {
        const image = new Image()
        setFetching(true)
        image.addEventListener('load', () => {
          setFetching(false)
        })
        image.src = props.src
      }
      return () => {}
    }, [thumbnailImage, props])

    return (
      <id-tube-thumbnail ref={thumbnailContainer}>
        <img
          alt='thumbnail'
          src={fetching ? '/static/missing.jpg' : props.src}
          style={props.animated && hovering ? { display: 'none' } : {}}
          ref={thumbnailImage}
        />
        <img
          alt='animatedThumbnail'
          style={props.animated && hovering ? {} : { display: 'none' }}
          src={props.animated}
          ref={animatedThumbnailImage}
        />
      </id-tube-thumbnail>
    )
  }

  const generateItems = (list: RowType) => {
    const items: JSX.Element[] = []

    const CardItem = (props: { item: ItemType }) => {
      const [showButtons, setShowButtons] = useState(false)

      return (
        <Card
          className='card'
          onClick={async () => {
            navigate('/videos/' + props.item.uuid)
          }}
          sx={{
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor:
              props.item.visiblity === 'private' ? '#ff000059' : 'transparent',
            backgroundColor:
              props.item.visiblity === 'private' ? '#121212' : '#171d2e',
          }}
          onMouseEnter={() => setShowButtons(true)}
          onMouseLeave={() => setShowButtons(false)}>
          <id-tube-rich-grid-media class='id-tube-rich-item-renderer'>
            <div style={{ position: 'relative' }}>
              <IdTubeThumbnail
                src={props.item.thumbnail}
                animated={props.item.preview}
              />
              <Box
                sx={
                  showButtons
                    ? {
                        position: 'absolute',
                        bottom: '0px',
                        right: '0px',
                      }
                    : { display: 'none' }
                }
                onClick={(e) => e.stopPropagation()}>
                <IconButton
                  onClick={async (e) => {
                    e.stopPropagation()
                    setShowButtons(false)
                    setUuid(props.item.uuid)
                    setEditVideoOpen(true)
                  }}>
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation()
                    setShowButtons(false)
                    setUuid(props.item.uuid)
                    setConfirmDelete(true)
                  }}>
                  <Delete />
                </IconButton>
              </Box>
            </div>
            <id-tube-details>
              <Typography
                variant='inherit'
                component='p'
                className='title'
                color={theme.palette.text.primary}>
                {props.item.title}
              </Typography>
              {props.item.subTitle ? (
                <Typography
                  variant='inherit'
                  component='p'
                  className='sub-title'
                  color={theme.palette.text.primary}>
                  {props.item.subTitle}
                </Typography>
              ) : (
                <></>
              )}
              <div style={{ height: 8 }}></div>
            </id-tube-details>
          </id-tube-rich-grid-media>
        </Card>
      )
    }

    for (let item of list)
      items.push(
        <id-tube-rich-item-renderer
          class={`id-tube-rich-grid-row ${item.dummy ? 'invisible' : ''}`}
          key={(Math.random() + 1).toString(36).substring(2)}>
          <div id='content' className='id-tube-rich-item-renderer'>
            <CardItem item={item} />
          </div>
        </id-tube-rich-item-renderer>
      )

    return items
  }

  const generateRows = (list: Array<RowType>) => {
    const simpleItemProps = {
      variant: 'h5',
      fontSize: 20,
    } as any

    const rows: JSX.Element[] = optionalVideoList
      ? [
          <id-tube-simple-row
            key={(Math.random() + 1).toString(36).substring(2)}>
            <div id='contents' className='id-tube-simple-row'>
              <id-tube-simple-item-renderer>
                <Grid container spacing={0}>
                  <Grid item xs={1.5}>
                    <WhiteText {...simpleItemProps} fontSize={24}>
                      Title
                    </WhiteText>
                  </Grid>
                  <Grid item xs={2.5}>
                    <WhiteText {...simpleItemProps} fontSize={24}>
                      Subtitle
                    </WhiteText>
                  </Grid>
                  <Grid item xs={5}>
                    <WhiteText {...simpleItemProps} fontSize={24}>
                      UUID
                    </WhiteText>
                  </Grid>
                  <Grid item xs={3}>
                    <WhiteText {...simpleItemProps} fontSize={24}>
                      Status
                    </WhiteText>
                  </Grid>
                </Grid>
              </id-tube-simple-item-renderer>
            </div>
          </id-tube-simple-row>,
        ]
      : []

    if (optionalVideoList)
      for (let row of list)
        rows.push(
          <id-tube-simple-row
            key={(Math.random() + 1).toString(36).substring(2)}>
            <div id='contents' className='id-tube-simple-row'>
              {row.map((item) => (
                <id-tube-simple-item-renderer>
                  <Grid container spacing={0}>
                    <Grid item xs={1.5}>
                      <WhiteText {...simpleItemProps}>{item.title}</WhiteText>
                    </Grid>
                    <Grid item xs={2.5}>
                      <WhiteText {...simpleItemProps}>
                        {item.subTitle}
                      </WhiteText>
                    </Grid>
                    <Grid item xs={5}>
                      <WhiteText {...simpleItemProps}>{item.uuid}</WhiteText>
                    </Grid>
                    <Grid item xs={3}>
                      <WhiteText
                        {...simpleItemProps}
                        style={{ textAlign: 'end' }}>
                        {item.status}
                      </WhiteText>
                    </Grid>
                  </Grid>
                </id-tube-simple-item-renderer>
              ))}
            </div>
          </id-tube-simple-row>
        )
    else
      for (let row of list)
        rows.push(
          <id-tube-rich-grid-row
            class='id-tube-rich-grid-renderer'
            key={(Math.random() + 1).toString(36).substring(2)}>
            <div id='contents' className='id-tube-rich-grid-row'>
              {generateItems(row)}
            </div>
          </id-tube-rich-grid-row>
        )

    return rows
  }

  const fetchVideos = async (
    amountToShow = 0,
    oldAmount = 0,
    internalData: VideoApiResponse = data,
    nestingLevel = 0,
    filter = ''
  ) => {
    setLoading(true)

    if (nestingLevel === 0)
      oldAmount = internalData.filter(
        (d) => d.status === 'ENCODING_DONE'
      ).length

    const contentJson = await myFetch(
      `/api/videos?limit=10&start_index=${internalData.length}&name=${filter}`
    )
      .then((body) => body.json())
      .catch(() => [])

    if (
      contentJson.length > 0 &&
      !internalData.map((d) => d.uuid).includes(contentJson[0].uuid)
    )
      internalData = [...internalData, ...contentJson]
    else if (contentJson.length === 0) {
      setAllLoaded(true)
      if (nestingLevel === 0) {
        setData(internalData)
        setLoading(false)
        return
      } else return internalData
    }
    const encodingDone =
      internalData.filter((d) => d.status === 'ENCODING_DONE').length -
      oldAmount

    if (encodingDone < amountToShow)
      await fetchVideos(
        amountToShow,
        oldAmount,
        internalData,
        nestingLevel + 1,
        filter
      ).then((d) => {
        if (d) internalData = d
      })
    if (nestingLevel === 0) {
      setData(internalData)
      setLoading(false)
    } else return internalData
  }

  const setUpVideoCount = () => {
    const current = contents.current
    const style = getComputedStyle(document.body)
    if (current) {
      const width = current.clientWidth
      const margin = Number(
        style
          .getPropertyValue('--id-tube-rich-grid-item-margin')
          .replace('px', '')
      )
      const minWidth = Number(
        style
          .getPropertyValue('--id-tube-rich-grid-item-min-width')
          .replace('px', '')
      )
      const maxWidth = Number(
        style
          .getPropertyValue('--id-tube-rich-grid-item-max-width')
          .replace('px', '')
      )
      const itemCount = Number(
        style.getPropertyValue('--id-tube-rich-grid-items-per-row')
      )
      const getItemCount = (count: number): number => {
        const nextTotalMinWidth = (margin + minWidth) * (count + 1) + margin * 2
        const nextTotalMaxWidth = (margin + maxWidth) * (count - 1) + margin * 2
        return width > nextTotalMinWidth
          ? getItemCount(count + 1)
          : width < nextTotalMaxWidth
          ? getItemCount(count - 1)
          : count
      }
      const newItemCount = getItemCount(itemCount)
      document.documentElement.style.setProperty(
        '--id-tube-rich-grid-items-per-row',
        `${newItemCount}`
      )
      if (newItemCount !== itemCount) setRefresh(true)
    }
  }

  const getItemCount = () =>
    Number(
      getComputedStyle(document.body).getPropertyValue(
        '--id-tube-rich-grid-items-per-row'
      )
    )

  const refetch = (filter: string) => {
    fetchVideos(getItemCount() * 4, 0, [], 0, filter)
  }

  useEffect(() => {
    const contentJson: Array<RowType> = []
    let items: RowType = []
    const itemCount = getItemCount()
    for (let video of data) {
      if (optionalVideoList || video.status === 'ENCODING_DONE')
        items.push({
          uuid: video.uuid,
          title: video.texts.title,
          subTitle: video.texts.subtext,
          thumbnail: video.video.first_thumbnail,
          preview: video.video.preview_path,
          status: video.status,
          visiblity: video.visibility,
        })
      if (optionalVideoList || items.length === itemCount) {
        contentJson.push(items)
        items = []
      }
    }
    if (items.length !== 0) {
      for (let i = items.length; i < itemCount; i++)
        items.push({
          uuid: '',
          title: '',
          thumbnail: '',
          dummy: true,
        })
      contentJson.push(items)
    }

    setRefresh(false)
    setRows(generateRows(contentJson))
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, refresh, optionalVideoList])

  useEffect(() => {
    setUpVideoCount()
    fetchVideos(getItemCount() * 4)
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (filter.length >= 3 || filter === '') refetch(filter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  useEffect(() => {
    const handleResize = (_: UIEvent) => {
      setUpVideoCount()
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    history.pushState(null, '', location.href)
    const handlePopState = (_: PopStateEvent) => {
      if (videoDialog) setVideoDialog(undefined)
      // eslint-disable-next-line no-restricted-globals
      else history.back()
    }

    window.addEventListener('popstate', handlePopState)
    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [videoDialog])

  const handleClose = () => {
    navigate('/videos')
  }

  const handleCloseUploadVideo = () => {
    setUploadVideoOpen(false)
  }

  return (
    <div id='contents' className='id-tube-rich-grid-renderer' ref={contents}>
      <TextField
        fullWidth
        variant='outlined'
        label='Name'
        autoComplete='new-password'
        sx={{
          marginBottom: '8px',
        }}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = event.target.value
          if (value.length > 2) setFilter(value)
          else setFilter('')
        }}
      />
      <FormControlLabel
        control={
          <Switch
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setOptionalVideoList(event.target.checked)
            }}
          />
        }
        label='Video list'
        sx={{
          color: 'white',
          marginBottom: '24px',
        }}
      />
      {loading && (
        <LinearProgress
          style={{ position: 'absolute', top: 0, left: 0, width: '100vw' }}
        />
      )}
      {loading && !rows.length ? (
        <div
          style={{
            width: '100%',
            height: '100%',
          }}>
          <CircularProgress
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            size={90}
          />
        </div>
      ) : (
        rows
      )}
      {!allLoaded && (
        <Button
          onClick={() => fetchVideos(getItemCount() * 2)}
          sx={{ mx: 'auto' }}
          disabled={loading}>
          Load more
        </Button>
      )}
      <VideoDialog
        handleClose={handleClose}
        open={!!videoDialog}
        videoDialog={videoDialog}
      />
      <UploadVideoDialog
        handleClose={handleCloseUploadVideo}
        open={uploadVideoOpen}
      />
      <AlertDialog
        title='Delete this Video?'
        contentText='Do you want to delete this Video?'
        leftButton={{
          text: 'Cancel',
        }}
        rightButton={{
          text: 'Delete',
          onClick: () => {
            deleteVideo(uuid)
          },
        }}
        onClose={() => setConfirmDelete(false)}
        open={confirmDelete}
      />
      <EditVideoDialog
        open={editVideoOpen}
        uuid={uuid}
        handleClose={(changed: boolean) => {
          if (changed) refetch(filter)
          setEditVideoOpen(false)
        }}
      />
      <Fab
        sx={{
          position: 'absolute',
          bottom: '16px',
          right: '16px',
        }}
        onClick={() => setUploadVideoOpen(true)}>
        <Add />
      </Fab>
    </div>
  )
}

export default Videos
