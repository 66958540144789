import { Done, Download, Error } from '@mui/icons-material'
import { Box, Button, CircularProgress, Fab } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import readXlsxFile from 'read-excel-file'
import { csvToXlsx, downloadAsFile, myFetch } from 'utils/utils'

const errorMapping: {
  [key: string]: keyof typeof errorMapping | string
} = {
  InvalidUsernameAndPassword: 'Ungültiger Benutzername oder Passwort',
  SessionCodeAlreadyUsed: 'Session Code bereits verwendet',
  SessionCodeNotValid: 'Session Code nicht gültig',
  TokenNotValid: 'Token nicht gültig',
  InternalServerError: 'Internal Server Error',
  CodeNotValid: 'Code nicht gültig',
  SessionCodeNotProcessed: 'Session Code nicht verarbeitet',
  VideosNotUploaded: 'Videos noch nicht hochgeladen',
  PasswordAlreadyUsed: 'Passwort bereits verwendet',
  InvalidPassword: 'Passwort nicht gültig',
  ClubAlreadyCreated: 'Club bereits erstellt',
  InvalidUsernameOrEmail:
    'Die Benutzername oder die E-Mailadresse existiert nicht',
  ConfirmUserEmailError:
    'Die Bestätigungs-E-Mail konnte nicht gesendet werden. Bitte überprüfen Sie, ob die E-Mail korrekt ist',
  ConfirmLegalGuardianEmailError:
    'Die Bestätigungs-E-Mail konnte nicht an deinen Erziehungsberechtigten gesendet werden. Bitte prüf, ob die E-Mail korrekt ist.',
  EmailTokenExpired: 'Der Link ist abgelaufen, bitte verwende einen neuen',
  EmailTokenNotExpired: 'Der Link ist nicht abgelaufen',
  EmailTokenNotFound: 'Der Link ist ungültig oder wurde bereits verwendet',
}

type Email = String

type CreateUserResponse = [
  Email,
  {
    errors:
      | [
          {
            message: keyof typeof errorMapping | string
            [key: string]: any
          }
        ]
      | undefined
    data: null
  }
][]

const CreateFromExcel = () => {
  const [users, setUsers] = useState<(string | null)[][]>()
  const [dataUsers, setDataUsers] = useState<any[]>([])
  const [response, setResponse] = useState<CreateUserResponse>([])

  const [hasCodes, setHasCodes] = useState(false)
  const [loading, setLoading] = useState(false)

  const createUsers = async (
    users: (string | null)[][],
    withCode: boolean = false
  ): Promise<CreateUserResponse> => {
    setLoading(true)
    const url = withCode
      ? '/api/signup_user/signup_and_add_code'
      : '/api/signup_user/signup'
    const res = await myFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(users),
    })
    setLoading(false)
    return res.json()
  }

  const selectFile = () => {
    setUsers(undefined)
    setResponse([])
    setHasCodes(false)

    const input = document.createElement('input')
    input.type = 'file'
    input.accept = '.xlsx'
    input.onchange = async () => {
      const files = input.files
      if (!files || !files[0]) return

      const rows = await readXlsxFile(files[0])
      const parsedRows: (string | null)[][] = rows.map((row, index) => {
        if (index > 0 && row.length === 9 && row[8]) setHasCodes(true)

        return row.map((cell) => {
          if (cell) return cell.toString()
          return ''
        })
      }) as any

      setUsers(parsedRows)
    }
    input.click()
  }

  const downloadExcel = () => {
    let csv = 'Name,Vorname,Email,Passwort,Tag,Monat,Jahr,Rolle,Code,Status,Fehler\n'
    dataUsers.forEach((row) => {
      let email = row.col3
      const generatedEmail = response[row.id]?.[0]

      if (Boolean(generatedEmail?.length)) email = generatedEmail

      csv += `${row.col1},${row.col2},${email},${row.col4},${row.col5},${row.col6},${row.col7},${row.col8},${row.col9},${row.status},${row.col11}\n`
    })

    downloadAsFile(csvToXlsx(csv), 'users.xlsx')
  }

  useEffect(() => {
    setDataUsers(
      users
        ?.filter((_, index) => index > 0)
        ?.map((user, index) => {
          return {
            id: index,
            col1: user[0],
            col2: user[1],
            col3: user[2],
            col4: user[3],
            col5: user[4],
            col6: user[5],
            col7: user[6],
            col8: user[7],
            col9: user[8],
            status: response[index]
              ? response[index][1].errors
                ? 'error'
                : 'success'
              : '',
            col11: response[index]
              ? response[index][1].errors
                  ?.map((error) => errorMapping[error.message] || error.message)
                  .join(', ')
              : '',
          }
        }) ?? []
    )
  }, [users, response])

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <Button
              disabled={loading}
              sx={{
                marginRight: '10px',
              }}
              variant='outlined'
              onClick={selectFile}>
              Select File
            </Button>
            <Button
              disabled={loading}
              variant='outlined'
              onClick={() => {
                setUsers(undefined)
                setResponse([])
                setHasCodes(false)
              }}>
              Clear
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <Button
              disabled={loading || !users}
              variant='outlined'
              color={!hasCodes ? 'success' : 'primary'}
              sx={{ marginRight: '10px' }}
              onClick={() => {
                if (!users) return
                createUsers(users).then(setResponse)
              }}>
              Create Users
            </Button>
            <Button
              disabled={loading || !hasCodes || !users}
              variant='outlined'
              color={hasCodes ? 'success' : 'primary'}
              onClick={() => {
                if (!users) return
                createUsers(users, true).then(setResponse)
              }}>
              Create Users with Code
            </Button>
          </Box>
        </Box>

        <DataGrid
          style={{
            marginTop: '15px',
            width: '100%',
            height: 'calc(100vh - 230px)',
          }}
          rows={dataUsers || []}
          rowCount={dataUsers?.length || 0}
          columns={[
            { field: 'col1', headerName: 'Name', width: 200 },
            { field: 'col2', headerName: 'Vorname', width: 200 },
            {
              field: 'col3',
              headerName: 'E-Mail',
              width: 350,
              renderCell: (params) => {
                const origMail = params.row.col3
                const generatedMail = response[params.row.id]?.[0]

                if (Boolean(origMail?.length)) return <span>{origMail}</span>
                if (Boolean(generatedMail?.length))
                  return (
                    <span
                      style={{
                        color: '#66bb6a',
                      }}>
                      {generatedMail}
                    </span>
                  )
                return <></>
              },
            },
            { field: 'col4', headerName: 'Passwort', width: 200 },
            { field: 'col5', headerName: 'Tag', width: 200 },
            { field: 'col6', headerName: 'Monat', width: 200 },
            { field: 'col7', headerName: 'Jahr', width: 200 },
            { field: 'col8', headerName: 'Rolle', width: 200 },
            { field: 'col9', headerName: 'Code', width: 200 },
            {
              field: 'col10',
              headerName: 'Status',
              width: 80,
              renderCell: (params) => {
                if (loading) return <CircularProgress size={20} />
                switch (params.row.status) {
                  case 'success':
                    return <Done color='success' />
                  case 'error':
                    return <Error color='error' />
                  default:
                    return <></>
                }
              },
            },
            {
              field: 'col11',
              headerName: 'Error',
              minWidth: 100,
              flex: 1,
            },
          ]}
        />

        <Fab
          sx={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
          }}
          onClick={downloadExcel}>
          <Download />
        </Fab>
      </Box>
    </>
  )
}

export default CreateFromExcel
