import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { Box } from '@mui/system'
import { CSSProperties, useEffect, useState } from 'react'
import { circularProgressClasses } from '@mui/material/CircularProgress'

export const Dropdown = (props: {
  label: string
  default?: { title: string; value: number }
  value?: { title: string; value: number }
  items: { title: string; value: number }[]
  style?: CSSProperties
  onSelect?: (item: { title: string; value: number } | undefined) => void
}) => {
  const {
    label,
    default: defaultItem,
    value: currentValue,
    items,
    style,
    onSelect,
  } = props

  const [value, setValue] = useState(defaultItem?.value?.toString() ?? '')

  useEffect(() => {
    if (currentValue) setValue(currentValue.value.toString())
  }, [currentValue])

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string)
    onSelect?.(items.find((i) => i.value === Number(event.target.value)))
  }

  return (
    <Box sx={{ minWidth: 120 }} style={style}>
      <FormControl fullWidth>
        <InputLabel id='dropdown-label'>{label}</InputLabel>
        <Select
          labelId='dropdown-label'
          id='dropdown'
          value={value}
          label={label}
          onChange={handleChange}>
          {items.map((item) => (
            <MenuItem value={item.value}>{item.title}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export const CircularProgressAnimation = (props: {
  animate: boolean
  maxValue: number
  duration: number
  size: string | number
}) => {
  const { animate, maxValue, size } = props
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (animate) {
      setTimeout(() => setProgress(maxValue))
    }
  }, [animate, maxValue])

  return (
    <>
      <CircularProgress
        variant='determinate'
        value={100}
        sx={{
          position: 'absolute',
          top: 0,
          color: '#202020',
        }}
        size={size}
        thickness={2}
      />

      <CircularProgress
        variant='determinate'
        value={progress}
        sx={{
          position: 'absolute',
          top: 0,
          [`& .${circularProgressClasses.circleDeterminate}`]: {
            stroke: 'url(#circularGradient)',
            transition: `stroke-dashoffset ${props.duration}ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
          },
        }}
        size={size}
        thickness={2}
      />
    </>
  )
}
