import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import QueuePlayerZoom from './QueuePlayerZoom'
import QueueVision from './QueuesVision'

const tabNames = ['vision', 'playerzoom']
type TabType = typeof tabNames[number]

const Queues = () => {
  const { tab } = useParams()

  const navigate = useNavigate()

  const [value, setValue] = useState<TabType>('vision')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate('/queues/' + newValue)
  }

  useEffect(() => {
    if (tab && tabNames.includes(tab)) setValue(tab as TabType)
  }, [tab])

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab key={'tab_queue_1'} label='Vision' value='vision' />
            <Tab key={'tab_queue_2'} label='Player Zoom' value='playerzoom' />
          </TabList>
        </Box>
        <Box
          sx={{
            width: '100%',
            flexGrow: '1',
            maxWidth: '100%',
            maxHeight: '100%',
            overflow: 'auto',
          }}>
          <TabPanel key={'tab_panel_queue_1'} value='vision'>
            <QueueVision />
          </TabPanel>
          <TabPanel key={'tab_panel_queue_2'} value='playerzoom'>
            <QueuePlayerZoom />
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  )
}

export default Queues
