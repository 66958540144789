import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { AsyncSearchField, DefaultTextInput } from 'components/Components'
import { useEffect, useState } from 'react'
import { AgeGroups, AgeGroupsType, Club } from 'types/types'
import { csvToXlsx, downloadAsFile, myFetch } from 'utils/utils'

const CreateOperatorSession = () => {
  const [loginError, setLoginError] = useState(false)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<{
    email?: string
    password?: string
    playerCount?: number
    trainerCount?: number
    ageGroup?: string
    club?: Club
    fixedGoalkeeper?: boolean
  }>({
    playerCount: 0,
    trainerCount: 0,
    fixedGoalkeeper: false,
  })

  const [createResponse, setCreateResponse] = useState<{ [key: string]: any }>(
    {}
  )

  const [mappedData, setMappedData] = useState<
    {
      sessionNumber: number
      type: 'Player' | 'Trainer'
      sessionId: number
      code: string
    }[]
  >([])

  const fetchClubs = async (
    filter: string = '',
    rowsPerPage: number = 10,
    needs_approval: boolean = false
  ): Promise<Club[]> => {
    return await myFetch(
      `/api/clubs/list_clubs?limit=${rowsPerPage}&offset=0&needs_approval=${needs_approval}${
        filter.length > 2 ? `&name=${filter}` : ''
      }`
    ).then((body) => body.json())
  }

  const createSession = async () => {
    const body = {
      operator_email: data.email,
      operator_password: data.password,
      player_count: Number(data.playerCount),
      trainer_count: Number(data.trainerCount),
      age_group: data.ageGroup,
      club_id: data.club?.id,
      fixed_goalkeeper: data.fixedGoalkeeper,
    }

    setLoading(true)
    setLoginError(false)
    setError('')
    setCreateResponse({})

    const res = await myFetch(`/api/sessions/create_portal_session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })

    const json = await res.json()

    setLoading(false)

    if (res.status === 200) {
      setCreateResponse(json)
    } else {
      const errors = json.errors?.map((error: any) => error.message)

      if (errors?.includes('InvalidUsernameAndPassword')) setLoginError(true)
      else setError(errors?.join('\n') ?? 'Unknown error')
    }
  }

  useEffect(() => {
    const sessionNumber =
      createResponse?.response?.data?.createOperatorSession?.sessionNumber

    if (sessionNumber) {
      const mapped: {
        sessionNumber: number
        type: 'Player' | 'Trainer'
        sessionId: number
        code: string
      }[] = []

      for (let i = 0; i < createResponse.playerCodes.length; i++) {
        mapped.push({
          sessionNumber,
          type: 'Player',
          sessionId: i + 1,
          code: createResponse.playerCodes[i],
        })
      }

      for (let i = 0; i < createResponse.trainerCodes.length; i++) {
        mapped.push({
          sessionNumber,
          type: 'Trainer',
          sessionId: i + 1,
          code: createResponse.trainerCodes[i],
        })
      }

      setMappedData(mapped)
    } else setMappedData([])
  }, [createResponse])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'end',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}>
        <DefaultTextInput
          sx={{
            flex: 0.5,
            minWidth: '150px',
            marginRight: '8px',
          }}
          name='email'
          label='Email'
          type='text'
          state={[data, setData]}
          error={data.email === undefined || loginError}
          errorMessage='Invalid username or password'
        />
        <DefaultTextInput
          sx={{
            flex: 0.5,
            minWidth: '150px',
          }}
          name='password'
          label='Password'
          type='password'
          state={[data, setData]}
          error={data.password === undefined || loginError}
          errorMessage='Invalid username or password'
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}>
        <DefaultTextInput
          sx={{
            flex: 0.5,
            minWidth: '150px',
            marginRight: '8px',
          }}
          name='playerCount'
          label='Player Count'
          type='number'
          state={[data, setData]}
          error={data.playerCount === undefined || data.playerCount < 10}
          errorMessage='Invalid player count (min 10)'
        />
        <DefaultTextInput
          sx={{
            flex: 0.5,
            minWidth: '150px',
          }}
          name='trainerCount'
          label='Trainer Count'
          type='number'
          state={[data, setData]}
          error={data.trainerCount === undefined}
          errorMessage='Invalid trainer count'
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}>
        <AsyncSearchField
          sx={{
            flex: 0.5,
            minWidth: '150px',
            marginRight: '8px',
          }}
          label='Age Group'
          getLabel={(item: AgeGroupsType) => item}
          onType={async (value: string) =>
            AgeGroups.filter((ageGroup) =>
              ageGroup.toUpperCase().includes(value.toUpperCase())
            )
          }
          onSelect={(ageGroup: AgeGroupsType) => {
            if (ageGroup && ageGroup !== '')
              setData({ ...data, ageGroup: ageGroup })
            else setData({ ...data, ageGroup: undefined })
          }}
          error={data.ageGroup === undefined}
          errorMessage='Invalid age group'
        />
        <AsyncSearchField
          sx={{
            flex: 0.5,
            minWidth: '150px',
          }}
          label='Club'
          getLabel={(item: Club) => item.name}
          onType={async (value: string) =>
            await fetchClubs(value, 10, false).then((data) => data)
          }
          onSelect={(club: Club) => {
            if (club) setData({ ...data, club: club })
            else setData({ ...data, club: undefined })
          }}
          error={data.club === undefined}
          errorMessage='Invalid club'
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          marginTop: '8px',
        }}>
        <Button
          sx={{
            flex: 0.08,
            minWidth: '180px',
          }}
          variant='outlined'
          disabled={mappedData.length === 0}
          onClick={() => {
            let csv = 'Session Number,Type,Session ID,Code\n'
            const sessionNumber = mappedData[0].sessionNumber
            for (let i = 0; i < mappedData.length; i++) {
              const row = mappedData[i]
              csv += `${row.sessionNumber},${row.type},${row.sessionId},${row.code}\n`
            }
            downloadAsFile(
              csvToXlsx(csv),
              `Session_${sessionNumber}_${data.club?.name}_${data.ageGroup}.xlsx`
            )
          }}>
          Download EXCEL
        </Button>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}>
          <FormControlLabel
            sx={{
              flex: 0.1,
              minWidth: '180px',
              color: 'text.primary',
            }}
            onChange={(e) => {
              const target = e.target as HTMLInputElement
              setData({ ...data, fixedGoalkeeper: target.checked })
            }}
            value={data.fixedGoalkeeper}
            control={<Checkbox />}
            label='Fixed Goalkeeper'
          />
          <Button
            sx={{
              flex: 0.08,
              minWidth: loading ? '180px' : '150px',
            }}
            variant='outlined'
            onClick={createSession}
            disabled={
              data.email === undefined ||
              data.password === undefined ||
              data.ageGroup === undefined ||
              data.club === undefined ||
              data.playerCount === undefined ||
              data.trainerCount === undefined ||
              data.playerCount < 10 ||
              loading
            }>
            Create Session{' '}
            {loading && (
              <CircularProgress size={'15px'} sx={{ marginLeft: '15px' }} />
            )}
          </Button>
        </Box>
      </Box>
      <Typography
        sx={{
          marginTop: '15px',
          color: 'error.main',
        }}
        variant='body1'>
        &nbsp;{error || ''}
      </Typography>

      <Box
        sx={{
          width: '100%',
        }}>
        <DataGrid
          sx={{
            height: 'calc(100vh - 450px)',
            marginTop: '15px',
          }}
          rows={mappedData}
          getRowId={(row) =>
            row.sessionNumber.toString() +
            row.type +
            row.sessionId.toString() +
            row.code
          }
          columns={[
            {
              field: 'sessionNumber',
              headerName: 'Session Number',
              width: 70,
              flex: 0.2,
            },
            { field: 'type', headerName: 'Type', minWidth: 70, flex: 0.3 },
            {
              field: 'sessionId',
              headerName: 'Session Id',
              width: 150,
              flex: 0.3,
            },
            { field: 'code', headerName: 'Code', width: 150, flex: 0.4 },
          ]}
        />
      </Box>
    </Box>
  )
}

export default CreateOperatorSession
