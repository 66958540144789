import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Typography,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { DefaultTextInput } from 'components/Components'
import { useEffect, useState } from 'react'
import { VideoDownload } from 'types/types'
import { bytesToHuman, downloadUrlAsFile, myFetch } from 'utils/utils'

type DownloadParameters = {
  session_number?: string
  player_session_id?: string
  ttl?: number
}

/**
 * DownloadProcess
 * 
 * this class is used to manage the download process and also build download button and status
 * 
 * to use it, you need to create a new instance of this class
 * to download a file you need to call the download method
 * to get the download button you need to call the getDownloadButton method
 * to get the download status you need to call the getDownloadStatus method
 * 
 * to get the download progress you need to add a listener to the download process
 * the listener will be called every time the download progress, countDown or loading status change
 * 
 * @class
 * @classdesc DownloadProcess
 */
class DownloadProcess {
  private _initValues = {
    session_number: 0,
    player_session_id: 0,
    match_number: 0,
    ttl: 0,
    url: '',
  }

  private _progress: number = 0
  private _loading: boolean = false
  private _countDown: number = 0
  private _downloadUrl: string = ''
  private _downloadName: string = ''
  private _downloadParameters: DownloadParameters = {}
  private _listeners: ((downloadProcess: DownloadProcess) => void)[] = []

  private _totalBytes: number = 0
  private _currentBytes: number = 0

  private _xhr: XMLHttpRequest | null = null

  private _startTimestamp: number = 0

  constructor(
    public session_number: number,
    public player_session_id: number,
    public match_number: number,
    public ttl: number,
    public url: string
  ) {
    this._countDown = ttl
    this._downloadUrl = url
    this._downloadName = `${session_number}_${match_number}_${player_session_id}.mp4`
    this._downloadParameters = {
      session_number: session_number.toString(),
      player_session_id: player_session_id.toString(),
      ttl,
    }

    this._initValues = {
      session_number,
      player_session_id,
      match_number,
      ttl,
      url,
    }

    this.start()
  }

  get progress() {
    return this._progress
  }

  get loading() {
    return this._loading
  }

  get countDown() {
    return this._countDown
  }

  get downloadUrl() {
    return this._downloadUrl
  }

  get downloadName() {
    return this._downloadName
  }

  get downloadParameters() {
    return this._downloadParameters
  }

  get startTimestamp() {
    return this._startTimestamp
  }

  get eta() {
    const progress = this.progress / 100
    const elapsed = Date.now() - this.startTimestamp
    const eta = (elapsed / progress - elapsed) / 1000

    const hours = Math.floor(eta / 3600)
    const minutes = Math.floor((eta - hours * 3600) / 60)
    const seconds = Math.floor(eta - hours * 3600 - minutes * 60)

    if (hours === 0 && minutes === 0) return `${seconds}s`
    if (hours === 0) return `${minutes}m ${seconds}s`
    return `${hours}h ${minutes}m ${seconds}s`
  }

  get totalBytes() {
    return this._totalBytes
  }

  get currentBytes() {
    return this._currentBytes
  }

  get speed() {
    const elapsed = Date.now() - this.startTimestamp
    const speed = this.currentBytes / elapsed
    return `${(speed / 1000).toFixed(2)} MB/s`
  }

  setProgress(progress: number) {
    this._progress = progress
  }

  setLoading(loading: boolean) {
    this._loading = loading

    this.invokeListeners()
  }

  setCountDown(countDown: number) {
    this._countDown = countDown
  }

  setDownloadUrl(downloadUrl: string) {
    this._downloadUrl = downloadUrl
  }

  setDownloadName(downloadName: string) {
    this._downloadName = downloadName
  }

  setDownloadParameters(downloadParameters: DownloadParameters) {
    this._downloadParameters = downloadParameters
  }

  download = async () => {
    this.setLoading(true)
    this._startTimestamp = Date.now()
    await downloadUrlAsFile(
      this.downloadUrl,
      this.downloadName,
      (xhr) => {
        this._xhr = xhr
      },
      this.handleProgress
    ).catch(() => null)
    this.reset()
  }

  cancel = () => {
    if (this._xhr) this._xhr.abort()
  }

  handleProgress = (progress: number, total: number, current: number) => {
    if (progress > 100) this.setProgress(0)
    else this.setProgress(parseFloat(progress.toFixed(2)))

    this._totalBytes = total
    this._currentBytes = current

    this.invokeListeners()
  }

  tick = () => {
    if (this.countDown <= 0) return
    this.setCountDown(this.countDown - 1)

    setTimeout(this.tick, 1000)

    this.invokeListeners()
  }

  start = () => {
    this.tick()
  }

  stop = () => {
    this.setCountDown(0)
  }

  reset = () => {
    this._countDown = this._initValues.ttl
    this._downloadUrl = this._initValues.url
    this._downloadName = `${this._initValues.session_number}_${this._initValues.match_number}_${this._initValues.player_session_id}.mp4`
    this._progress = 0
    this.setLoading(false)
  }

  invokeListeners = () => {
    this._listeners.forEach((listener) => listener(this))
  }

  getDownloadButton = () => {
    return (
      <DownloadButton
        session_number={this.session_number}
        player_session_id={this.player_session_id}
        match_number={this.match_number}
        ttl={this.ttl}
        url={this.url}
        onDownload={this.download}
      />
    )
  }

  getDownloadStatsComponent = () => {
    return <DownloadStats initDownloadProcess={this} />
  }

  addEventListener = (listener: (downloadProcess: DownloadProcess) => void) => {
    this._listeners.push(listener)
  }

  clearListeners = () => {
    this._listeners = []
  }
}

function DownloadStats({
  initDownloadProcess,
}: {
  initDownloadProcess: DownloadProcess
}) {
  const [downloadProcess, setDownloadProcess] =
    useState<DownloadProcess>(initDownloadProcess)

  const [progress, setProgress] = useState(0)

  downloadProcess.clearListeners()
  downloadProcess.addEventListener((downloadProcess) => {
    setProgress(downloadProcess.progress)

    setDownloadProcess(downloadProcess)
  })

  if (!downloadProcess) return null
  if (downloadProcess.countDown <= 0) return null
  if (downloadProcess.progress >= 100) return null
  if (!downloadProcess.loading) return null

  return (
    <Card
      sx={{
        marginTop: '10px',
        marginBottom: '10px',
        height: 'max-content',
      }}
      variant='outlined'>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Typography color='textSecondary'>
              {downloadProcess.downloadName} - {downloadProcess.speed}
            </Typography>
            <Typography color='textSecondary'>
              {downloadProcess.progress.toFixed(2)}% - {downloadProcess.eta}
            </Typography>
            <Typography color='textSecondary'>
              {bytesToHuman(downloadProcess.currentBytes)} /{' '}
              {bytesToHuman(downloadProcess.totalBytes)}
            </Typography>
          </Box>
          <Button
            sx={{
              marginTop: '10px',
            }}
            variant='outlined'
            onClick={downloadProcess.cancel}>
            Cancel
          </Button>
        </Box>
      </CardContent>
      <LinearProgress variant='determinate' value={progress} />
    </Card>
  )
}

function DownloadButton({
  session_number,
  player_session_id,
  match_number,
  onDownload,
  ...props
}: {
  ttl: number
  url: string
  session_number: number
  player_session_id: number
  match_number: number
  onDownload?: () => Promise<void>
}) {
  const ttl = props.ttl || 12000
  const [countDown, setCountDown] = useState(ttl)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (countDown <= 0) return
    const timer = setTimeout(() => {
      setCountDown(countDown - 1)
    }, 1000)
    return () => clearTimeout(timer)
  }, [countDown])

  const download = async (event: React.MouseEvent) => {
    event.stopPropagation()
    setLoading(true)
    await onDownload?.()
    setLoading(false)
  }

  return (
    <Button
      disabled={!(countDown > 0) || loading}
      onClick={download}
      variant='contained'>
      Download
    </Button>
  )
}

function DownloadVideos() {
  const [loading, setLoading] = useState(false)
  const [downloadParameters, setDownloadParameters] =
    useState<DownloadParameters>({ ttl: 12000 })

  const [downloads, setDownloads] = useState<
    (VideoDownload & { process: DownloadProcess })[]
  >([])

  const getDownloads = async () => {
    const { session_number, player_session_id, ttl } = downloadParameters
    if (session_number && player_session_id) {
      setLoading(true)
      const response = await myFetch('/api/player_video_downloader', {
        headers: {
          'Content-Type': 'application/json',
        },
        query: {
          session_number,
          player_session_id,
          ttl: ttl || 12000,
        },
      }).catch(() => null)

      if (response?.ok) {
        const data = await response.json().catch(() => [])
        setDownloads(
          data.map((download: VideoDownload) => ({
            ...download,
            ttl: ttl || 12000,
            process: new DownloadProcess(
              download.session_number,
              download.player_session_id,
              download.match_number,
              ttl || 12000,
              download.url
            ),
          }))
        )
      }
      setLoading(false)
    }
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}>
        <DefaultTextInput
          sx={{
            flex: 0.5,
            minWidth: '150px',
            marginRight: '5px',
          }}
          state={[downloadParameters, setDownloadParameters]}
          label='Session Number'
          name='session_number'
          type='number'
        />
        <DefaultTextInput
          sx={{
            flex: 0.5,
            minWidth: '150px',
            marginLeft: '5px',
            marginRight: '5px',
          }}
          state={[downloadParameters, setDownloadParameters]}
          label='Player Session ID'
          name='player_session_id'
          type='number'
        />
        <DefaultTextInput
          sx={{
            flex: 0.08,
            minWidth: '150px',
            marginLeft: '5px',
            marginRight: '5px',
          }}
          state={[downloadParameters, setDownloadParameters]}
          label='TTL'
          name='ttl'
          type='number'
        />
        <Button
          sx={{
            flex: 0.06,
            minWidth: '170px',
            marginLeft: '5px',
            marginTop: '8px',
            marginBottom: '8px',
          }}
          disabled={
            loading ||
            !downloadParameters.session_number ||
            !downloadParameters.player_session_id
          }
          variant='outlined'
          onClick={getDownloads}>
          Get Videos
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 210px)',
          marginBottom: '10px',
        }}>
        <Box
          sx={{
            maxHeight: '33%',
            overflowY: 'auto',
          }}>
          {downloads.map((download) =>
            download.process.getDownloadStatsComponent()
          )}
        </Box>
        <Box
          sx={{
            marginTop: '10px',
            flex: 1,
          }}>
          <DataGrid
            sx={{
              height: '100%',
            }}
            loading={loading}
            rows={downloads.map((download) => ({
              id: `${download.session_number}_${download.match_number}_${download.player_session_id}`,
              match_number: download.match_number,
              url: download.url,
              player_session_id: download.player_session_id,
              session_number: download.session_number,
              ttl: download.ttl,
              process: download.process,
            }))}
            rowSelection={false}
            columns={[
              {
                field: 'match_number',
                headerName: 'Match Number',
                flex: 0.04,
                minWidth: 120,
              },
              { field: 'url', headerName: 'URL', flex: 0.6 },
              {
                field: 'download',
                headerName: 'Download',
                flex: 0.04,
                minWidth: 180,
                renderCell: (params) => {
                  const row = params.row as VideoDownload & {
                    process: DownloadProcess
                  }
                  return row.process.getDownloadButton()
                },
              },
            ]}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default DownloadVideos
