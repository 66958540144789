import React, { ClassAttributes, HTMLAttributes } from 'react'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import App from './App'
import './index.css'

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'id-tube-details': IdTubeAttributes
      'id-tube-thumbnail': IdTubeAttributes
      'id-tube-simple-row': IdTubeAttributes
      'id-tube-rich-grid-row': IdTubeAttributes
      'id-tube-rich-grid-media': IdTubeAttributes
      'id-tube-rich-item-renderer': IdTubeAttributes
      'id-tube-simple-item-renderer': IdTubeAttributes
    }

    interface IdTubeAttributes extends HTMLAttributes<{}>, ClassAttributes<{}> {
      children?: JSX.Element | JSX.Element[]
      class?: string
      key?: string
    }
  }
  interface Window {
    env: any
  }
}

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
