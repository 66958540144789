import { ContentCopy, Add } from '@mui/icons-material'
import {
  Alert,
  Dialog,
  Fab,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { PortalUser, TabProps } from 'types'
import { myFetch, copyToClipboard } from 'utils'
import { Loading, Error } from 'components'
import FetchSimpleuserList from './FetchSimpleuserList'

const Users = (
  props: TabProps & {
    buttons?: (user: PortalUser, loadData?: () => void) => JSX.Element[]
    maxHeight?: number | string
    background?: string
    add?: boolean
  } = { add: false }
) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [data, setData] = useState<PortalUser[]>([])
  const [addUserOpen, setAddUserOpen] = useState(false)

  const alert = useRef<HTMLDivElement | null>(null)
  const infoAlert = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    props.loadData?.({
      setError,
      setLoading,
      setData,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const UserRow = (props: {
    user: PortalUser
    buttons?: (user: PortalUser, loadData?: () => void) => JSX.Element[]
    loadData?: () => void
  }) => {
    const switchRef = useRef<HTMLInputElement | null>(null)

    return (
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell component='th' scope='row'>
          {props.user.email}
        </TableCell>
        <TableCell>
          {props.user.firstName} {props.user.lastName}
        </TableCell>
        <TableCell>
          <Switch
            inputRef={switchRef}
            defaultChecked={props.user.enabled}
            onChange={async (_, checked) => {
              const current = switchRef.current
              if (current) {
                current.disabled = true
                await myFetch(
                  `/api/admin/user_enabled?user_id=${props.user.id}&enabled=${checked}`,
                  {
                    method: 'PUT',
                  }
                ).then((body) => {
                  const alertRef = alert.current
                  if (body.status !== 200) {
                    current.checked = !checked
                    if (alertRef) {
                      const message =
                        alertRef.querySelector<HTMLDivElement>(
                          '.MuiAlert-message'
                        )
                      if (message) message.innerText = body.statusText
                      alertRef.style.display = ''
                    }
                  } else if (alertRef) alertRef.style.display = 'none'
                })
                current.disabled = false
              }
            }}
          />
        </TableCell>
        <TableCell align='right'>
          {props.buttons?.(props.user, props.loadData)}
          <Tooltip title='Copy JSON'>
            <IconButton
              onClick={() => {
                copyToClipboard(JSON.stringify(props.user, null, 2))
                const alertRef = infoAlert.current
                if (alertRef) {
                  const message =
                    alertRef.querySelector<HTMLDivElement>('.MuiAlert-message')
                  if (message) message.innerText = 'Copied to clipboard.'
                  alertRef.style.display = ''
                  setTimeout(() => {
                    alertRef.style.display = 'none'
                  }, 3000)
                }
              }}>
              <ContentCopy />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <>
      {loading && <Loading />}
      {error && <Error message='Error loading data!' />}
      {data && !error && (
        <>
          <TableContainer
            component={Paper}
            sx={{
              background: '#FFFFFF15',
              overflow: 'auto',
              maxHeight: props.maxHeight,
              '& .MuiTableCell-stickyHeader': {
                background: props.background,
              },
              '::-webkit-scrollbar': {
                width: '5px',
                height: '5px',
              },
              '::-webkit-scrollbar-track': {
                background: 'transparent',
              },
              '::-webkit-scrollbar-thumb': {
                background: props.background,
              },
              '::-webkit-scrollbar-thumb:hover': {
                background: props.background,
              },
            }}>
            <Table sx={{ minWidth: 650 }} stickyHeader={!!props.maxHeight}>
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Enabled</TableCell>
                  <TableCell align='right' />
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((user) => (
                  <UserRow
                    key={user.id}
                    user={user}
                    buttons={props.buttons}
                    loadData={() =>
                      props.loadData?.({
                        setError,
                        setLoading,
                        setData,
                      })
                    }
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Alert
            ref={alert}
            style={{ display: 'none' }}
            sx={{
              position: 'absolute',
              bottom: 10,
              right: 10,
            }}
            severity='error'
            elevation={6}
            variant='filled'
          />
          <Alert
            ref={infoAlert}
            style={{ display: 'none' }}
            sx={{
              position: 'absolute',
              bottom: 10,
              right: 10,
            }}
            severity='info'
            elevation={6}
            variant='filled'
          />
        </>
      )}
      <Dialog
        maxWidth='xl'
        open={addUserOpen}
        onClose={() => setAddUserOpen(false)}
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '8px',
            background: '#2D2D2D',
            '::-webkit-scrollbar': {
              width: '5px',
              height: '5px',
            },
            '::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '::-webkit-scrollbar-thumb': {
              background: '#2D2D2D',
              borderRadius: '8px',
            },
            '::-webkit-scrollbar-thumb:hover': {
              background: '#2D2D2D',
            },
          },
        }}>
        <FetchSimpleuserList
          usersInGroup={data}
          handleClose={() => setAddUserOpen(false)}
          customFetch={(filter) =>
            myFetch(`/api/admin/find_user?email=${filter}`).then((body) =>
              body.json()
            )
          }
          customClickEvent={async (user) => {
            await myFetch(`/api/admin/add_user_to_portal?user_id=${user.id}`, {
              method: 'PUT',
            }).then((body) => {
              const alertRef = alert?.current
              if (body.status !== 200) {
                if (alertRef) {
                  const message =
                    alertRef.querySelector<HTMLDivElement>('.MuiAlert-message')
                  if (message) message.innerText = body.statusText
                  alertRef.style.display = ''
                }
              } else if (alertRef) alertRef.style.display = 'none'
              setTimeout(() => {
                if (alertRef) alertRef.style.display = 'none'
              }, 3000)
            })
            props.loadData?.({
              setError,
              setLoading,
              setData,
            })
          }}
          alert={alert}
        />
      </Dialog>
      {props.add && (
        <Fab
          sx={{
            position: 'absolute',
            bottom: 16,
            right: 16,
          }}
          onClick={async () => {
            setAddUserOpen(true)
          }}>
          <Add />
        </Fab>
      )}
    </>
  )
}

export default Users
