import { ChangeEvent, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import ReactJson from 'react-json-view'
import { format, parseISO } from 'date-fns'
import { Mail } from 'types'
import { deepSearch, myFetch } from 'utils'
import { TablePaginationActions, Loading, AlertDialog } from 'components'

const Mails = () => {
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [rows, setRows] = useState<Mail[]>([])
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [count, setCount] = useState(Math.max(page, 1) * rowsPerPage + 1)
  const [bounceNotification, setBounceNotification] = useState({})

  const emptyRows = rowsPerPage - rows.length

  const fetchRows = async (
    page: number = 0,
    rowsPerPage: number = 5,
    filter: string = ''
  ) => {
    setLoading(true)
    await myFetch(
      `/api/mails?limit=${rowsPerPage}&offset=${page * rowsPerPage}${
        filter.length ? `&email=${filter}` : ''
      }`
    )
      .then((body) => {
        if (body.status === 200) return body.json()
        else {
          const filtered = rows.filter(
            (row) =>
              row.sender_mail.startsWith(filter) ||
              row.receiver_mail.startsWith(filter)
          )
          return { list: filtered, total: filtered.length }
        }
      })
      .then((data: { list: Mail[]; total: number }) => {
        setRows(data.list)
        setCount(data.total)
        if (page === 0 && data.list.length <= 10 && data.list.length > 5)
          setRowsPerPage(10)
        else if (page === 0 && data.list.length <= 5) setRowsPerPage(5)
      })
    setLoading(false)
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    fetchRows(page, rowsPerPage, filter)

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, filter])

  const Row = (props: { mail: Mail }) => {
    const [open, setOpen] = useState(false)

    return (
      <>
        <TableRow
          sx={{
            '& > .MuiTableCell-body': { borderBottom: 'unset' },
            cursor: 'pointer',
          }}
          onClick={() => setOpen(!open)}>
          <TableCell align='left'>{props.mail.receiver_mail}</TableCell>
          <TableCell align='left'>{props.mail.sender_mail}</TableCell>
          <TableCell align='left'>
            <Button
              variant='text'
              style={{
                color:
                  props.mail.status.toLocaleLowerCase().trim() === 'delivery'
                    ? '#00FF00'
                    : '#F10000',
              }}
              onClick={
                props.mail.status.toLocaleLowerCase().trim() === 'bounce'
                  ? () => {
                      setBounceNotification(
                        deepSearch(props.mail, 'bounce', () => true)
                      )
                      setDialogOpen(true)
                    }
                  : undefined
              }>
              {props.mail.status}
            </Button>
          </TableCell>
          <TableCell align='right'>{props.mail.subject}</TableCell>
          <TableCell align='right'>
            {format(parseISO(props.mail.created_at), 'dd.MM.yyyy HH:mm:ss')}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant='h6' gutterBottom component='div'>
                  JSON
                </Typography>
                <ReactJson
                  src={props.mail}
                  theme='ocean'
                  name={false}
                  style={{ background: 'transparent' }}
                  collapsed={1}
                />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  }

  return (
    <>
      <TextField
        fullWidth
        variant='outlined'
        label='Name'
        autoComplete='new-password'
        sx={{
          marginBottom: '24px',
        }}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = event.target.value
          if (value.length > 2) setFilter(value)
          else setFilter('')
        }}
      />
      <TableContainer
        component={Paper}
        sx={{
          filter: loading ? 'contrast(.7) brightness(0.7)' : '',
          position: 'relative',
        }}>
        {loading && <Loading />}
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Receiver</TableCell>
              <TableCell align='left'>Sender</TableCell>
              <TableCell align='left'>Status</TableCell>
              <TableCell align='right'>Subject</TableCell>
              <TableCell align='right'>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row mail={row} key={row.message_id} />
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                colSpan={5}
                rowsPerPage={rowsPerPage}
                count={count}
                page={page}
                SelectProps={{ native: true }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <AlertDialog
        title='Bounce notification'
        content={
          <ReactJson
            src={bounceNotification}
            theme='ocean'
            name={false}
            style={{
              background: 'transparent',
              minWidth: '500px',
              minHeight: '500px',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          />
        }
        rightButton={{
          text: 'OK',
        }}
        onClose={() => setDialogOpen(false)}
        open={dialogOpen}
      />
    </>
  )
}

export default Mails
