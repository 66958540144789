import MuiAppBar from '@mui/material/AppBar'
import { Button, ButtonProps, styled } from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const drawerWidth = 240

export const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open?: boolean }>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  overflowY: 'scroll',
  background: theme.palette.background.default,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${drawerWidth}px`,
  }),
}))

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open?: boolean }>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

export const MenuButton = (
  props: {
    href?: string
    onClick?: React.MouseEventHandler<HTMLAnchorElement>
    children: any
    icon: React.ReactNode
  } & ButtonProps
) => {
  const { onClick, href, icon, children, ...rest } = props

  const navigate = useNavigate()

  return (
    <Button
      onClick={(event) => {
        event.stopPropagation()
        if (onClick) onClick(event)
        else if (href) navigate(href)
      }}
      sx={{
        width: '100%',
        justifyContent: 'space-between',
      }}
      startIcon={icon}
      {...rest}>
      {children}
    </Button>
  )
}

export const Title = (props: { title: string }) => {
  useEffect(() => {
    document.title = props.title
  }, [props.title])
  return <>{props.title}</>
}
