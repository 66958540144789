import {
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TablePagination,
  Button,
} from '@mui/material'
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions'
import Loading from 'components/Loading'
import { useState, useEffect } from 'react'
import { copyToClipboard, myFetch } from 'utils/utils'
import { QueueVision as QueueVisionType, QueueVisionStatus } from 'types/types'

const colors: { [key: QueueVisionStatus]: string } = {
  WAITING: '#D3B638',
  STARTING: '#ACC732',
  RUNNING: '#3BD648',
  FAILED_RUN: '#CE2828',
  DONE: '#28D428',
}

const QueueVision = () => {
  const [rows, setRows] = useState<QueueVisionType[]>([])
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [queueCount, setQueueCount] = useState(0)
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)

  const emptyRows = rowsPerPage - rows.length

  const fetchDataVision = async (
    rowsPerPage: number = 20,
    page: number = 0
  ) => {
    setLoading(true)
    await myFetch(
      `/api/queues/vision?limit=${rowsPerPage}&skip=${page * rowsPerPage}`
    )
      .then((body) => body.json())
      .then((data: QueueVisionType[]) => {
        setRows(data)
        setQueueCount((page + 1) * rowsPerPage + 1)
      })
    setLoading(false)
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    fetchDataVision(rowsPerPage, page)

    return () => {}
  }, [rowsPerPage, page])

  const Row = (props: { queueVision: QueueVisionType }) => {
    const { queueVision } = props

    return (
      <>
        <TableRow>
          <TableCell align='left'>{queueVision.match_number}</TableCell>
          <TableCell align='left'>{queueVision.session_number}</TableCell>
          <TableCell align='left'>
            {queueVision.current_details.trim().startsWith('Traceback') ? (
              <Button
                onClick={() => copyToClipboard(queueVision.current_details)}>
                Copy error
              </Button>
            ) : (
              queueVision.current_details
            )}
          </TableCell>
          <TableCell align='left' sx={{ color: colors[queueVision.status] }}>
            {queueVision.status}
          </TableCell>
          <TableCell align='left'>{queueVision.worker}</TableCell>
          <TableCell align='right'>
            {new Date(queueVision.created_at).toLocaleDateString('en-US', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false,
            })}
          </TableCell>
          <TableCell align='right'>
            {new Date(queueVision.updated_at).toLocaleDateString('en-US', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false,
            })}
          </TableCell>
        </TableRow>
      </>
    )
  }

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          filter: loading ? 'contrast(.7) brightness(0.7)' : '',
          position: 'relative',
          transition: '200ms',
        }}>
        {loading && <Loading />}
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Match Number</TableCell>
              <TableCell align='left'>Session Number</TableCell>
              <TableCell align='left'>Current Details</TableCell>
              <TableCell align='left'>Status</TableCell>
              <TableCell align='left'>Worker</TableCell>
              <TableCell align='right'>Created At</TableCell>
              <TableCell align='right'>Updated At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <Row queueVision={row} key={index} />
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 69 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                colSpan={7}
                rowsPerPage={rowsPerPage}
                count={queueCount}
                page={page}
                SelectProps={{ native: true }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  )
}

export default QueueVision
