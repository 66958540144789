export const codes = [
    'AFG', 'ALA', 'ALB', 'DZA', 'ASM', 'AND',
    'AGO', 'AIA', 'ATA', 'ATG', 'ARG', 'ARM',
    'ABW', 'AUS', 'AUT', 'AZE', 'BHS', 'BHR',
    'BGD', 'BRB', 'BLR', 'BEL', 'BLZ', 'BEN',
    'BMU', 'BTN', 'BOL', 'BES', 'BIH', 'BWA',
    'BVT', 'BRA', 'IOT', 'BRN', 'BGR', 'BFA',
    'BDI', 'CPV', 'KHM', 'CMR', 'CAN', 'CYM',
    'CAF', 'TCD', 'CHL', 'CHN', 'CXR', 'CCK',
    'COL', 'COM', 'COG', 'COD', 'COK', 'CRI',
    'CIV', 'HRV', 'CUB', 'CUW', 'CYP', 'CZE',
    'DNK', 'DJI', 'DMA', 'DOM', 'ECU', 'EGY',
    'SLV', 'GNQ', 'ERI', 'EST', 'SWZ', 'ETH',
    'FLK', 'FRO', 'FJI', 'FIN', 'FRA', 'GUF',
    'PYF', 'ATF', 'GAB', 'GMB', 'GEO', 'DEU',
    'GHA', 'GIB', 'GRC', 'GRL', 'GRD', 'GLP',
    'GUM', 'GTM', 'GGY', 'GIN', 'GNB', 'GUY',
    'HTI', 'HMD', 'VAT', 'HND', 'HKG', 'HUN',
    'ISL', 'IND', 'IDN', 'IRN', 'IRQ', 'IRL',
    'IMN', 'ISR', 'ITA', 'JAM', 'JPN', 'JEY',
    'JOR', 'KAZ', 'KEN', 'KIR', 'PRK', 'KOR',
    'KWT', 'KGZ', 'LAO', 'LVA', 'LBN', 'LSO',
    'LBR', 'LBY', 'LIE', 'LTU', 'LUX', 'MAC',
    'MDG', 'MWI', 'MYS', 'MDV', 'MLI', 'MLT',
    'MHL', 'MTQ', 'MRT', 'MUS', 'MYT', 'MEX',
    'FSM', 'MDA', 'MCO', 'MNG', 'MNE', 'MSR',
    'MAR', 'MOZ', 'MMR', 'NAM', 'NRU', 'NPL',
    'NLD', 'NCL', 'NZL', 'NIC', 'NER', 'NGA',
    'NIU', 'NFK', 'MKD', 'MNP', 'NOR', 'OMN',
    'PAK', 'PLW', 'PSE', 'PAN', 'PNG', 'PRY',
    'PER', 'PHL', 'PCN', 'POL', 'PRT', 'PRI',
    'QAT', 'REU', 'ROU', 'RUS', 'RWA', 'BLM',
    'SHN', 'KNA', 'LCA', 'MAF', 'SPM', 'VCT',
    'WSM', 'SMR', 'STP', 'SAU', 'SEN', 'SRB',
    'SYC', 'SLE', 'SGP', 'SXM', 'SVK', 'SVN',
    'SLB', 'SOM', 'ZAF', 'SGS', 'SSD', 'ESP',
    'LKA', 'SDN', 'SUR', 'SJM', 'SWE', 'CHE',
    'SYR', 'TWN', 'TJK', 'TZA', 'THA', 'TLS',
    'TGO', 'TKL', 'TON', 'TTO', 'TUN', 'TUR',
    'TKM', 'TCA', 'TUV', 'UGA', 'UKR', 'ARE',
    'GBR', 'USA', 'UMI', 'URY', 'UZB', 'VUT',
    'VEN', 'VNM', 'VGB', 'VIR', 'WLF', 'ESH',
    'YEM', 'ZMB', 'ZWE'
]

export type Codes = (typeof codes)[number]

export const currency = [
    'USD', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT',
    'BGN', 'BIF', 'BMD', 'BND', 'BOB', 'BRL', 'BSD', 'BWP', 'BYN', 'BZD', 'CAD', 'CDF', 'CHF', 'CLP',
    'CNY', 'COP', 'CRC', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EGP', 'ETB', 'EUR', 'FJD', 'FKP',
    'GBP', 'GEL', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HKD', 'HNL', 'HRK', 'HTG', 'HUF', 'IDR', 'ILS',
    'INR', 'ISK', 'JMD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KRW', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR',
    'LRD', 'LSL', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN',
    'MYR', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'NZD', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN',
    'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SEK', 'SGD', 'SHP', 'SLL', 'SOS',
    'SRD', 'STD', 'SZL', 'THB', 'TJS', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'UYU', 'UZS',
    'VND', 'VUV', 'WST', 'XAF', 'XCD', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMW'
]
export type Currency = (typeof currency)[number]